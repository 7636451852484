export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A string used to identify an i18n locale */
  I18NLocaleCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** `Date` type as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  _jsonb: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export type Accessory = {
  __typename?: 'Accessory';
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<AccessoryRelationResponseCollection>;
  name: Scalars['String'];
  picture?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type AccessoryLocalizationsArgs = {
  filters?: Maybe<AccessoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AccessoryEntity = {
  __typename?: 'AccessoryEntity';
  attributes?: Maybe<Accessory>;
  id?: Maybe<Scalars['ID']>;
};

export type AccessoryEntityResponse = {
  __typename?: 'AccessoryEntityResponse';
  data?: Maybe<AccessoryEntity>;
};

export type AccessoryEntityResponseCollection = {
  __typename?: 'AccessoryEntityResponseCollection';
  data: Array<AccessoryEntity>;
  meta: ResponseCollectionMeta;
};

export type AccessoryFiltersInput = {
  and?: Maybe<Array<Maybe<AccessoryFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<AccessoryFiltersInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<AccessoryFiltersInput>;
  or?: Maybe<Array<Maybe<AccessoryFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  sku?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type AccessoryInput = {
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Scalars['String']>;
};

export type AccessoryRelationResponseCollection = {
  __typename?: 'AccessoryRelationResponseCollection';
  data: Array<AccessoryEntity>;
};

export enum ActivationOutcome {
  InternalError = 'InternalError',
  NetworkProviderError = 'NetworkProviderError',
  NetworkProviderUnavailable = 'NetworkProviderUnavailable',
  SuccessNewline = 'SuccessNewline',
  SuccessPortability = 'SuccessPortability'
}

export type ActivationRequestInput = {
  iccid?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  orderId: Scalars['Float'];
  portability: Scalars['Boolean'];
  portabilityDate?: Maybe<Scalars['DateTime']>;
  rio?: Maybe<Scalars['String']>;
};

export type ActivationResult = {
  __typename?: 'ActivationResult';
  matchingId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  outcome: ActivationOutcome;
  portabilityDate?: Maybe<Scalars['DateTime']>;
  qrCode?: Maybe<Scalars['String']>;
  snmpd?: Maybe<Scalars['String']>;
};

export type AddBankDetailError = {
  __typename?: 'AddBankDetailError';
  message: Scalars['String'];
};

export type AddBankDetailResult = AddBankDetailError | AddBankDetailSuccess;

export type AddBankDetailSuccess = {
  __typename?: 'AddBankDetailSuccess';
  success: Scalars['Boolean'];
};

export type AddCardError = {
  __typename?: 'AddCardError';
  success: Scalars['Boolean'];
};

export type AddCardResult = AddCardError | AddCardSuccess;

export type AddCardSuccess = {
  __typename?: 'AddCardSuccess';
  pspOutput: Scalars['JSONObject'];
};

export type AddOwnershipProofError = {
  __typename?: 'AddOwnershipProofError';
  message: Scalars['String'];
};

export type AddOwnershipProofResult = AddOwnershipProofError | AddOwnershipProofSuccess;

export type AddOwnershipProofSuccess = {
  __typename?: 'AddOwnershipProofSuccess';
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
};

export type AddSupplierOrderItemsResult = {
  __typename?: 'AddSupplierOrderItemsResult';
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  failedImportedIMEIs?: Maybe<Array<Scalars['String']>>;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AddressDto = {
  city: Scalars['String'];
  deliveryInstructions?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type ApplyDiscountCodeResponse = {
  __typename?: 'ApplyDiscountCodeResponse';
  success: Scalars['Boolean'];
};

export type AssignedOrderDto = {
  __typename?: 'AssignedOrderDto';
  assigned: Scalars['Boolean'];
  assignedStock?: Maybe<Scalars['String']>;
  missingItems?: Maybe<MissingItemsDto>;
  orderCode: Scalars['String'];
  orderId: Scalars['Int'];
};

export type AttachmentInput = {
  data: Scalars['String'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};

export type AuthError = {
  __typename?: 'AuthError';
  rateLimit?: Maybe<RateLimitDto>;
  success: Scalars['Boolean'];
};

export type AuthResponse = AuthError | AuthSuccess;

export type AuthSuccess = {
  __typename?: 'AuthSuccess';
  isBusiness: Scalars['Boolean'];
  success: Scalars['Boolean'];
  token: Scalars['String'];
  type: Scalars['String'];
};

export type Authorize3dsPaymentResponse = {
  __typename?: 'Authorize3dsPaymentResponse';
  pspOutput: Scalars['JSONObject'];
};

export type AvailableActions = {
  __typename?: 'AvailableActions';
  canCancel: Scalars['Boolean'];
  canRequestCancelation: Scalars['Boolean'];
};

export type Average = {
  __typename?: 'Average';
  average: Scalars['Float'];
  count: Scalars['Float'];
};

export type B2BAddressInput = {
  city: Scalars['String'];
  deliveryInstructions?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  extra_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type B2BCompanyDetails = {
  b2bAccountName: Scalars['String'];
  iban: Scalars['String'];
  siret: Scalars['String'];
};

export type B2BCreateUserResponseDto = {
  __typename?: 'B2BCreateUserResponseDto';
  createdUserId: Scalars['Int'];
  message: B2BUserResponse;
  success: Scalars['Boolean'];
};

export type B2BCustomerInput = {
  birthdate: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type B2BLaptopCatalog = {
  __typename?: 'B2BLaptopCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesLaptopEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2BLaptopCatalogEntriesArgs = {
  filters?: Maybe<ComponentEntriesLaptopEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BLaptopCatalogEntity = {
  __typename?: 'B2BLaptopCatalogEntity';
  attributes?: Maybe<B2BLaptopCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2BLaptopCatalogEntityResponse = {
  __typename?: 'B2BLaptopCatalogEntityResponse';
  data?: Maybe<B2BLaptopCatalogEntity>;
};

export type B2BLaptopCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesLaptopEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type B2BNewOrderBatchInput = {
  b2bAccountId: Scalars['Int'];
  batch: Array<B2BNewOrderBatchItemInput>;
  payment: Scalars['String'];
  shippingAddress: OrderAddressInput;
};

export type B2BNewOrderBatchItemInput = {
  deliveryFees: Scalars['Float'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  subscriptionPrice: Scalars['Float'];
  upfront: Scalars['Float'];
};

export type B2BPhonePlanCatalog = {
  __typename?: 'B2BPhonePlanCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesPhonePlanEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2BPhonePlanCatalogEntriesArgs = {
  filters?: Maybe<ComponentEntriesPhonePlanEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BPhonePlanCatalogEntity = {
  __typename?: 'B2BPhonePlanCatalogEntity';
  attributes?: Maybe<B2BPhonePlanCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2BPhonePlanCatalogEntityResponse = {
  __typename?: 'B2BPhonePlanCatalogEntityResponse';
  data?: Maybe<B2BPhonePlanCatalogEntity>;
};

export type B2BPhonePlanCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesPhonePlanEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type B2BSmartphoneCatalog = {
  __typename?: 'B2BSmartphoneCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2BSmartphoneCatalogEntriesArgs = {
  filters?: Maybe<ComponentEntriesSmartphoneEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BSmartphoneCatalogEntity = {
  __typename?: 'B2BSmartphoneCatalogEntity';
  attributes?: Maybe<B2BSmartphoneCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2BSmartphoneCatalogEntityResponse = {
  __typename?: 'B2BSmartphoneCatalogEntityResponse';
  data?: Maybe<B2BSmartphoneCatalogEntity>;
};

export type B2BSmartphoneCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type B2BTabletCatalog = {
  __typename?: 'B2BTabletCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesTabletEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2BTabletCatalogEntriesArgs = {
  filters?: Maybe<ComponentEntriesTabletEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BTabletCatalogEntity = {
  __typename?: 'B2BTabletCatalogEntity';
  attributes?: Maybe<B2BTabletCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2BTabletCatalogEntityResponse = {
  __typename?: 'B2BTabletCatalogEntityResponse';
  data?: Maybe<B2BTabletCatalogEntity>;
};

export type B2BTabletCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesTabletEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type B2BUserCreateDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export enum B2BUserResponse {
  EntityNotFound = 'EntityNotFound',
  Error = 'Error',
  Forbidden = 'Forbidden',
  Ok = 'Ok'
}

export type B2BUserResponseDto = {
  __typename?: 'B2BUserResponseDto';
  message: B2BUserResponse;
  success: Scalars['Boolean'];
};

export type B2BUserUpdateDto = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type B2CSmartphoneBestSellersCatalog = {
  __typename?: 'B2CSmartphoneBestSellersCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entires?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2CSmartphoneBestSellersCatalogEntiresArgs = {
  filters?: Maybe<ComponentEntriesSmartphoneEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CSmartphoneBestSellersCatalogEntity = {
  __typename?: 'B2CSmartphoneBestSellersCatalogEntity';
  attributes?: Maybe<B2CSmartphoneBestSellersCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2CSmartphoneBestSellersCatalogEntityResponse = {
  __typename?: 'B2CSmartphoneBestSellersCatalogEntityResponse';
  data?: Maybe<B2CSmartphoneBestSellersCatalogEntity>;
};

export type B2CSmartphoneBestSellersCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entires?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type B2CSmartphoneCatalog = {
  __typename?: 'B2CSmartphoneCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntry>>>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<B2CSmartphoneCatalogRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2CSmartphoneCatalogEntriesArgs = {
  filters?: Maybe<ComponentEntriesSmartphoneEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type B2CSmartphoneCatalogLocalizationsArgs = {
  publicationState?: Maybe<PublicationState>;
};

export type B2CSmartphoneCatalogEntity = {
  __typename?: 'B2CSmartphoneCatalogEntity';
  attributes?: Maybe<B2CSmartphoneCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2CSmartphoneCatalogEntityResponse = {
  __typename?: 'B2CSmartphoneCatalogEntityResponse';
  data?: Maybe<B2CSmartphoneCatalogEntity>;
};

export type B2CSmartphoneCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type B2CSmartphoneCatalogRelationResponseCollection = {
  __typename?: 'B2CSmartphoneCatalogRelationResponseCollection';
  data: Array<B2CSmartphoneCatalogEntity>;
};

export type B2CSmartphoneOthersCatalog = {
  __typename?: 'B2CSmartphoneOthersCatalog';
  catalogId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entires?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type B2CSmartphoneOthersCatalogEntiresArgs = {
  filters?: Maybe<ComponentEntriesSmartphoneEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CSmartphoneOthersCatalogEntity = {
  __typename?: 'B2CSmartphoneOthersCatalogEntity';
  attributes?: Maybe<B2CSmartphoneOthersCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type B2CSmartphoneOthersCatalogEntityResponse = {
  __typename?: 'B2CSmartphoneOthersCatalogEntityResponse';
  data?: Maybe<B2CSmartphoneOthersCatalogEntity>;
};

export type B2CSmartphoneOthersCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entires?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type BankDetailsDto = {
  iban: Scalars['String'];
};

export type BankDetailsLookup = {
  __typename?: 'BankDetailsLookup';
  bankName: Scalars['String'];
  bic: Scalars['String'];
};

export type BankDetailsOutput = {
  __typename?: 'BankDetailsOutput';
  accountHolderName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  customerId: Scalars['Float'];
  files?: Maybe<Array<File>>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAlive?: Maybe<Scalars['Boolean']>;
};

export type BankDetailsResults = {
  __typename?: 'BankDetailsResults';
  bankDetails?: Maybe<BankDetailsOutput>;
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum BnpReturnDecisionOptions {
  DeviceNotCompliant = 'DeviceNotCompliant',
  ICloudLocked = 'ICloudLocked',
  Nothing = 'Nothing',
  ParcelEmptyOrWrongContent = 'ParcelEmptyOrWrongContent'
}

export type BooleanFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  between?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  contains?: Maybe<Scalars['Boolean']>;
  containsi?: Maybe<Scalars['Boolean']>;
  endsWith?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
  eqi?: Maybe<Scalars['Boolean']>;
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  nei?: Maybe<Scalars['Boolean']>;
  not?: Maybe<BooleanFilterInput>;
  notContains?: Maybe<Scalars['Boolean']>;
  notContainsi?: Maybe<Scalars['Boolean']>;
  notIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  startsWith?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type BreakageFeeItem = {
  __typename?: 'BreakageFeeItem';
  id: Scalars['Int'];
  name: Scalars['String'];
  sellPrice?: Maybe<Scalars['Int']>;
};

export type BreakingInsuranceFeeResponse = {
  __typename?: 'BreakingInsuranceFeeResponse';
  amountInCents: Scalars['Int'];
  taxBase: Scalars['Float'];
};

export type BusinessAccountAddressError = {
  __typename?: 'BusinessAccountAddressError';
  message: Scalars['String'];
};

export type BusinessAccountAddressResult = BusinessAccountAddressError | BusinessAccountAddressSuccess;

export type BusinessAccountAddressSuccess = {
  __typename?: 'BusinessAccountAddressSuccess';
  success: Scalars['Boolean'];
};

export type BusinessAccountNameError = {
  __typename?: 'BusinessAccountNameError';
  message: Scalars['String'];
};

export type BusinessAccountNameResult = BusinessAccountNameError | BusinessAccountNameSuccess;

export type BusinessAccountNameSuccess = {
  __typename?: 'BusinessAccountNameSuccess';
  success: Scalars['Boolean'];
};

export type BusinessEntity = {
  __typename?: 'BusinessEntity';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BusinessUpdateDraftOrdersDto = {
  orderAttributes: UpdateDraftOrderAttributes;
  orderId: Scalars['Int'];
};

export type Catalog = {
  __typename?: 'Catalog';
  category?: Maybe<Enum_Catalog_Category>;
  createdAt?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  release?: Maybe<Scalars['String']>;
  swapEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CatalogEntity = {
  __typename?: 'CatalogEntity';
  attributes?: Maybe<Catalog>;
  id?: Maybe<Scalars['ID']>;
};

export type CatalogEntityResponse = {
  __typename?: 'CatalogEntityResponse';
  data?: Maybe<CatalogEntity>;
};

export type CatalogEntityResponseCollection = {
  __typename?: 'CatalogEntityResponseCollection';
  data: Array<CatalogEntity>;
  meta: ResponseCollectionMeta;
};

export type CatalogFiltersInput = {
  and?: Maybe<Array<Maybe<CatalogFiltersInput>>>;
  category?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  model?: Maybe<StringFilterInput>;
  not?: Maybe<CatalogFiltersInput>;
  or?: Maybe<Array<Maybe<CatalogFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  release?: Maybe<StringFilterInput>;
  swapEnabled?: Maybe<BooleanFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type CatalogInput = {
  category?: Maybe<Enum_Catalog_Category>;
  model?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  release?: Maybe<Scalars['String']>;
  swapEnabled?: Maybe<Scalars['Boolean']>;
};

export type CatalogItemsSkus = {
  __typename?: 'CatalogItemsSkus';
  catalogItemSkus: Scalars['JSONObject'];
};

export type Cgu = {
  __typename?: 'Cgu';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CguEntity = {
  __typename?: 'CguEntity';
  attributes?: Maybe<Cgu>;
  id?: Maybe<Scalars['ID']>;
};

export type CguEntityResponse = {
  __typename?: 'CguEntityResponse';
  data?: Maybe<CguEntity>;
};

export type CguInput = {
  content?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type ChangeOrderSubscriptionError = {
  __typename?: 'ChangeOrderSubscriptionError';
  message: Scalars['String'];
};

export type ChangeOrderSubscriptionResult = ChangeOrderSubscriptionError | ChangeOrderSubscriptionSuccess;

export type ChangeOrderSubscriptionSuccess = {
  __typename?: 'ChangeOrderSubscriptionSuccess';
  success: Scalars['Boolean'];
};

export type CheckoutError = {
  __typename?: 'CheckoutError';
  message: Scalars['String'];
};

export type CheckoutResult = CheckoutError | CheckoutSuccess;

export type CheckoutSuccess = {
  __typename?: 'CheckoutSuccess';
  success: Scalars['Boolean'];
};

export type CheckpointUbbleResult = {
  __typename?: 'CheckpointUbbleResult';
  birthdate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  dashboardUrl?: Maybe<Scalars['String']>;
  documentAttributes: Scalars['JSONObject'];
  endedAt?: Maybe<Scalars['String']>;
  faceAttributes: Scalars['JSONObject'];
  faceMatchScore?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identificationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  previousIdentifications: Array<UbblePreviousIdentification>;
  responseCodes?: Maybe<Array<UbbleResultResponseCode>>;
  score?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CleaqOrderInfo = {
  __typename?: 'CleaqOrderInfo';
  cleaqInstanceId?: Maybe<Scalars['String']>;
  cleaqOrderName?: Maybe<Scalars['String']>;
  cleaqProductId?: Maybe<Scalars['String']>;
};

export type ComparatorRival = {
  __typename?: 'ComparatorRival';
  createdAt?: Maybe<Scalars['DateTime']>;
  highlights?: Maybe<Array<Maybe<ComponentComparatorWinnerHighlight>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  rivalA?: Maybe<SmartphoneFamilyEntityResponse>;
  rivalB?: Maybe<SmartphoneFamilyEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  winner?: Maybe<SmartphoneFamilyEntityResponse>;
};


export type ComparatorRivalHighlightsArgs = {
  filters?: Maybe<ComponentComparatorWinnerHighlightFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComparatorRivalEntity = {
  __typename?: 'ComparatorRivalEntity';
  attributes?: Maybe<ComparatorRival>;
  id?: Maybe<Scalars['ID']>;
};

export type ComparatorRivalEntityResponse = {
  __typename?: 'ComparatorRivalEntityResponse';
  data?: Maybe<ComparatorRivalEntity>;
};

export type ComparatorRivalEntityResponseCollection = {
  __typename?: 'ComparatorRivalEntityResponseCollection';
  data: Array<ComparatorRivalEntity>;
  meta: ResponseCollectionMeta;
};

export type ComparatorRivalFiltersInput = {
  and?: Maybe<Array<Maybe<ComparatorRivalFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  highlights?: Maybe<ComponentComparatorWinnerHighlightFiltersInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<ComparatorRivalFiltersInput>;
  or?: Maybe<Array<Maybe<ComparatorRivalFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  rivalA?: Maybe<SmartphoneFamilyFiltersInput>;
  rivalB?: Maybe<SmartphoneFamilyFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  winner?: Maybe<SmartphoneFamilyFiltersInput>;
};

export type ComparatorRivalInput = {
  highlights?: Maybe<Array<Maybe<ComponentComparatorWinnerHighlightInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  rivalA?: Maybe<Scalars['ID']>;
  rivalB?: Maybe<Scalars['ID']>;
  winner?: Maybe<Scalars['ID']>;
};

export type CompliantTransactionStatus = {
  __typename?: 'CompliantTransactionStatus';
  compliant: Scalars['Boolean'];
};

export type ComponentComparatorWinnerHighlight = {
  __typename?: 'ComponentComparatorWinnerHighlight';
  id: Scalars['ID'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type ComponentComparatorWinnerHighlightFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentComparatorWinnerHighlightFiltersInput>>>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<ComponentComparatorWinnerHighlightFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentComparatorWinnerHighlightFiltersInput>>>;
  text?: Maybe<StringFilterInput>;
};

export type ComponentComparatorWinnerHighlightInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ComponentEntriesLaptopEntry = {
  __typename?: 'ComponentEntriesLaptopEntry';
  entryName: Scalars['String'];
  flagship?: Maybe<LaptopSubscriptionEntityResponse>;
  id: Scalars['ID'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  subscriptions?: Maybe<LaptopSubscriptionRelationResponseCollection>;
};


export type ComponentEntriesLaptopEntrySubscriptionsArgs = {
  filters?: Maybe<LaptopSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentEntriesLaptopEntryFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentEntriesLaptopEntryFiltersInput>>>;
  entryName?: Maybe<StringFilterInput>;
  flagship?: Maybe<LaptopSubscriptionFiltersInput>;
  isPrivate?: Maybe<BooleanFilterInput>;
  not?: Maybe<ComponentEntriesLaptopEntryFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentEntriesLaptopEntryFiltersInput>>>;
  subscriptions?: Maybe<LaptopSubscriptionFiltersInput>;
};

export type ComponentEntriesLaptopEntryInput = {
  entryName?: Maybe<Scalars['String']>;
  flagship?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  subscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentEntriesLpmSmartphoneEntry = {
  __typename?: 'ComponentEntriesLpmSmartphoneEntry';
  entryName: Scalars['String'];
  flagship?: Maybe<SmartphoneSubscriptionEntityResponse>;
  id: Scalars['ID'];
  subscriptions?: Maybe<SmartphoneSubscriptionRelationResponseCollection>;
};


export type ComponentEntriesLpmSmartphoneEntrySubscriptionsArgs = {
  filters?: Maybe<SmartphoneSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentEntriesLpmSmartphoneEntryFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentEntriesLpmSmartphoneEntryFiltersInput>>>;
  entryName?: Maybe<StringFilterInput>;
  flagship?: Maybe<SmartphoneSubscriptionFiltersInput>;
  not?: Maybe<ComponentEntriesLpmSmartphoneEntryFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentEntriesLpmSmartphoneEntryFiltersInput>>>;
  subscriptions?: Maybe<SmartphoneSubscriptionFiltersInput>;
};

export type ComponentEntriesLpmSmartphoneEntryInput = {
  entryName?: Maybe<Scalars['String']>;
  flagship?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  subscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentEntriesPhonePlanEntry = {
  __typename?: 'ComponentEntriesPhonePlanEntry';
  entryName: Scalars['String'];
  flagship?: Maybe<PhonePlanSubscriptionEntityResponse>;
  id: Scalars['ID'];
};

export type ComponentEntriesPhonePlanEntryFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentEntriesPhonePlanEntryFiltersInput>>>;
  entryName?: Maybe<StringFilterInput>;
  flagship?: Maybe<PhonePlanSubscriptionFiltersInput>;
  not?: Maybe<ComponentEntriesPhonePlanEntryFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentEntriesPhonePlanEntryFiltersInput>>>;
};

export type ComponentEntriesPhonePlanEntryInput = {
  entryName?: Maybe<Scalars['String']>;
  flagship?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type ComponentEntriesSmartphoneEntry = {
  __typename?: 'ComponentEntriesSmartphoneEntry';
  blackFridayFormerUpfrontPriceInCentsInclundingTax?: Maybe<Scalars['Int']>;
  category?: Maybe<Enum_Componententriessmartphoneentry_Category>;
  entryName: Scalars['String'];
  flagship?: Maybe<SmartphoneSubscriptionEntityResponse>;
  formerUpfrontPriceInCentsInclundingTax?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isNew?: Maybe<Scalars['Boolean']>;
  releaseDate?: Maybe<Scalars['String']>;
  subscriptionDrop?: Maybe<Array<Maybe<ComponentEntriesSubscriptionDrop>>>;
  subscriptions?: Maybe<SmartphoneSubscriptionRelationResponseCollection>;
  tags?: Maybe<TagRelationResponseCollection>;
};


export type ComponentEntriesSmartphoneEntrySubscriptionDropArgs = {
  filters?: Maybe<ComponentEntriesSubscriptionDropFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ComponentEntriesSmartphoneEntrySubscriptionsArgs = {
  filters?: Maybe<SmartphoneSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ComponentEntriesSmartphoneEntryTagsArgs = {
  filters?: Maybe<TagFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentEntriesSmartphoneEntryFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntryFiltersInput>>>;
  blackFridayFormerUpfrontPriceInCentsInclundingTax?: Maybe<IntFilterInput>;
  category?: Maybe<StringFilterInput>;
  entryName?: Maybe<StringFilterInput>;
  flagship?: Maybe<SmartphoneSubscriptionFiltersInput>;
  formerUpfrontPriceInCentsInclundingTax?: Maybe<IntFilterInput>;
  isNew?: Maybe<BooleanFilterInput>;
  not?: Maybe<ComponentEntriesSmartphoneEntryFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentEntriesSmartphoneEntryFiltersInput>>>;
  releaseDate?: Maybe<StringFilterInput>;
  subscriptionDrop?: Maybe<ComponentEntriesSubscriptionDropFiltersInput>;
  subscriptions?: Maybe<SmartphoneSubscriptionFiltersInput>;
  tags?: Maybe<TagFiltersInput>;
};

export type ComponentEntriesSmartphoneEntryInput = {
  blackFridayFormerUpfrontPriceInCentsInclundingTax?: Maybe<Scalars['Int']>;
  category?: Maybe<Enum_Componententriessmartphoneentry_Category>;
  entryName?: Maybe<Scalars['String']>;
  flagship?: Maybe<Scalars['ID']>;
  formerUpfrontPriceInCentsInclundingTax?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isNew?: Maybe<Scalars['Boolean']>;
  releaseDate?: Maybe<Scalars['String']>;
  subscriptionDrop?: Maybe<Array<Maybe<ComponentEntriesSubscriptionDropInput>>>;
  subscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentEntriesSubscriptionDrop = {
  __typename?: 'ComponentEntriesSubscriptionDrop';
  formerSubscriptionPriceInCentsIncludingTax?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  subscriptions?: Maybe<SmartphoneSubscriptionRelationResponseCollection>;
};


export type ComponentEntriesSubscriptionDropSubscriptionsArgs = {
  filters?: Maybe<SmartphoneSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentEntriesSubscriptionDropFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentEntriesSubscriptionDropFiltersInput>>>;
  formerSubscriptionPriceInCentsIncludingTax?: Maybe<IntFilterInput>;
  not?: Maybe<ComponentEntriesSubscriptionDropFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentEntriesSubscriptionDropFiltersInput>>>;
  subscriptions?: Maybe<SmartphoneSubscriptionFiltersInput>;
};

export type ComponentEntriesSubscriptionDropInput = {
  formerSubscriptionPriceInCentsIncludingTax?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  subscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentEntriesTabletEntry = {
  __typename?: 'ComponentEntriesTabletEntry';
  entryName: Scalars['String'];
  flagship?: Maybe<TabletSubscriptionEntityResponse>;
  id: Scalars['ID'];
  subscriptions?: Maybe<TabletSubscriptionRelationResponseCollection>;
};


export type ComponentEntriesTabletEntrySubscriptionsArgs = {
  filters?: Maybe<TabletSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentEntriesTabletEntryFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentEntriesTabletEntryFiltersInput>>>;
  entryName?: Maybe<StringFilterInput>;
  flagship?: Maybe<TabletSubscriptionFiltersInput>;
  not?: Maybe<ComponentEntriesTabletEntryFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentEntriesTabletEntryFiltersInput>>>;
  subscriptions?: Maybe<TabletSubscriptionFiltersInput>;
};

export type ComponentEntriesTabletEntryInput = {
  entryName?: Maybe<Scalars['String']>;
  flagship?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  subscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: Maybe<StringFilterInput>;
  not?: Maybe<ComponentSharedMetaSocialFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: Maybe<StringFilterInput>;
  title?: Maybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['ID']>;
  socialNetwork?: Maybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords?: Maybe<Scalars['String']>;
  metaDescription: Scalars['String'];
  metaImage: UploadFileEntityResponse;
  metaRobots?: Maybe<Scalars['String']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String'];
  metaViewport?: Maybe<Scalars['String']>;
  structuredData?: Maybe<Scalars['JSON']>;
};


export type ComponentSharedSeoMetaSocialArgs = {
  filters?: Maybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentSharedSeoFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentSharedSeoFiltersInput>>>;
  canonicalURL?: Maybe<StringFilterInput>;
  keywords?: Maybe<StringFilterInput>;
  metaDescription?: Maybe<StringFilterInput>;
  metaRobots?: Maybe<StringFilterInput>;
  metaSocial?: Maybe<ComponentSharedMetaSocialFiltersInput>;
  metaTitle?: Maybe<StringFilterInput>;
  metaViewport?: Maybe<StringFilterInput>;
  not?: Maybe<ComponentSharedSeoFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentSharedSeoFiltersInput>>>;
  structuredData?: Maybe<JsonFilterInput>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  keywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaImage?: Maybe<Scalars['ID']>;
  metaRobots?: Maybe<Scalars['String']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: Maybe<Scalars['String']>;
  metaViewport?: Maybe<Scalars['String']>;
  structuredData?: Maybe<Scalars['JSON']>;
};

export type ComponentTipsArticle = {
  __typename?: 'ComponentTipsArticle';
  content: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  seo: ComponentSharedSeo;
  slug: Scalars['String'];
};

export type ComponentTipsArticleFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentTipsArticleFiltersInput>>>;
  content?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<ComponentTipsArticleFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentTipsArticleFiltersInput>>>;
  seo?: Maybe<ComponentSharedSeoFiltersInput>;
  slug?: Maybe<StringFilterInput>;
};

export type ComponentTipsArticleInput = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  seo?: Maybe<ComponentSharedSeoInput>;
  slug?: Maybe<Scalars['String']>;
};

export type ComponentTipsCategory = {
  __typename?: 'ComponentTipsCategory';
  articles: Array<Maybe<ComponentTipsArticle>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type ComponentTipsCategoryArticlesArgs = {
  filters?: Maybe<ComponentTipsArticleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentTipsCategoryFiltersInput = {
  and?: Maybe<Array<Maybe<ComponentTipsCategoryFiltersInput>>>;
  articles?: Maybe<ComponentTipsArticleFiltersInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<ComponentTipsCategoryFiltersInput>;
  or?: Maybe<Array<Maybe<ComponentTipsCategoryFiltersInput>>>;
};

export type ComponentTipsCategoryInput = {
  articles?: Maybe<Array<Maybe<ComponentTipsArticleInput>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConsumptionInfo = {
  __typename?: 'ConsumptionInfo';
  data: Scalars['Int'];
  id: Scalars['Int'];
  mms: Scalars['Int'];
  overage: Scalars['Int'];
  sms: Scalars['Int'];
  voice: Scalars['Int'];
};

export type Content = {
  __typename?: 'Content';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContentEntity = {
  __typename?: 'ContentEntity';
  attributes?: Maybe<Content>;
  id?: Maybe<Scalars['ID']>;
};

export type ContentEntityResponse = {
  __typename?: 'ContentEntityResponse';
  data?: Maybe<ContentEntity>;
};

export type ContentEntityResponseCollection = {
  __typename?: 'ContentEntityResponseCollection';
  data: Array<ContentEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentFiltersInput = {
  and?: Maybe<Array<Maybe<ContentFiltersInput>>>;
  content?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  description?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<ContentFiltersInput>;
  or?: Maybe<Array<Maybe<ContentFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  slug?: Maybe<StringFilterInput>;
  title?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type ContentInput = {
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ConvertBnpReturnToCompliantError = {
  __typename?: 'ConvertBnpReturnToCompliantError';
  message: Scalars['String'];
  reason: ConvertBnpReturnToCompliantErrorReason;
};

export enum ConvertBnpReturnToCompliantErrorReason {
  CanNotConvertB2BOrder = 'CanNotConvertB2BOrder',
  InfrastructureError = 'InfrastructureError',
  IsNotInBnpNotCompliantCase = 'IsNotInBnpNotCompliantCase',
  OrderNotOwnedByBnp = 'OrderNotOwnedByBnp'
}

export type ConvertBnpReturnToCompliantResult = ConvertBnpReturnToCompliantError | ConvertBnpReturnToCompliantSuccess;

export type ConvertBnpReturnToCompliantSuccess = {
  __typename?: 'ConvertBnpReturnToCompliantSuccess';
  success: Scalars['Boolean'];
};

export enum CountryCode {
  Be = 'BE',
  De = 'DE',
  Fr = 'FR',
  Lu = 'LU',
  Nd = 'ND',
  Pt = 'PT'
}

export type CreateBusinessDraftOrderResponse = {
  __typename?: 'CreateBusinessDraftOrderResponse';
  orderId: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CreateCustomerReminderInput = {
  email: Scalars['String'];
  subscriptionId: Scalars['Int'];
};

export type CreateDraftInsuranceTicketInput = {
  incidentType: Scalars['String'];
  orderItemId: Scalars['Int'];
};

export type CreateDraftInsuranceTicketOutput = {
  __typename?: 'CreateDraftInsuranceTicketOutput';
  ticketId: Scalars['Int'];
};

export type CreateDraftOrderResponse = {
  __typename?: 'CreateDraftOrderResponse';
  orderId: Scalars['Int'];
  session: AuthSuccess;
  success: Scalars['Boolean'];
};

export type CreateHelpScoutTicketInput = {
  attachments?: Maybe<Array<AttachmentInput>>;
  body: Scalars['String'];
  customer: CustomerInput;
  status?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CreateInvoiceInput = {
  chargeAfterCreate?: Maybe<Scalars['Boolean']>;
  chargeAt?: Maybe<Scalars['String']>;
  orderId: Scalars['Int'];
  orderItemsLines: Array<OrderItemLine>;
  paymentSource?: Maybe<InvoicePaymentSourceInput>;
};

export type CreateLpmOrderError = {
  __typename?: 'CreateLpmOrderError';
  message: Scalars['String'];
  reason: LpmOrderErrorReason;
};

export type CreateLpmOrderResult = CreateLpmOrderError | CreateLpmOrderSuccess;

export type CreateLpmOrderSuccess = {
  __typename?: 'CreateLpmOrderSuccess';
  success: Scalars['Boolean'];
};

export type CreateNewUserError = {
  __typename?: 'CreateNewUserError';
  message: Scalars['String'];
};

export type CreateNewUserResult = CreateNewUserError | CreateNewUserSuccess;

export type CreateNewUserSuccess = {
  __typename?: 'CreateNewUserSuccess';
  isSuccess: Scalars['Boolean'];
};

export type CreateOrderPaymentResponse = {
  __typename?: 'CreateOrderPaymentResponse';
  gatewayResponse?: Maybe<Scalars['JSONObject']>;
  message?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateOrdersArg = {
  b2bAccountId: Scalars['Int'];
  shippingAddress: CreateOrdersArgShippingAddress;
  subscriptions: Array<CreateOrdersArgSubscription>;
};

export type CreateOrdersArgShippingAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  extraName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type CreateOrdersArgSubscription = {
  sku: Scalars['String'];
  subscriptionPrice: Scalars['Float'];
  upfront: Scalars['Float'];
};

export type CreateOrdersError = {
  __typename?: 'CreateOrdersError';
  message: Scalars['String'];
};

export type CreateOrdersResult = CreateOrdersError | CreateOrdersSuccess;

export type CreateOrdersSuccess = {
  __typename?: 'CreateOrdersSuccess';
  orderUrl: Scalars['String'];
};

export type CreateParcelInput = {
  contents: Array<CreateParcelInputContent>;
  fromId: Scalars['Int'];
  fromType: ParcelEndpoint;
  gateway: ParcelGateway;
  ref?: Maybe<ParcelRef>;
  toId: Scalars['Int'];
  toType: ParcelEndpoint;
  tracking?: Maybe<Scalars['String']>;
};

export type CreateParcelInputContent = {
  orderItemId: Scalars['Int'];
  serial?: Maybe<Scalars['String']>;
};

export type CreatePipedriveDealInput = {
  company: Scalars['String'];
  count: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateUbbleKycResult = {
  __typename?: 'CreateUbbleKycResult';
  id: Scalars['String'];
  ubbleUrl: Scalars['String'];
};

export enum CustomerAccountPaymentMethodCardGateway {
  Adyen = 'Adyen',
  ProcessOut = 'ProcessOut'
}

export type CustomerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CustomerInvoiceDto = {
  __typename?: 'CustomerInvoiceDto';
  amount: Scalars['Int'];
  amountDue: Scalars['Int'];
  amountPaid: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  fileUUID?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceType: Scalars['String'];
  isDeposit: Scalars['Boolean'];
  orderId: Scalars['Int'];
};

export type CustomerMarkAddressAsDeliverableError = {
  __typename?: 'CustomerMarkAddressAsDeliverableError';
  message: Scalars['String'];
};

export type CustomerMarkAddressAsDeliverableResult = CustomerMarkAddressAsDeliverableError | CustomerMarkAddressAsDeliverableSuccess;

export type CustomerMarkAddressAsDeliverableSuccess = {
  __typename?: 'CustomerMarkAddressAsDeliverableSuccess';
  success: Scalars['Boolean'];
};

export type CustomerMarkAddressAsUndeliverableError = {
  __typename?: 'CustomerMarkAddressAsUndeliverableError';
  message: Scalars['String'];
};

export type CustomerMarkAddressAsUndeliverableResult = CustomerMarkAddressAsUndeliverableError | CustomerMarkAddressAsUndeliverableSuccess;

export type CustomerMarkAddressAsUndeliverableSuccess = {
  __typename?: 'CustomerMarkAddressAsUndeliverableSuccess';
  success: Scalars['Boolean'];
};

export type CustomerMetadata = {
  __typename?: 'CustomerMetadata';
  canSwap: Scalars['Boolean'];
  canSwapTemporary: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasOutstandingPayment: Scalars['Boolean'];
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CustomerResult = {
  __typename?: 'CustomerResult';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
};

export type CustomerVerificationDto = {
  __typename?: 'CustomerVerificationDto';
  currentIdentificationId?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  files: Array<File>;
  id: Scalars['Int'];
  isPhoneValidated: Scalars['Boolean'];
  ubbleStatus: Scalars['String'];
};

export enum CustomerVerificationStateName {
  Canceled = 'Canceled',
  DocumentsRejected = 'DocumentsRejected',
  DocumentsUploaded = 'DocumentsUploaded',
  Rejected = 'Rejected',
  Validated = 'Validated',
  WaitingDocuments = 'WaitingDocuments'
}

export type CustomizeOrderSubscriptionError = {
  __typename?: 'CustomizeOrderSubscriptionError';
  message: Scalars['String'];
};

export type CustomizeOrderSubscriptionResult = CustomizeOrderSubscriptionError | CustomizeOrderSubscriptionSuccess;

export type CustomizeOrderSubscriptionSuccess = {
  __typename?: 'CustomizeOrderSubscriptionSuccess';
  success: Scalars['Boolean'];
};


export type DateFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Date']>>>;
  between?: Maybe<Array<Maybe<Scalars['Date']>>>;
  contains?: Maybe<Scalars['Date']>;
  containsi?: Maybe<Scalars['Date']>;
  endsWith?: Maybe<Scalars['Date']>;
  eq?: Maybe<Scalars['Date']>;
  eqi?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  nei?: Maybe<Scalars['Date']>;
  not?: Maybe<DateFilterInput>;
  notContains?: Maybe<Scalars['Date']>;
  notContainsi?: Maybe<Scalars['Date']>;
  notIn?: Maybe<Array<Maybe<Scalars['Date']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Date']>>>;
  startsWith?: Maybe<Scalars['Date']>;
};


export type DateTimeFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  between?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  contains?: Maybe<Scalars['DateTime']>;
  containsi?: Maybe<Scalars['DateTime']>;
  endsWith?: Maybe<Scalars['DateTime']>;
  eq?: Maybe<Scalars['DateTime']>;
  eqi?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  nei?: Maybe<Scalars['DateTime']>;
  not?: Maybe<DateTimeFilterInput>;
  notContains?: Maybe<Scalars['DateTime']>;
  notContainsi?: Maybe<Scalars['DateTime']>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  startsWith?: Maybe<Scalars['DateTime']>;
};

export type DeclareDamagesArgs = {
  backCoverGrade: Scalars['String'];
  chassisGrade: Scalars['String'];
  orderId: Scalars['Int'];
  screenGrade: Scalars['String'];
};

export type DeleteCardCard = {
  gateway: CustomerAccountPaymentMethodCardGateway;
  id: Scalars['String'];
};

export type DeleteCardResponse = {
  __typename?: 'DeleteCardResponse';
  success: Scalars['Boolean'];
};

export type DevicesInput = {
  orderItemId: Scalars['Int'];
  stockItemId: Scalars['Int'];
};

export type DisableOrderSubscriptionAutomaticRenewalError = {
  __typename?: 'DisableOrderSubscriptionAutomaticRenewalError';
  message: Scalars['String'];
};

export type DisableOrderSubscriptionAutomaticRenewalResult = DisableOrderSubscriptionAutomaticRenewalError | DisableOrderSubscriptionAutomaticRenewalSuccess;

export type DisableOrderSubscriptionAutomaticRenewalSuccess = {
  __typename?: 'DisableOrderSubscriptionAutomaticRenewalSuccess';
  success: Scalars['Boolean'];
};

export type DiscountCodeMetadata = {
  __typename?: 'DiscountCodeMetadata';
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  type: DiscountCodeType;
};

/** coupon or referral */
export enum DiscountCodeType {
  Coupon = 'coupon',
  Referral = 'referral'
}

export type DraftOrderDto = {
  attribution?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
};

export enum Enum_Catalog_Category {
  Available = 'available',
  ComingSoon = 'comingSoon',
  Legacy = 'legacy'
}

export enum Enum_Componententriessmartphoneentry_Category {
  Available = 'available',
  ComingSoon = 'comingSoon',
  Legacy = 'legacy'
}

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter'
}

export enum Enum_Translation_Table {
  Authentication = 'authentication',
  B2b = 'b2b',
  Banner = 'banner',
  Blog = 'blog',
  Breadcrumb = 'breadcrumb',
  BusinessSpace = 'business_space',
  Catalog = 'catalog',
  Color = 'color',
  Common = 'common',
  Comparator = 'comparator',
  Cookie = 'cookie',
  CustomerAccount = 'customer_account',
  Device = 'device',
  Environment = 'environment',
  ErrorPage = 'error_page',
  Faq = 'faq',
  Footer = 'footer',
  Funnel = 'funnel',
  Home = 'home',
  HowItWorks = 'how_it_works',
  ICloudReturn = 'i_cloud_return',
  InsuranceModal = 'insurance_modal',
  LeClub = 'le_club',
  Legal = 'legal',
  Marquee = 'marquee',
  Navbar = 'navbar',
  NetworkCoverage = 'network_coverage',
  PaymentMethods = 'payment_methods',
  Press = 'press',
  Product = 'product',
  ReconditionedSmartphones = 'reconditioned_smartphones',
  Seo = 'seo',
  ShippingMethods = 'shipping_methods',
  Unsubscribed = 'unsubscribed'
}

export type Eligibility = {
  __typename?: 'Eligibility';
  isEligible: Scalars['Boolean'];
};

export type EligibilityDto = Eligibility | NotFoundError;

export type EmailTemplaterLayout = {
  __typename?: 'EmailTemplaterLayout';
  createdAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplaterLayoutEntity = {
  __typename?: 'EmailTemplaterLayoutEntity';
  attributes?: Maybe<EmailTemplaterLayout>;
  id?: Maybe<Scalars['ID']>;
};

export type EmailTemplaterLayoutEntityResponse = {
  __typename?: 'EmailTemplaterLayoutEntityResponse';
  data?: Maybe<EmailTemplaterLayoutEntity>;
};

export type EmailTemplaterLayoutEntityResponseCollection = {
  __typename?: 'EmailTemplaterLayoutEntityResponseCollection';
  data: Array<EmailTemplaterLayoutEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailTemplaterLayoutFiltersInput = {
  and?: Maybe<Array<Maybe<EmailTemplaterLayoutFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  data?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<EmailTemplaterLayoutFiltersInput>;
  or?: Maybe<Array<Maybe<EmailTemplaterLayoutFiltersInput>>>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type EmailTemplaterLayoutInput = {
  data?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailTemplaterTemplate = {
  __typename?: 'EmailTemplaterTemplate';
  createdAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['String'];
  identifier?: Maybe<Scalars['String']>;
  layout?: Maybe<EmailTemplaterLayoutEntityResponse>;
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  senderMail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplaterTemplateEntity = {
  __typename?: 'EmailTemplaterTemplateEntity';
  attributes?: Maybe<EmailTemplaterTemplate>;
  id?: Maybe<Scalars['ID']>;
};

export type EmailTemplaterTemplateEntityResponse = {
  __typename?: 'EmailTemplaterTemplateEntityResponse';
  data?: Maybe<EmailTemplaterTemplateEntity>;
};

export type EmailTemplaterTemplateEntityResponseCollection = {
  __typename?: 'EmailTemplaterTemplateEntityResponseCollection';
  data: Array<EmailTemplaterTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailTemplaterTemplateFiltersInput = {
  and?: Maybe<Array<Maybe<EmailTemplaterTemplateFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  data?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  identifier?: Maybe<StringFilterInput>;
  layout?: Maybe<EmailTemplaterLayoutFiltersInput>;
  metadata?: Maybe<JsonFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<EmailTemplaterTemplateFiltersInput>;
  or?: Maybe<Array<Maybe<EmailTemplaterTemplateFiltersInput>>>;
  senderMail?: Maybe<StringFilterInput>;
  senderName?: Maybe<StringFilterInput>;
  subject?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type EmailTemplaterTemplateInput = {
  data?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  senderMail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type EnableOrderSubscriptionAutomaticRenewalError = {
  __typename?: 'EnableOrderSubscriptionAutomaticRenewalError';
  message: Scalars['String'];
};

export type EnableOrderSubscriptionAutomaticRenewalResult = EnableOrderSubscriptionAutomaticRenewalError | EnableOrderSubscriptionAutomaticRenewalSuccess;

export type EnableOrderSubscriptionAutomaticRenewalSuccess = {
  __typename?: 'EnableOrderSubscriptionAutomaticRenewalSuccess';
  success: Scalars['Boolean'];
};

export type EstimatedDeliveryTimeRange = {
  __typename?: 'EstimatedDeliveryTimeRange';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type ExistingCardInput = {
  tokenId: Scalars['String'];
};

export type Faq = {
  __typename?: 'Faq';
  analyticsKey?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FaqEntity = {
  __typename?: 'FaqEntity';
  attributes?: Maybe<Faq>;
  id?: Maybe<Scalars['ID']>;
};

export type FaqEntityResponse = {
  __typename?: 'FaqEntityResponse';
  data?: Maybe<FaqEntity>;
};

export type FaqEntityResponseCollection = {
  __typename?: 'FaqEntityResponseCollection';
  data: Array<FaqEntity>;
  meta: ResponseCollectionMeta;
};

export type FaqFiltersInput = {
  analyticsKey?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<FaqFiltersInput>>>;
  answer?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<FaqFiltersInput>;
  or?: Maybe<Array<Maybe<FaqFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  question?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type FaqInput = {
  analyticsKey?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  reference: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  uuid: Scalars['String'];
};

export type FileInfoInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FinancingContractResult = {
  __typename?: 'FinancingContractResult';
  financingProvider?: Maybe<Scalars['String']>;
  oplNumber?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type FinishKycError = {
  __typename?: 'FinishKycError';
  message: Scalars['String'];
  reason: KycErrorReason;
};

export type FinishKycResult = FinishKycError | FinishKycSuccess;

export type FinishKycSuccess = {
  __typename?: 'FinishKycSuccess';
  success: Scalars['Boolean'];
};

export type FloatFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Float']>>>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  contains?: Maybe<Scalars['Float']>;
  containsi?: Maybe<Scalars['Float']>;
  endsWith?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  eqi?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  nei?: Maybe<Scalars['Float']>;
  not?: Maybe<FloatFilterInput>;
  notContains?: Maybe<Scalars['Float']>;
  notContainsi?: Maybe<Scalars['Float']>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Float']>>>;
  startsWith?: Maybe<Scalars['Float']>;
};

export type GenericMorph = Accessory | B2BLaptopCatalog | B2BPhonePlanCatalog | B2BSmartphoneCatalog | B2BTabletCatalog | B2CSmartphoneBestSellersCatalog | B2CSmartphoneCatalog | B2CSmartphoneOthersCatalog | Catalog | Cgu | ComparatorRival | ComponentComparatorWinnerHighlight | ComponentEntriesLaptopEntry | ComponentEntriesLpmSmartphoneEntry | ComponentEntriesPhonePlanEntry | ComponentEntriesSmartphoneEntry | ComponentEntriesSubscriptionDrop | ComponentEntriesTabletEntry | ComponentSharedMetaSocial | ComponentSharedSeo | ComponentTipsArticle | ComponentTipsCategory | Content | EmailTemplaterLayout | EmailTemplaterTemplate | Faq | I18NLocale | LaptopFamily | LaptopSubscription | LaptopVariant | LpmSalesFunnelCatalog | PhonePlanSubscription | Privacy | Review | SeoProperty | SmartphoneFamily | SmartphoneSubscription | SmartphoneVariant | TabletFamily | TabletSubscription | TabletVariant | Tag | Translation | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | WebsiteSetting;

export type GetCustomerContractError = {
  __typename?: 'GetCustomerContractError';
  message: Scalars['String'];
};

export type GetCustomerContractResult = GetCustomerContractError | GetCustomerContractSuccess;

export type GetCustomerContractSuccess = {
  __typename?: 'GetCustomerContractSuccess';
  id: Scalars['Int'];
  signedAt?: Maybe<Scalars['String']>;
};

export type GetDeclaredStatusAndIncomeResult = GetDeclaredStatusAndIncomeSuccess;

export type GetDeclaredStatusAndIncomeSuccess = {
  __typename?: 'GetDeclaredStatusAndIncomeSuccess';
  birthCity?: Maybe<Scalars['String']>;
  birthCountryCode?: Maybe<Scalars['String']>;
  birthZipCode?: Maybe<Scalars['String']>;
  dependentChildrenNumber?: Maybe<Scalars['Float']>;
  housingType?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  monthlyExpenses?: Maybe<Scalars['Float']>;
  netMonthlyIncome?: Maybe<Scalars['Float']>;
  socioProfessionalCategoryCode?: Maybe<Scalars['String']>;
};

export type GetDeviceAmortizationDto = {
  __typename?: 'GetDeviceAmortizationDto';
  firstRentedAt?: Maybe<Scalars['DateTime']>;
  rentedDaysCount: Scalars['Int'];
  rentedMonthsCount: Scalars['Int'];
  residualValue: Scalars['Int'];
  totalDeprecationCost: Scalars['Int'];
};

export type GoCardlessCheckpointResponse = {
  __typename?: 'GoCardlessCheckpointResponse';
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type GorgiasTicket = {
  __typename?: 'GorgiasTicket';
  assignee_user: GorgiasUser;
  channel: Scalars['String'];
  created_datetime: Scalars['String'];
  customer: GorgiasUser;
  excerpt: Scalars['String'];
  id: Scalars['Int'];
  last_message_datetime: Scalars['String'];
  last_received_message_datetime: Scalars['String'];
  requester: GorgiasUser;
  status: Scalars['String'];
  subject: Scalars['String'];
  updated_datetime: Scalars['String'];
  uri: Scalars['String'];
  via: Scalars['String'];
};

export type GorgiasUser = {
  __typename?: 'GorgiasUser';
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['Int'];
  lastname: Scalars['String'];
};

export type HasDeclaredStatusAndIncomeResult = HasDeclaredStatusAndIncomeSuccess;

export type HasDeclaredStatusAndIncomeSuccess = {
  __typename?: 'HasDeclaredStatusAndIncomeSuccess';
  hasDeclaredStatusAndIncome: Scalars['Boolean'];
};

export type HasRequestedFinancingResult = HasRequestedFinancingSuccess;

export type HasRequestedFinancingSuccess = {
  __typename?: 'HasRequestedFinancingSuccess';
  hasRequestedFinancing: Scalars['Boolean'];
};

export type HelpScoutLink = {
  __typename?: 'HelpScoutLink';
  href: Scalars['String'];
};

export type HelpScoutLinks = {
  __typename?: 'HelpScoutLinks';
  assignee: HelpScoutLink;
  closedBy: HelpScoutLink;
  createdByCustomer: HelpScoutLink;
  mailbox: HelpScoutLink;
  primaryCustomer: HelpScoutLink;
  self: HelpScoutLink;
  threads: HelpScoutLink;
  web: HelpScoutLink;
};

export type HelpScoutTicket = {
  __typename?: 'HelpScoutTicket';
  _links: HelpScoutLinks;
  assignee?: Maybe<HelpScoutUser>;
  closedAt?: Maybe<Scalars['String']>;
  closedBy: Scalars['Int'];
  closedByUser?: Maybe<HelpScoutUser>;
  createdAt: Scalars['String'];
  createdBy: HelpScoutUser;
  customerWaitingSince: HelpScoutWaitingSince;
  folderId: Scalars['Int'];
  id: Scalars['String'];
  mailboxId: Scalars['Int'];
  number: Scalars['Int'];
  preview: Scalars['String'];
  primaryCustomer: HelpScoutUser;
  state: Scalars['String'];
  status: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  threads: Scalars['Int'];
  type: Scalars['String'];
  userUpdatedAt: Scalars['String'];
};

export type HelpScoutUser = {
  __typename?: 'HelpScoutUser';
  email: Scalars['String'];
  first?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type HelpScoutWaitingSince = {
  __typename?: 'HelpScoutWaitingSince';
  friendly: Scalars['String'];
  latestReplyFrom?: Maybe<Scalars['String']>;
  time: Scalars['String'];
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  code?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<I18NLocaleFiltersInput>;
  or?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['ID']>>>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  containsi?: Maybe<Scalars['ID']>;
  endsWith?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  eqi?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  nei?: Maybe<Scalars['ID']>;
  not?: Maybe<IdFilterInput>;
  notContains?: Maybe<Scalars['ID']>;
  notContainsi?: Maybe<Scalars['ID']>;
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startsWith?: Maybe<Scalars['ID']>;
};

export type IncomingParcelOrder = {
  __typename?: 'IncomingParcelOrder';
  code: Scalars['String'];
  customer: IncomingParcelOrderCustomer;
  id: Scalars['Int'];
  isB2b: Scalars['Boolean'];
  state: IncomingParcelOrderState;
};

export type IncomingParcelOrderCustomer = {
  __typename?: 'IncomingParcelOrderCustomer';
  first_name: Scalars['String'];
  id: Scalars['Int'];
  isB2b: Scalars['Boolean'];
  last_name: Scalars['String'];
};

export type IncomingParcelOrderItem = {
  __typename?: 'IncomingParcelOrderItem';
  id: Scalars['Int'];
  item: IncomingParcelOrderItemItem;
  state: IncomingParcelOrderItemState;
  stock_item: IncomingParcelOrderItemStockItem;
};

export type IncomingParcelOrderItemItem = {
  __typename?: 'IncomingParcelOrderItemItem';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type IncomingParcelOrderItemState = {
  __typename?: 'IncomingParcelOrderItemState';
  created_at: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type IncomingParcelOrderItemStockItem = {
  __typename?: 'IncomingParcelOrderItemStockItem';
  id: Scalars['Int'];
  metadata: Scalars['JSONObject'];
  serial: Scalars['String'];
};

export type IncomingParcelOrderState = {
  __typename?: 'IncomingParcelOrderState';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type IncomingParcelsView = {
  __typename?: 'IncomingParcelsView';
  carrier_delivered_at?: Maybe<Scalars['String']>;
  carrier_shipped_at?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  order: IncomingParcelOrder;
  order_item: IncomingParcelOrderItem;
  to_id: Scalars['Int'];
  to_type: Scalars['String'];
  tracking?: Maybe<Scalars['String']>;
  unpacked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type InsuranceFees = {
  __typename?: 'InsuranceFees';
  breakageFee: Scalars['Int'];
  theftFee: Scalars['Int'];
};

export type IntFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Int']>>>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Scalars['Int']>;
  containsi?: Maybe<Scalars['Int']>;
  endsWith?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  eqi?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  nei?: Maybe<Scalars['Int']>;
  not?: Maybe<IntFilterInput>;
  notContains?: Maybe<Scalars['Int']>;
  notContainsi?: Maybe<Scalars['Int']>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startsWith?: Maybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type InvoiceChargeSuccessDto = {
  __typename?: 'InvoiceChargeSuccessDto';
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  paymentId: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type InvoiceCollectionStep = {
  __typename?: 'InvoiceCollectionStep';
  collectAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gateway?: Maybe<PaymentGateway>;
  id?: Maybe<Scalars['Float']>;
  invoiceCollectionId: Scalars['Float'];
  isBankDetailsAlive: Scalars['Boolean'];
  isCardAlive: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<InvoiceCollectionStepName>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['Float']>;
  state?: Maybe<InvoiceCollectionStepState>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum InvoiceCollectionStepName {
  BeCareful = 'BeCareful',
  Pipedrive = 'Pipedrive',
  PreRecovery = 'PreRecovery',
  Recovery = 'Recovery',
  Up = 'Up'
}

export enum InvoiceCollectionStepState {
  Executed = 'Executed',
  Scheduled = 'Scheduled',
  Skipped = 'Skipped'
}

export type InvoiceFilter = {
  isAmountDue?: Maybe<Scalars['Boolean']>;
  orderDirection?: Maybe<OrderByDirection>;
  orderKey?: Maybe<Scalars['String']>;
};

export type InvoicePaymentSourceInput = {
  apm?: Maybe<Scalars['JSONObject']>;
  bankDetails?: Maybe<Scalars['JSONObject']>;
  orderItem?: Maybe<Scalars['JSONObject']>;
  token?: Maybe<Scalars['JSONObject']>;
};

export type IsBannedCustomerError = {
  __typename?: 'IsBannedCustomerError';
  message: Scalars['String'];
};

export type IsBannedCustomerResult = IsBannedCustomerError | IsBannedCustomerSuccess;

export type IsBannedCustomerSuccess = {
  __typename?: 'IsBannedCustomerSuccess';
  isBanned: Scalars['Boolean'];
};

export type ItemsPartialAmounts = {
  itemId: Scalars['Int'];
  partialAmount: Scalars['Int'];
};


export type JsonFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  between?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  contains?: Maybe<Scalars['JSON']>;
  containsi?: Maybe<Scalars['JSON']>;
  endsWith?: Maybe<Scalars['JSON']>;
  eq?: Maybe<Scalars['JSON']>;
  eqi?: Maybe<Scalars['JSON']>;
  gt?: Maybe<Scalars['JSON']>;
  gte?: Maybe<Scalars['JSON']>;
  in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lt?: Maybe<Scalars['JSON']>;
  lte?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  nei?: Maybe<Scalars['JSON']>;
  not?: Maybe<JsonFilterInput>;
  notContains?: Maybe<Scalars['JSON']>;
  notContainsi?: Maybe<Scalars['JSON']>;
  notIn?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  startsWith?: Maybe<Scalars['JSON']>;
};


/** Available kyc email triggers */
export enum KycEmailTrigger {
  BankDetailsIncompleteDocument = 'BANK_DETAILS_INCOMPLETE_DOCUMENT',
  BankDetailsNotMainAccount = 'BANK_DETAILS_NOT_MAIN_ACCOUNT',
  BankDetailsStatementDoesNotMatch = 'BANK_DETAILS_STATEMENT_DOES_NOT_MATCH',
  BankDetailsWrongDocument = 'BANK_DETAILS_WRONG_DOCUMENT',
  UbbleNotOriginalDocument = 'UBBLE_NOT_ORIGINAL_DOCUMENT',
  UbbleTechnicalProblem = 'UBBLE_TECHNICAL_PROBLEM',
  UbbleWrongDocument = 'UBBLE_WRONG_DOCUMENT',
  UbbleWrongPerson = 'UBBLE_WRONG_PERSON'
}

/** Available KYC file references */
export enum KycFileName {
  AddressProof = 'ADDRESS_PROOF',
  Id = 'ID',
  Selfie = 'SELFIE'
}

export type KycCheckpointsCustomer = {
  __typename?: 'KycCheckpointsCustomer';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
};

export type KycCheckpointsOutcomeAndRelatedCustomer = {
  __typename?: 'KycCheckpointsOutcomeAndRelatedCustomer';
  outcome: Scalars['Boolean'];
  relatedCustomers?: Maybe<Array<KycCheckpointsCustomer>>;
};

export type KycCheckpointsResult = {
  __typename?: 'KycCheckpointsResult';
  actualPrepaidOperator: KycCheckpointsOutcomeAndRelatedCustomer;
  bankingCreditScore: KycCheckpointsOutcomeAndRelatedCustomer;
  bankingCreditScoreDevice: KycCheckpointsOutcomeAndRelatedCustomer;
  bankingIncidents: KycCheckpointsOutcomeAndRelatedCustomer;
  bankingWithdrawals: KycCheckpointsOutcomeAndRelatedCustomer;
  disposableEmail: KycCheckpointsOutcomeAndRelatedCustomer;
  formerPrepaidOperator: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSameAddressCustomers: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSameAddressCustomersFraud: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSameAddressCustomersUnpaid: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSameBankDetailsCustomers: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSameBankDetailsCustomersFraud: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSameBankDetailsCustomersUnpaid: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSamePhoneCustomers: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSamePhoneCustomersFraud: KycCheckpointsOutcomeAndRelatedCustomer;
  hasSamePhoneCustomersUnpaid: KycCheckpointsOutcomeAndRelatedCustomer;
  hasUsedJunkMail: Scalars['Boolean'];
  id: Scalars['Int'];
  identityNotVerified: KycCheckpointsOutcomeAndRelatedCustomer;
  isBankAccountOwner: KycCheckpointsOutcomeAndRelatedCustomer;
  isIntentionalFraudRisk: KycCheckpointsOutcomeAndRelatedCustomer;
  isSponsoredByFraud: KycCheckpointsOutcomeAndRelatedCustomer;
  isSponsoredByUnpaid: KycCheckpointsOutcomeAndRelatedCustomer;
  lifestyleSegment: KycCheckpointsOutcomeAndRelatedCustomer;
  prepaidOperator: KycCheckpointsOutcomeAndRelatedCustomer;
  vpn: KycCheckpointsOutcomeAndRelatedCustomer;
};

export enum KycErrorReason {
  ApplicationAlreadyAppliedForFinancing = 'ApplicationAlreadyAppliedForFinancing',
  ApplicationIsAborted = 'ApplicationIsAborted',
  InfrastructureError = 'InfrastructureError',
  KycNotFound = 'KycNotFound',
  KycNotFoundForCustomerId = 'KycNotFoundForCustomerId',
  KycNotFoundForOrderId = 'KycNotFoundForOrderId',
  NoIdentityProviderId = 'NoIdentityProviderId',
  ShouldNotBeValidated = 'ShouldNotBeValidated'
}

export type KycValidateError = {
  __typename?: 'KycValidateError';
  message: Scalars['String'];
};

export type KycValidateResult = KycValidateError | KycValidateSuccess;

export type KycValidateSuccess = {
  __typename?: 'KycValidateSuccess';
  success: Scalars['Boolean'];
};

export type LaptopFamily = {
  __typename?: 'LaptopFamily';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  display?: Maybe<Scalars['String']>;
  laptopVariants?: Maybe<LaptopVariantRelationResponseCollection>;
  model: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['String']>;
};


export type LaptopFamilyLaptopVariantsArgs = {
  filters?: Maybe<LaptopVariantFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LaptopFamilyEntity = {
  __typename?: 'LaptopFamilyEntity';
  attributes?: Maybe<LaptopFamily>;
  id?: Maybe<Scalars['ID']>;
};

export type LaptopFamilyEntityResponse = {
  __typename?: 'LaptopFamilyEntityResponse';
  data?: Maybe<LaptopFamilyEntity>;
};

export type LaptopFamilyEntityResponseCollection = {
  __typename?: 'LaptopFamilyEntityResponseCollection';
  data: Array<LaptopFamilyEntity>;
  meta: ResponseCollectionMeta;
};

export type LaptopFamilyFiltersInput = {
  and?: Maybe<Array<Maybe<LaptopFamilyFiltersInput>>>;
  brand?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  display?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  laptopVariants?: Maybe<LaptopVariantFiltersInput>;
  model?: Maybe<StringFilterInput>;
  not?: Maybe<LaptopFamilyFiltersInput>;
  or?: Maybe<Array<Maybe<LaptopFamilyFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  slug?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  year?: Maybe<StringFilterInput>;
};

export type LaptopFamilyInput = {
  brand?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  laptopVariants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  model?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type LaptopSubscription = {
  __typename?: 'LaptopSubscription';
  createdAt?: Maybe<Scalars['DateTime']>;
  laptopVariant?: Maybe<LaptopVariantEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  subscriptionItem?: Maybe<Items>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaptopSubscriptionEntity = {
  __typename?: 'LaptopSubscriptionEntity';
  attributes?: Maybe<LaptopSubscription>;
  id?: Maybe<Scalars['ID']>;
};

export type LaptopSubscriptionEntityResponse = {
  __typename?: 'LaptopSubscriptionEntityResponse';
  data?: Maybe<LaptopSubscriptionEntity>;
};

export type LaptopSubscriptionEntityResponseCollection = {
  __typename?: 'LaptopSubscriptionEntityResponseCollection';
  data: Array<LaptopSubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type LaptopSubscriptionFiltersInput = {
  and?: Maybe<Array<Maybe<LaptopSubscriptionFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  laptopVariant?: Maybe<LaptopVariantFiltersInput>;
  not?: Maybe<LaptopSubscriptionFiltersInput>;
  or?: Maybe<Array<Maybe<LaptopSubscriptionFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  sku?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type LaptopSubscriptionInput = {
  laptopVariant?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Scalars['String']>;
};

export type LaptopSubscriptionRelationResponseCollection = {
  __typename?: 'LaptopSubscriptionRelationResponseCollection';
  data: Array<LaptopSubscriptionEntity>;
};

export type LaptopVariant = {
  __typename?: 'LaptopVariant';
  chip?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cpu?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gpu?: Maybe<Scalars['String']>;
  keyboard?: Maybe<Scalars['String']>;
  laptopFamily?: Maybe<LaptopFamilyEntityResponse>;
  laptopSubscriptions?: Maybe<LaptopSubscriptionRelationResponseCollection>;
  memory?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  storage?: Maybe<Scalars['String']>;
  thumbnailPicture: UploadFileEntityResponse;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type LaptopVariantLaptopSubscriptionsArgs = {
  filters?: Maybe<LaptopSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LaptopVariantEntity = {
  __typename?: 'LaptopVariantEntity';
  attributes?: Maybe<LaptopVariant>;
  id?: Maybe<Scalars['ID']>;
};

export type LaptopVariantEntityResponse = {
  __typename?: 'LaptopVariantEntityResponse';
  data?: Maybe<LaptopVariantEntity>;
};

export type LaptopVariantEntityResponseCollection = {
  __typename?: 'LaptopVariantEntityResponseCollection';
  data: Array<LaptopVariantEntity>;
  meta: ResponseCollectionMeta;
};

export type LaptopVariantFiltersInput = {
  and?: Maybe<Array<Maybe<LaptopVariantFiltersInput>>>;
  chip?: Maybe<StringFilterInput>;
  color?: Maybe<StringFilterInput>;
  cpu?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  gpu?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  keyboard?: Maybe<StringFilterInput>;
  laptopFamily?: Maybe<LaptopFamilyFiltersInput>;
  laptopSubscriptions?: Maybe<LaptopSubscriptionFiltersInput>;
  memory?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<LaptopVariantFiltersInput>;
  or?: Maybe<Array<Maybe<LaptopVariantFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  storage?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type LaptopVariantInput = {
  chip?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cpu?: Maybe<Scalars['String']>;
  gpu?: Maybe<Scalars['String']>;
  keyboard?: Maybe<Scalars['String']>;
  laptopFamily?: Maybe<Scalars['ID']>;
  laptopSubscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  memory?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  storage?: Maybe<Scalars['String']>;
  thumbnailPicture?: Maybe<Scalars['ID']>;
};

export type LaptopVariantRelationResponseCollection = {
  __typename?: 'LaptopVariantRelationResponseCollection';
  data: Array<LaptopVariantEntity>;
};

export type ListCardsCard = {
  __typename?: 'ListCardsCard';
  brand?: Maybe<Scalars['String']>;
  expiry?: Maybe<ListCardsCardExpiry>;
  gateway: CustomerAccountPaymentMethodCardGateway;
  id: Scalars['String'];
  isAlive?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  last4Digits?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ListCardsCardExpiry = {
  __typename?: 'ListCardsCardExpiry';
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type ListCardsReponse = {
  __typename?: 'ListCardsReponse';
  cards: Array<ListCardsCard>;
};

export type LogisticListOrder = {
  __typename?: 'LogisticListOrder';
  code: Scalars['String'];
  createdAt: Scalars['String'];
  currentState: Scalars['String'];
  customerFirstname: Scalars['String'];
  customerId: Scalars['Float'];
  customerLastname: Scalars['String'];
  deviceName: Scalars['String'];
  deviceSerial?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  parcelUpdatedAt?: Maybe<Scalars['String']>;
  parentOrderId?: Maybe<Scalars['Float']>;
  stockCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type LpmCreateOrderArgs = {
  lpmIdentifiers: LpmIdentifiers;
  phoneSubscription: PhoneSubscriptionDto;
  subscriber: Subscriber;
};

export type LpmGetModelInsuranceFeesError = {
  __typename?: 'LpmGetModelInsuranceFeesError';
  message: Scalars['String'];
};

export type LpmGetModelInsuranceFeesResult = LpmGetModelInsuranceFeesError | LpmGetModelInsuranceFeesSuccess;

export type LpmGetModelInsuranceFeesSuccess = {
  __typename?: 'LpmGetModelInsuranceFeesSuccess';
  breakageFee: Scalars['Int'];
  theftFee: Scalars['Int'];
};

export type LpmIdentifiers = {
  contractId: Scalars['String'];
  salesPersonId: Scalars['String'];
};

export enum LpmOrderErrorReason {
  CannotCancelOrderDueToInvalidState = 'CannotCancelOrderDueToInvalidState',
  InfrastructureError = 'InfrastructureError',
  InvalidLpmContractId = 'InvalidLpmContractId',
  InvalidSubscriberEmail = 'InvalidSubscriberEmail',
  WrongSubscriptionProperties = 'WrongSubscriptionProperties'
}

export type LpmSalesFunnelCatalog = {
  __typename?: 'LpmSalesFunnelCatalog';
  catalogId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesLpmSmartphoneEntry>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type LpmSalesFunnelCatalogEntriesArgs = {
  filters?: Maybe<ComponentEntriesLpmSmartphoneEntryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LpmSalesFunnelCatalogEntity = {
  __typename?: 'LpmSalesFunnelCatalogEntity';
  attributes?: Maybe<LpmSalesFunnelCatalog>;
  id?: Maybe<Scalars['ID']>;
};

export type LpmSalesFunnelCatalogEntityResponse = {
  __typename?: 'LpmSalesFunnelCatalogEntityResponse';
  data?: Maybe<LpmSalesFunnelCatalogEntity>;
};

export type LpmSalesFunnelCatalogInput = {
  catalogId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<ComponentEntriesLpmSmartphoneEntryInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type MissingItemsDto = {
  __typename?: 'MissingItemsDto';
  monceau: Array<Scalars['String']>;
  tamet: Array<Scalars['String']>;
};

export type NewCardInput = {
  pspInput: Scalars['JSONObject'];
  returnUrl: Scalars['String'];
};

export type NonCompliantTransactionStatus = {
  __typename?: 'NonCompliantTransactionStatus';
  compliant: Scalars['Boolean'];
  message: Scalars['String'];
};

export type NotFoundError = {
  __typename?: 'NotFoundError';
  message: Scalars['String'];
};

export type OpenBankingInitiateAccountCheckError = {
  __typename?: 'OpenBankingInitiateAccountCheckError';
  message: Scalars['String'];
};

export type OpenBankingInitiateAccountCheckResult = OpenBankingInitiateAccountCheckError | OpenBankingInitiateAccountCheckSuccess;

export type OpenBankingInitiateAccountCheckSuccess = {
  __typename?: 'OpenBankingInitiateAccountCheckSuccess';
  success: Scalars['Boolean'];
  temporaryCode: Scalars['String'];
};

export type OpenBankingRevokeAccountCheckError = {
  __typename?: 'OpenBankingRevokeAccountCheckError';
  message: Scalars['String'];
};

export type OpenBankingRevokeAccountCheckResult = OpenBankingRevokeAccountCheckError | OpenBankingRevokeAccountCheckSuccess;

export type OpenBankingRevokeAccountCheckSuccess = {
  __typename?: 'OpenBankingRevokeAccountCheckSuccess';
  success: Scalars['Boolean'];
};

export type Order = {
  __typename?: 'Order';
  billingAddress?: Maybe<OrderAddress>;
  companyName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  isBusinessOrder?: Maybe<Scalars['Boolean']>;
  paymentSource?: Maybe<OrderPaymentSource>;
  shippingAddress?: Maybe<OrderAddress>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  extra_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type OrderAddressInput = {
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  extra_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderCustomerRevenueDto = {
  __typename?: 'OrderCustomerRevenueDto';
  customerRevenueInCentsExclTax: Scalars['Int'];
  firstCustomerOrderCreatedAt?: Maybe<Scalars['DateTime']>;
  orderSeniorityInMonths: Scalars['String'];
};

export type OrderItemLine = {
  buyingPriceInCents: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  taxCode?: Maybe<Scalars['String']>;
};

export enum OrderItemStateName {
  Active = 'Active',
  Created = 'Created',
  InPreparation = 'InPreparation',
  InTransit = 'InTransit',
  Inactive = 'Inactive',
  InsuranceBreakage = 'InsuranceBreakage',
  InsuranceBreakageNotCompliant = 'InsuranceBreakageNotCompliant',
  InsuranceBreakageRejected = 'InsuranceBreakageRejected',
  InsuranceBreakageRequested = 'InsuranceBreakageRequested',
  InsuranceBreakageReturned = 'InsuranceBreakageReturned',
  InsuranceBreakageReturning = 'InsuranceBreakageReturning',
  InsuranceBreakageValidated = 'InsuranceBreakageValidated',
  InsuranceBreakageWaitingIcloudUnlock = 'InsuranceBreakageWaitingIcloudUnlock',
  InsuranceBreakageWaitingReturn = 'InsuranceBreakageWaitingReturn',
  InsuranceTheft = 'InsuranceTheft',
  InsuranceTheftRejected = 'InsuranceTheftRejected',
  InsuranceTheftRequested = 'InsuranceTheftRequested',
  InsuranceTheftValidated = 'InsuranceTheftValidated',
  Resignation = 'Resignation',
  ResignationNotCompliant = 'ResignationNotCompliant',
  ResignationRequested = 'ResignationRequested',
  ResignationReturned = 'ResignationReturned',
  ResignationReturning = 'ResignationReturning',
  ResignationValidated = 'ResignationValidated',
  ResignationWaitingIcloudUnlock = 'ResignationWaitingIcloudUnlock',
  ResignationWaitingReturn = 'ResignationWaitingReturn',
  Stolen = 'Stolen',
  Swap = 'Swap',
  SwapNotCompliant = 'SwapNotCompliant',
  SwapRequested = 'SwapRequested',
  SwapReturned = 'SwapReturned',
  SwapReturning = 'SwapReturning',
  SwapValidated = 'SwapValidated',
  SwapWaitingIcloudUnlock = 'SwapWaitingIcloudUnlock',
  SwapWaitingReturn = 'SwapWaitingReturn',
  WaitingPortability = 'WaitingPortability',
  WaitingShipment = 'WaitingShipment',
  Warranty = 'Warranty',
  WarrantyNotCompliant = 'WarrantyNotCompliant',
  WarrantyRejected = 'WarrantyRejected',
  WarrantyRequested = 'WarrantyRequested',
  WarrantyReturned = 'WarrantyReturned',
  WarrantyReturning = 'WarrantyReturning',
  WarrantyValidated = 'WarrantyValidated',
  WarrantyWaitingIcloudUnlock = 'WarrantyWaitingIcloudUnlock',
  WarrantyWaitingReturn = 'WarrantyWaitingReturn'
}

export type OrderItemsInput = {
  itemId: Scalars['Int'];
  orderItemId: Scalars['Int'];
  stockItemCostPrice: Scalars['Int'];
};

export type OrderMetadata = {
  __typename?: 'OrderMetadata';
  hasPendingRefund: Scalars['Boolean'];
  orderId: Scalars['Int'];
};

export type OrderPaymentSource = {
  __typename?: 'OrderPaymentSource';
  bankDetails?: Maybe<Scalars['JSONObject']>;
  token?: Maybe<Scalars['JSONObject']>;
};

export type OrderPaymentSourceInput = {
  bankDetails?: Maybe<Scalars['JSONObject']>;
  token?: Maybe<Scalars['JSONObject']>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  code: Scalars['String'];
  id: Scalars['ID'];
  score: Scalars['Float'];
};

export type OrderSeniority = {
  __typename?: 'OrderSeniority';
  seniority: Scalars['Int'];
};

export enum OrderStateName {
  Active = 'Active',
  CancelRequested = 'CancelRequested',
  Canceled = 'Canceled',
  Created = 'Created',
  DepositToRefund = 'DepositToRefund',
  Draft = 'Draft',
  Migrated = 'Migrated',
  OrderShipped = 'OrderShipped',
  Swap = 'Swap',
  Terminated = 'Terminated',
  Validated = 'Validated',
  WaitingActivation = 'WaitingActivation',
  WaitingTermination = 'WaitingTermination'
}

export type OrderSubscriptionDeviceDto = {
  __typename?: 'OrderSubscriptionDeviceDto';
  brand: Scalars['String'];
  color: Scalars['String'];
  contractTerm: Scalars['Int'];
  id: Scalars['Int'];
  model: Scalars['String'];
  name: Scalars['String'];
  sku: Scalars['String'];
  storage: Scalars['String'];
  subscriptionPrice: Scalars['Int'];
  subscriptionTaxAmount: Scalars['Float'];
  upfrontId: Scalars['Int'];
  upfrontPrice: Scalars['Int'];
  upfrontTaxAmount: Scalars['Float'];
};

export type OrderSubscriptionDeviceInputDto = {
  id: Scalars['Int'];
  message: Scalars['String'];
  upfrontId: Scalars['Int'];
  upfrontPrice: Scalars['Int'];
  upfrontTaxAmount: Scalars['Float'];
};

export type OrderSwapCreateSwap = {
  __typename?: 'OrderSwapCreateSwap';
  orderId: Scalars['Int'];
};

export type OrderSwapDisableSwap = {
  __typename?: 'OrderSwapDisableSwap';
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type OrderSwapEnableSwap = {
  __typename?: 'OrderSwapEnableSwap';
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  validUntil?: Maybe<Scalars['String']>;
};

export type OrderSwapGetUpfrontDiscountPercentage = {
  __typename?: 'OrderSwapGetUpfrontDiscountPercentage';
  discount: Scalars['Int'];
};

export type OrderSwapSwap = {
  __typename?: 'OrderSwapSwap';
  orderId: Scalars['Int'];
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
};

export type Parcel = {
  __typename?: 'Parcel';
  aftershipId?: Maybe<Scalars['String']>;
  carrierDeliveredAt?: Maybe<Scalars['DateTime']>;
  carrierDeliveredLabel?: Maybe<Scalars['String']>;
  carrierDeliveredState?: Maybe<Scalars['String']>;
  carrierShippedAt?: Maybe<Scalars['DateTime']>;
  carrierShippedLabel?: Maybe<Scalars['String']>;
  carrierShippedState?: Maybe<Scalars['String']>;
  chronopostReservationNumber?: Maybe<Scalars['String']>;
  fromId: Scalars['Int'];
  fromType: ParcelEndpoint;
  gateway: ParcelGateway;
  id: Scalars['Int'];
  lastCarrierLabel?: Maybe<Scalars['String']>;
  lastCarrierState?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  refreshedAt?: Maybe<Scalars['DateTime']>;
  stockItems: Array<StockItem>;
  toId: Scalars['Int'];
  toType: ParcelEndpoint;
  tracking?: Maybe<Scalars['String']>;
  unpacked?: Maybe<Scalars['Boolean']>;
};

export enum ParcelEndpoint {
  Order = 'ORDER',
  Stock = 'STOCK',
  Supplier = 'SUPPLIER'
}

export enum ParcelGateway {
  Chronopost = 'CHRONOPOST',
  Courier = 'COURIER',
  Dhl = 'DHL',
  Fedex = 'FEDEX',
  Tnt = 'TNT',
  Ups = 'UPS'
}

export enum ParcelRef {
  InsBreakage = 'INS_BREAKAGE',
  InsTheft = 'INS_THEFT',
  New = 'NEW',
  ReturnBreakage = 'RETURN_BREAKAGE',
  ReturnResign = 'RETURN_RESIGN',
  ReturnResignF = 'RETURN_RESIGN_F',
  ReturnSwap = 'RETURN_SWAP',
  ReturnWarranty = 'RETURN_WARRANTY',
  Swap = 'SWAP',
  Warranty = 'WARRANTY'
}

export type ParcelResult = {
  __typename?: 'ParcelResult';
  id: Scalars['ID'];
  score: Scalars['Float'];
  trackingLabel: Scalars['String'];
};

export type PayInsuranceTicketError = {
  __typename?: 'PayInsuranceTicketError';
  success: Scalars['Boolean'];
};

export type PayInsuranceTicketResult = PayInsuranceTicketError | PayInsuranceTicketSuccess;

export type PayInsuranceTicketSuccess = {
  __typename?: 'PayInsuranceTicketSuccess';
  pspOutput: Scalars['JSONObject'];
};

export type PayOrderResponse = {
  __typename?: 'PayOrderResponse';
  pspOutput: Scalars['JSONObject'];
};

export type PaySelectedInvoicesArgs = {
  existingCard?: Maybe<ExistingCardInput>;
  invoiceIds: Array<Scalars['Int']>;
  newCard?: Maybe<NewCardInput>;
};

export type PayUnpaidInvoicesError = {
  __typename?: 'PayUnpaidInvoicesError';
  success: Scalars['Boolean'];
};

export type PayUnpaidInvoicesResult = PayUnpaidInvoicesError | PayUnpaidInvoicesSuccess;

export type PayUnpaidInvoicesSuccess = {
  __typename?: 'PayUnpaidInvoicesSuccess';
  pspOutput: Scalars['JSONObject'];
};

export type Payment = {
  __typename?: 'Payment';
  amountInCents: Scalars['Float'];
  authorized: Scalars['Boolean'];
  authorizedAt?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  captured: Scalars['Boolean'];
  capturedAt?: Maybe<Scalars['DateTime']>;
  chargedbackAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  fraud: Scalars['Boolean'];
  gateway: PaymentGateway;
  gatewayReference?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isAutomatic?: Maybe<Scalars['Boolean']>;
  metadata: Scalars['JSONObject'];
  order: Order;
  orderId: Scalars['Float'];
  parentPaymentId?: Maybe<Scalars['Int']>;
  paymentType: PaymentType;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum PaymentGateway {
  Adyen = 'Adyen',
  GoCardLess = 'GoCardLess',
  OrderItem = 'OrderItem',
  ProcessOut = 'ProcessOut',
  Qonto = 'Qonto'
}

export enum PaymentType {
  Charge = 'Charge',
  Refund = 'Refund'
}

export type PhonePlanSubscription = {
  __typename?: 'PhonePlanSubscription';
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  subscriptionItem?: Maybe<Items>;
  thumbnailPicture?: Maybe<UploadFileEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhonePlanSubscriptionEntity = {
  __typename?: 'PhonePlanSubscriptionEntity';
  attributes?: Maybe<PhonePlanSubscription>;
  id?: Maybe<Scalars['ID']>;
};

export type PhonePlanSubscriptionEntityResponse = {
  __typename?: 'PhonePlanSubscriptionEntityResponse';
  data?: Maybe<PhonePlanSubscriptionEntity>;
};

export type PhonePlanSubscriptionEntityResponseCollection = {
  __typename?: 'PhonePlanSubscriptionEntityResponseCollection';
  data: Array<PhonePlanSubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type PhonePlanSubscriptionFiltersInput = {
  and?: Maybe<Array<Maybe<PhonePlanSubscriptionFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<PhonePlanSubscriptionFiltersInput>;
  or?: Maybe<Array<Maybe<PhonePlanSubscriptionFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  sku?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type PhonePlanSubscriptionInput = {
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Scalars['String']>;
  thumbnailPicture?: Maybe<Scalars['ID']>;
};

export type PhoneSubscriptionDto = {
  sku: Scalars['String'];
};

export type PipedriveResolverResponse = {
  __typename?: 'PipedriveResolverResponse';
  success: Scalars['Boolean'];
};

export type PlaceInsuranceTicketResponse = {
  __typename?: 'PlaceInsuranceTicketResponse';
  success: Scalars['Boolean'];
};

export type PrintPreparationNoteError = {
  __typename?: 'PrintPreparationNoteError';
  reason: PrintPreparationNoteErrorReason;
};

export enum PrintPreparationNoteErrorReason {
  EmptyPreparationNoteConcatenation = 'EmptyPreparationNoteConcatenation',
  InfrastructureError = 'InfrastructureError',
  LambdaHtmToPdfFailed = 'LambdaHtmToPdfFailed',
  NoParcelsFound = 'NoParcelsFound',
  PreparationNoteConcatenationFailed = 'PreparationNoteConcatenationFailed',
  S3FileUploadFailed = 'S3FileUploadFailed',
  ShippingLabelGenerationFailed = 'ShippingLabelGenerationFailed',
  ShippingLabelNotFound = 'ShippingLabelNotFound'
}

export type PrintPreparationNoteResult = PrintPreparationNoteError | PrintPreparationNoteSuccess;

export type PrintPreparationNoteSuccess = {
  __typename?: 'PrintPreparationNoteSuccess';
  noteURL: Scalars['String'];
};

export type Privacy = {
  __typename?: 'Privacy';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrivacyEntity = {
  __typename?: 'PrivacyEntity';
  attributes?: Maybe<Privacy>;
  id?: Maybe<Scalars['ID']>;
};

export type PrivacyEntityResponse = {
  __typename?: 'PrivacyEntityResponse';
  data?: Maybe<PrivacyEntity>;
};

export type PrivacyInput = {
  content?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export enum ProcessParcelCosmeticGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  New = 'New',
  Unkown = 'Unkown'
}

export type ProcessParcelCosmeticInput = {
  backcover: ProcessParcelCosmeticGrade;
  chassis: ProcessParcelCosmeticGrade;
  comment?: Maybe<Scalars['String']>;
  irreparable?: Maybe<Scalars['Boolean']>;
  screen: ProcessParcelCosmeticGrade;
};

export type ProcessParcelInput = {
  cosmetic?: Maybe<ProcessParcelCosmeticInput>;
  unpack: ProcessParcelUnpackInput;
};

export type ProcessParcelUnpackInput = {
  iCloudLocked?: Maybe<Scalars['Boolean']>;
  imei: Scalars['String'];
  parcelId: Scalars['Int'];
  stockId: Scalars['Int'];
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type RateLimitDto = {
  __typename?: 'RateLimitDto';
  consumed: Scalars['Int'];
  remaining: Scalars['Int'];
  secondsBeforeReset: Scalars['Float'];
};

export type RefundPartial = RefundPartialError | RefundPartialSuccess;

export type RefundPartialError = {
  __typename?: 'RefundPartialError';
  message: Scalars['String'];
};

export type RefundPartialSuccess = {
  __typename?: 'RefundPartialSuccess';
  success: Scalars['Boolean'];
};

export type RefundTotalError = {
  __typename?: 'RefundTotalError';
  message: Scalars['String'];
};

export type RefundTotalResult = RefundTotalError | RefundTotalSuccess;

export type RefundTotalSuccess = {
  __typename?: 'RefundTotalSuccess';
  success: Scalars['Boolean'];
};

export type RefundableAmountDto = {
  __typename?: 'RefundableAmountDto';
  amountInCents: Scalars['Int'];
  orderItemId: Scalars['Int'];
};

export type RejectKycInput = {
  kycId: Scalars['Int'];
  metadata?: Maybe<Scalars['JSONObject']>;
  nextState: CustomerVerificationStateName;
};

export type RemoveCouponResponseError = {
  __typename?: 'RemoveCouponResponseError';
  message: Scalars['String'];
};

export type RemoveCouponResponseSuccess = {
  __typename?: 'RemoveCouponResponseSuccess';
  success: Scalars['Boolean'];
};

export type RemoveCouponResult = RemoveCouponResponseError | RemoveCouponResponseSuccess;

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type RetrieveIdentityInfosDto = {
  __typename?: 'RetrieveIdentityInfosDTO';
  birthDate?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type RetrieveIdentityInfosError = {
  __typename?: 'RetrieveIdentityInfosError';
  message: Scalars['String'];
};

export type RetrieveIdentityInfosResult = RetrieveIdentityInfosError | RetrieveIdentityInfosSuccess;

export type RetrieveIdentityInfosSuccess = {
  __typename?: 'RetrieveIdentityInfosSuccess';
  identityInfos?: Maybe<RetrieveIdentityInfosDto>;
};

export type Review = {
  __typename?: 'Review';
  author?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<ReviewRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ReviewLocalizationsArgs = {
  filters?: Maybe<ReviewFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReviewEntity = {
  __typename?: 'ReviewEntity';
  attributes?: Maybe<Review>;
  id?: Maybe<Scalars['ID']>;
};

export type ReviewEntityResponse = {
  __typename?: 'ReviewEntityResponse';
  data?: Maybe<ReviewEntity>;
};

export type ReviewEntityResponseCollection = {
  __typename?: 'ReviewEntityResponseCollection';
  data: Array<ReviewEntity>;
  meta: ResponseCollectionMeta;
};

export type ReviewFiltersInput = {
  and?: Maybe<Array<Maybe<ReviewFiltersInput>>>;
  author?: Maybe<StringFilterInput>;
  comment?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  date?: Maybe<DateFilterInput>;
  id?: Maybe<IdFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<ReviewFiltersInput>;
  not?: Maybe<ReviewFiltersInput>;
  or?: Maybe<Array<Maybe<ReviewFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  rating?: Maybe<IntFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type ReviewInput = {
  author?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
};

export type ReviewRelationResponseCollection = {
  __typename?: 'ReviewRelationResponseCollection';
  data: Array<ReviewEntity>;
};

export type RevokeKycSignatureError = {
  __typename?: 'RevokeKycSignatureError';
  message: Scalars['String'];
};

export type RevokeKycSignatureResult = RevokeKycSignatureError | RevokeKycSignatureSuccess;

export type RevokeKycSignatureSuccess = {
  __typename?: 'RevokeKycSignatureSuccess';
  success: Scalars['Boolean'];
};

export type RollbackStockItemError = {
  __typename?: 'RollbackStockItemError';
  message: Scalars['String'];
};

export type RollbackStockItemResult = RollbackStockItemError | RollbackStockItemSuccess;

export type RollbackStockItemSuccess = {
  __typename?: 'RollbackStockItemSuccess';
  message: Scalars['String'];
};

export type SearchResult = CustomerResult | OrderResult | ParcelResult;

export type SeoProperty = {
  __typename?: 'SeoProperty';
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<SeoPropertyRelationResponseCollection>;
  pageTitle: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  seo?: Maybe<ComponentSharedSeo>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SeoPropertyLocalizationsArgs = {
  filters?: Maybe<SeoPropertyFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SeoPropertyEntity = {
  __typename?: 'SeoPropertyEntity';
  attributes?: Maybe<SeoProperty>;
  id?: Maybe<Scalars['ID']>;
};

export type SeoPropertyEntityResponse = {
  __typename?: 'SeoPropertyEntityResponse';
  data?: Maybe<SeoPropertyEntity>;
};

export type SeoPropertyEntityResponseCollection = {
  __typename?: 'SeoPropertyEntityResponseCollection';
  data: Array<SeoPropertyEntity>;
  meta: ResponseCollectionMeta;
};

export type SeoPropertyFiltersInput = {
  and?: Maybe<Array<Maybe<SeoPropertyFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<SeoPropertyFiltersInput>;
  not?: Maybe<SeoPropertyFiltersInput>;
  or?: Maybe<Array<Maybe<SeoPropertyFiltersInput>>>;
  pageTitle?: Maybe<StringFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  seo?: Maybe<ComponentSharedSeoFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type SeoPropertyInput = {
  pageTitle?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seo?: Maybe<ComponentSharedSeoInput>;
};

export type SeoPropertyRelationResponseCollection = {
  __typename?: 'SeoPropertyRelationResponseCollection';
  data: Array<SeoPropertyEntity>;
};

export type SetRoleAndPermissionsError = {
  __typename?: 'SetRoleAndPermissionsError';
  message: Scalars['String'];
};

export type SetRoleAndPermissionsResult = SetRoleAndPermissionsError | SetRoleAndPermissionsSuccess;

export type SetRoleAndPermissionsSuccess = {
  __typename?: 'SetRoleAndPermissionsSuccess';
  assignedRole: UserRole;
  toggledPermissionsCount: Scalars['Int'];
};

export enum ShippingGateway {
  UpsExpress = 'UpsExpress',
  UpsPickup = 'UpsPickup',
  UpsStandard = 'UpsStandard'
}

export type ShouldDeclareStatusAndIncomeError = {
  __typename?: 'ShouldDeclareStatusAndIncomeError';
  message: Scalars['String'];
};

export type ShouldDeclareStatusAndIncomeResult = ShouldDeclareStatusAndIncomeError | ShouldDeclareStatusAndIncomeSuccess;

export type ShouldDeclareStatusAndIncomeSuccess = {
  __typename?: 'ShouldDeclareStatusAndIncomeSuccess';
  shouldDeclareStatusAndIncome: Scalars['Boolean'];
};

export type SignatoryInput = {
  birthdate: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type SimilarCustomer = {
  __typename?: 'SimilarCustomer';
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  orderState?: Maybe<Scalars['String']>;
};

export type SimilarCustomerSplit = {
  __typename?: 'SimilarCustomerSplit';
  regular: Array<SimilarCustomer>;
  unpaid: Array<SimilarCustomer>;
};

export type SimilarUnpaidCustomerNearby = {
  __typename?: 'SimilarUnpaidCustomerNearby';
  sameZip: Array<SimilarCustomer>;
  sameZipWithStreetOccurences: Array<SimilarCustomer>;
};

export type SmartphoneFamily = {
  __typename?: 'SmartphoneFamily';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dasValues?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  descriptionImage?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<SmartphoneFamilyRelationResponseCollection>;
  model: Scalars['String'];
  ogImage?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  releaseDate?: Maybe<Scalars['String']>;
  replacement?: Maybe<SmartphoneFamilyEntityResponse>;
  slug: Scalars['String'];
  smartphoneVariants?: Maybe<SmartphoneVariantRelationResponseCollection>;
  tips?: Maybe<Array<Maybe<ComponentTipsCategory>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SmartphoneFamilyLocalizationsArgs = {
  filters?: Maybe<SmartphoneFamilyFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SmartphoneFamilySmartphoneVariantsArgs = {
  filters?: Maybe<SmartphoneVariantFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SmartphoneFamilyTipsArgs = {
  filters?: Maybe<ComponentTipsCategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SmartphoneFamilyEntity = {
  __typename?: 'SmartphoneFamilyEntity';
  attributes?: Maybe<SmartphoneFamily>;
  id?: Maybe<Scalars['ID']>;
};

export type SmartphoneFamilyEntityResponse = {
  __typename?: 'SmartphoneFamilyEntityResponse';
  data?: Maybe<SmartphoneFamilyEntity>;
};

export type SmartphoneFamilyEntityResponseCollection = {
  __typename?: 'SmartphoneFamilyEntityResponseCollection';
  data: Array<SmartphoneFamilyEntity>;
  meta: ResponseCollectionMeta;
};

export type SmartphoneFamilyFiltersInput = {
  and?: Maybe<Array<Maybe<SmartphoneFamilyFiltersInput>>>;
  brand?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  dasValues?: Maybe<StringFilterInput>;
  description?: Maybe<JsonFilterInput>;
  id?: Maybe<IdFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<SmartphoneFamilyFiltersInput>;
  model?: Maybe<StringFilterInput>;
  not?: Maybe<SmartphoneFamilyFiltersInput>;
  or?: Maybe<Array<Maybe<SmartphoneFamilyFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  releaseDate?: Maybe<StringFilterInput>;
  replacement?: Maybe<SmartphoneFamilyFiltersInput>;
  slug?: Maybe<StringFilterInput>;
  smartphoneVariants?: Maybe<SmartphoneVariantFiltersInput>;
  tips?: Maybe<ComponentTipsCategoryFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type SmartphoneFamilyInput = {
  brand?: Maybe<Scalars['String']>;
  dasValues?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  descriptionImage?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  releaseDate?: Maybe<Scalars['String']>;
  replacement?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  smartphoneVariants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tips?: Maybe<Array<Maybe<ComponentTipsCategoryInput>>>;
};

export type SmartphoneFamilyRelationResponseCollection = {
  __typename?: 'SmartphoneFamilyRelationResponseCollection';
  data: Array<SmartphoneFamilyEntity>;
};

export type SmartphoneSubscription = {
  __typename?: 'SmartphoneSubscription';
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<SmartphoneSubscriptionRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  smartphoneVariant?: Maybe<SmartphoneVariantEntityResponse>;
  subscriptionItem?: Maybe<Items>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SmartphoneSubscriptionLocalizationsArgs = {
  filters?: Maybe<SmartphoneSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SmartphoneSubscriptionEntity = {
  __typename?: 'SmartphoneSubscriptionEntity';
  attributes?: Maybe<SmartphoneSubscription>;
  id?: Maybe<Scalars['ID']>;
};

export type SmartphoneSubscriptionEntityResponse = {
  __typename?: 'SmartphoneSubscriptionEntityResponse';
  data?: Maybe<SmartphoneSubscriptionEntity>;
};

export type SmartphoneSubscriptionEntityResponseCollection = {
  __typename?: 'SmartphoneSubscriptionEntityResponseCollection';
  data: Array<SmartphoneSubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type SmartphoneSubscriptionFiltersInput = {
  and?: Maybe<Array<Maybe<SmartphoneSubscriptionFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<SmartphoneSubscriptionFiltersInput>;
  not?: Maybe<SmartphoneSubscriptionFiltersInput>;
  or?: Maybe<Array<Maybe<SmartphoneSubscriptionFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  sku?: Maybe<StringFilterInput>;
  smartphoneVariant?: Maybe<SmartphoneVariantFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type SmartphoneSubscriptionInput = {
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Scalars['String']>;
  smartphoneVariant?: Maybe<Scalars['ID']>;
};

export type SmartphoneSubscriptionRelationResponseCollection = {
  __typename?: 'SmartphoneSubscriptionRelationResponseCollection';
  data: Array<SmartphoneSubscriptionEntity>;
};

export type SmartphoneVariant = {
  __typename?: 'SmartphoneVariant';
  backPicture?: Maybe<UploadFileEntityResponse>;
  colorCode?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  combinedPicture?: Maybe<UploadFileEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frontPicture?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<SmartphoneVariantRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  smartphoneFamily?: Maybe<SmartphoneFamilyEntityResponse>;
  smartphoneSubscriptions?: Maybe<SmartphoneSubscriptionRelationResponseCollection>;
  storage?: Maybe<Scalars['String']>;
  thumbnailPicture?: Maybe<UploadFileEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SmartphoneVariantLocalizationsArgs = {
  filters?: Maybe<SmartphoneVariantFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SmartphoneVariantSmartphoneSubscriptionsArgs = {
  filters?: Maybe<SmartphoneSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SmartphoneVariantEntity = {
  __typename?: 'SmartphoneVariantEntity';
  attributes?: Maybe<SmartphoneVariant>;
  id?: Maybe<Scalars['ID']>;
};

export type SmartphoneVariantEntityResponse = {
  __typename?: 'SmartphoneVariantEntityResponse';
  data?: Maybe<SmartphoneVariantEntity>;
};

export type SmartphoneVariantEntityResponseCollection = {
  __typename?: 'SmartphoneVariantEntityResponseCollection';
  data: Array<SmartphoneVariantEntity>;
  meta: ResponseCollectionMeta;
};

export type SmartphoneVariantFiltersInput = {
  and?: Maybe<Array<Maybe<SmartphoneVariantFiltersInput>>>;
  colorCode?: Maybe<StringFilterInput>;
  colorName?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<SmartphoneVariantFiltersInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<SmartphoneVariantFiltersInput>;
  or?: Maybe<Array<Maybe<SmartphoneVariantFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  slug?: Maybe<StringFilterInput>;
  smartphoneFamily?: Maybe<SmartphoneFamilyFiltersInput>;
  smartphoneSubscriptions?: Maybe<SmartphoneSubscriptionFiltersInput>;
  storage?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type SmartphoneVariantInput = {
  backPicture?: Maybe<Scalars['ID']>;
  colorCode?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  combinedPicture?: Maybe<Scalars['ID']>;
  frontPicture?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  smartphoneFamily?: Maybe<Scalars['ID']>;
  smartphoneSubscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  storage?: Maybe<Scalars['String']>;
  thumbnailPicture?: Maybe<Scalars['ID']>;
};

export type SmartphoneVariantRelationResponseCollection = {
  __typename?: 'SmartphoneVariantRelationResponseCollection';
  data: Array<SmartphoneVariantEntity>;
};

export type StockItem = {
  __typename?: 'StockItem';
  createdAt: Scalars['DateTime'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  locationType: StockItemLocation;
  parcels: Array<Parcel>;
  receivedAt: Scalars['DateTime'];
  serial: Scalars['String'];
  soldAt: Scalars['DateTime'];
  supplierInvoiceReference?: Maybe<Scalars['String']>;
  supplierOrderReference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum StockItemLocation {
  Order = 'ORDER',
  Parcel = 'PARCEL',
  Stock = 'STOCK'
}

export type StockItemOwnershipDto = {
  __typename?: 'StockItemOwnershipDTO';
  businessEntity: BusinessEntity;
  buyingPrice: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  stockItem: StockItem;
  updatedAt: Scalars['DateTime'];
};

export enum StockItemStateName {
  Assigned = 'Assigned',
  Ko = 'Ko',
  Lost = 'Lost',
  Ok = 'Ok',
  Rented = 'Rented',
  RepairAccepted = 'RepairAccepted',
  RepairRejected = 'RepairRejected',
  Rma = 'Rma',
  RmaCreditInvoice = 'RmaCreditInvoice',
  RmaDeviceSwapped = 'RmaDeviceSwapped',
  Sold = 'Sold',
  Stolen = 'Stolen',
  StolenInsurance = 'StolenInsurance',
  ToQualify = 'ToQualify',
  ToRefurb = 'ToRefurb',
  ToRepair = 'ToRepair',
  ToSell = 'ToSell'
}

export type StockItemsInput = {
  gateway?: Maybe<Scalars['String']>;
  orderId: Scalars['Int'];
  orderItemId: Scalars['Int'];
  parcelId?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
  shipmentTracking?: Maybe<Scalars['String']>;
  stockItemId: Scalars['Int'];
};

export enum StockMovementNoteFormat {
  Csv = 'csv',
  Pdf = 'pdf'
}

export type StringFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['String']>>>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  containsi?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  eqi?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  nei?: Maybe<Scalars['String']>;
  not?: Maybe<StringFilterInput>;
  notContains?: Maybe<Scalars['String']>;
  notContainsi?: Maybe<Scalars['String']>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type Subscriber = {
  address: AddressDto;
  bankDetails: BankDetailsDto;
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type SuccessObjectDto = {
  __typename?: 'SuccessObjectDto';
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SupplierInfosInput = {
  orderLink: Scalars['String'];
  orderReference: Scalars['String'];
  supplierId: Scalars['Int'];
};

export type TabletFamily = {
  __typename?: 'TabletFamily';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  generation?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  tabletVariants?: Maybe<TabletVariantRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type TabletFamilyTabletVariantsArgs = {
  filters?: Maybe<TabletVariantFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TabletFamilyEntity = {
  __typename?: 'TabletFamilyEntity';
  attributes?: Maybe<TabletFamily>;
  id?: Maybe<Scalars['ID']>;
};

export type TabletFamilyEntityResponse = {
  __typename?: 'TabletFamilyEntityResponse';
  data?: Maybe<TabletFamilyEntity>;
};

export type TabletFamilyEntityResponseCollection = {
  __typename?: 'TabletFamilyEntityResponseCollection';
  data: Array<TabletFamilyEntity>;
  meta: ResponseCollectionMeta;
};

export type TabletFamilyFiltersInput = {
  and?: Maybe<Array<Maybe<TabletFamilyFiltersInput>>>;
  brand?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  generation?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  model?: Maybe<StringFilterInput>;
  not?: Maybe<TabletFamilyFiltersInput>;
  or?: Maybe<Array<Maybe<TabletFamilyFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  slug?: Maybe<StringFilterInput>;
  tabletVariants?: Maybe<TabletVariantFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type TabletFamilyInput = {
  brand?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  tabletVariants?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TabletSubscription = {
  __typename?: 'TabletSubscription';
  createdAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  subscriptionItem?: Maybe<Items>;
  tabletVariant?: Maybe<TabletVariantEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TabletSubscriptionEntity = {
  __typename?: 'TabletSubscriptionEntity';
  attributes?: Maybe<TabletSubscription>;
  id?: Maybe<Scalars['ID']>;
};

export type TabletSubscriptionEntityResponse = {
  __typename?: 'TabletSubscriptionEntityResponse';
  data?: Maybe<TabletSubscriptionEntity>;
};

export type TabletSubscriptionEntityResponseCollection = {
  __typename?: 'TabletSubscriptionEntityResponseCollection';
  data: Array<TabletSubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type TabletSubscriptionFiltersInput = {
  and?: Maybe<Array<Maybe<TabletSubscriptionFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<TabletSubscriptionFiltersInput>;
  or?: Maybe<Array<Maybe<TabletSubscriptionFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  sku?: Maybe<StringFilterInput>;
  tabletVariant?: Maybe<TabletVariantFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type TabletSubscriptionInput = {
  publishedAt?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Scalars['String']>;
  tabletVariant?: Maybe<Scalars['ID']>;
};

export type TabletSubscriptionRelationResponseCollection = {
  __typename?: 'TabletSubscriptionRelationResponseCollection';
  data: Array<TabletSubscriptionEntity>;
};

export type TabletVariant = {
  __typename?: 'TabletVariant';
  chip?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  connectivity?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  display: Scalars['String'];
  memory?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  storage?: Maybe<Scalars['String']>;
  tabletFamily?: Maybe<TabletFamilyEntityResponse>;
  tabletSubscriptions?: Maybe<TabletSubscriptionRelationResponseCollection>;
  thumbnailPicture?: Maybe<UploadFileEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['String']>;
};


export type TabletVariantTabletSubscriptionsArgs = {
  filters?: Maybe<TabletSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TabletVariantEntity = {
  __typename?: 'TabletVariantEntity';
  attributes?: Maybe<TabletVariant>;
  id?: Maybe<Scalars['ID']>;
};

export type TabletVariantEntityResponse = {
  __typename?: 'TabletVariantEntityResponse';
  data?: Maybe<TabletVariantEntity>;
};

export type TabletVariantEntityResponseCollection = {
  __typename?: 'TabletVariantEntityResponseCollection';
  data: Array<TabletVariantEntity>;
  meta: ResponseCollectionMeta;
};

export type TabletVariantFiltersInput = {
  and?: Maybe<Array<Maybe<TabletVariantFiltersInput>>>;
  chip?: Maybe<StringFilterInput>;
  color?: Maybe<StringFilterInput>;
  connectivity?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  display?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  memory?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<TabletVariantFiltersInput>;
  or?: Maybe<Array<Maybe<TabletVariantFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  storage?: Maybe<StringFilterInput>;
  tabletFamily?: Maybe<TabletFamilyFiltersInput>;
  tabletSubscriptions?: Maybe<TabletSubscriptionFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  year?: Maybe<StringFilterInput>;
};

export type TabletVariantInput = {
  chip?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  connectivity?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  memory?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  storage?: Maybe<Scalars['String']>;
  tabletFamily?: Maybe<Scalars['ID']>;
  tabletSubscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  thumbnailPicture?: Maybe<Scalars['ID']>;
  year?: Maybe<Scalars['String']>;
};

export type TabletVariantRelationResponseCollection = {
  __typename?: 'TabletVariantRelationResponseCollection';
  data: Array<TabletVariantEntity>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isCatalogFilter?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<TagRelationResponseCollection>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type TagLocalizationsArgs = {
  filters?: Maybe<TagFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagEntity = {
  __typename?: 'TagEntity';
  attributes?: Maybe<Tag>;
  id?: Maybe<Scalars['ID']>;
};

export type TagEntityResponse = {
  __typename?: 'TagEntityResponse';
  data?: Maybe<TagEntity>;
};

export type TagEntityResponseCollection = {
  __typename?: 'TagEntityResponseCollection';
  data: Array<TagEntity>;
  meta: ResponseCollectionMeta;
};

export type TagFiltersInput = {
  and?: Maybe<Array<Maybe<TagFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  description?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  isCatalogFilter?: Maybe<BooleanFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<TagFiltersInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<TagFiltersInput>;
  or?: Maybe<Array<Maybe<TagFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  slug?: Maybe<StringFilterInput>;
  title?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type TagInput = {
  description?: Maybe<Scalars['String']>;
  isCatalogFilter?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TagRelationResponseCollection = {
  __typename?: 'TagRelationResponseCollection';
  data: Array<TagEntity>;
};


export type ToAssignDeletePurchases = {
  devices: Array<DevicesInput>;
};

export type ToAssignUpdateInfosInput = {
  orderLink?: Maybe<Scalars['String']>;
  stockItems: Array<StockItemsInput>;
  supplierInvoiceReference?: Maybe<Scalars['String']>;
  supplierStockId?: Maybe<Scalars['Int']>;
};

export type ToPurchaseConfirmationInput = {
  orderItems: Array<OrderItemsInput>;
  ordersIds: Array<Scalars['Int']>;
  supplierInfos: SupplierInfosInput;
};

export type ToggleUserStatusError = {
  __typename?: 'ToggleUserStatusError';
  message: Scalars['String'];
};

export type ToggleUserStatusResult = ToggleUserStatusError | ToggleUserStatusSuccess;

export type ToggleUserStatusSuccess = {
  __typename?: 'ToggleUserStatusSuccess';
  isSuccess: Scalars['Boolean'];
};

export type TrackBankConnectionError = {
  __typename?: 'TrackBankConnectionError';
  message: Scalars['String'];
};

export type TrackBankConnectionResult = TrackBankConnectionError | TrackBankConnectionSuccess;

export type TrackBankConnectionSuccess = {
  __typename?: 'TrackBankConnectionSuccess';
  success: Scalars['Boolean'];
};

export type TransactionStatusResultDto = CompliantTransactionStatus | NonCompliantTransactionStatus | TransactionStatusResultError;

export type TransactionStatusResultError = {
  __typename?: 'TransactionStatusResultError';
  message: Scalars['String'];
};

export type Translation = {
  __typename?: 'Translation';
  createdAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<TranslationRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  table: Enum_Translation_Table;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};


export type TranslationLocalizationsArgs = {
  filters?: Maybe<TranslationFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TranslationEntity = {
  __typename?: 'TranslationEntity';
  attributes?: Maybe<Translation>;
  id?: Maybe<Scalars['ID']>;
};

export type TranslationEntityResponse = {
  __typename?: 'TranslationEntityResponse';
  data?: Maybe<TranslationEntity>;
};

export type TranslationEntityResponseCollection = {
  __typename?: 'TranslationEntityResponseCollection';
  data: Array<TranslationEntity>;
  meta: ResponseCollectionMeta;
};

export type TranslationFiltersInput = {
  and?: Maybe<Array<Maybe<TranslationFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  key?: Maybe<StringFilterInput>;
  locale?: Maybe<StringFilterInput>;
  localizations?: Maybe<TranslationFiltersInput>;
  not?: Maybe<TranslationFiltersInput>;
  or?: Maybe<Array<Maybe<TranslationFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  table?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  value?: Maybe<StringFilterInput>;
};

export type TranslationInput = {
  key?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Enum_Translation_Table>;
  value?: Maybe<Scalars['String']>;
};

export type TranslationRelationResponseCollection = {
  __typename?: 'TranslationRelationResponseCollection';
  data: Array<TranslationEntity>;
};

export type UpsAccessPointsInput = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type UbblePreviousIdentification = {
  __typename?: 'UbblePreviousIdentification';
  createdAt: Scalars['DateTime'];
  documentType: Scalars['String'];
  identificationId: Scalars['String'];
  responseCodes: Array<UbbleResultResponseCode>;
  status: Scalars['String'];
};

export type UbbleResultResponseCode = {
  __typename?: 'UbbleResultResponseCode';
  code: Scalars['String'];
  summary: Scalars['String'];
};

export type UnbanCustomerError = {
  __typename?: 'UnbanCustomerError';
  message: Scalars['String'];
};

export type UnbanCustomerResult = UnbanCustomerError | UnbanCustomerSuccess;

export type UnbanCustomerSuccess = {
  __typename?: 'UnbanCustomerSuccess';
  success: Scalars['Boolean'];
};

export type UnpaidCustomer = {
  __typename?: 'UnpaidCustomer';
  activeAt: Scalars['DateTime'];
  amountDue: Scalars['Int'];
  customerName: Scalars['String'];
  id: Scalars['Int'];
  invoicesCount: Scalars['Int'];
  orders: Array<UnpaidCustomerOrder>;
  unpaidSince: Scalars['DateTime'];
};

export type UnpaidCustomerOrder = {
  __typename?: 'UnpaidCustomerOrder';
  code: Scalars['String'];
  id: Scalars['Int'];
};

export type UnpaidCustomers = {
  __typename?: 'UnpaidCustomers';
  count: Scalars['Int'];
  rows: Array<UnpaidCustomer>;
};

export type UpdateCustomerBillingAddressInput = {
  billingAddress: OrderAddressInput;
};

export type UpdateCustomerInput = {
  acceptedCguAt?: Maybe<Scalars['DateTime']>;
  acceptedContractAt?: Maybe<Scalars['DateTime']>;
  acceptedMarketingAt?: Maybe<Scalars['DateTime']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provenance?: Maybe<Scalars['String']>;
};

export type UpdateCustomerShippingAddressInput = {
  shippingAddress: OrderAddressInput;
};

export type UpdateDraftOrderAttributes = {
  billingAddress?: Maybe<OrderAddressInput>;
  companyName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  isBusinessOrder?: Maybe<Scalars['Boolean']>;
  shippingAddress?: Maybe<OrderAddressInput>;
};

export type UpdateInsuranceTicketResponse = {
  __typename?: 'UpdateInsuranceTicketResponse';
  success: Scalars['Boolean'];
};

export type UpdateStatusAndIncomeArgs = {
  birthCity?: Maybe<Scalars['String']>;
  birthCountryCode?: Maybe<Scalars['String']>;
  birthZipCode?: Maybe<Scalars['String']>;
  dependentChildrenNumber?: Maybe<Scalars['Int']>;
  housingType?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  monthlyExpenses?: Maybe<Scalars['Int']>;
  netMonthlyIncome?: Maybe<Scalars['Int']>;
  socioProfessionalCategoryCode?: Maybe<Scalars['String']>;
};

export type UpdateStatusAndIncomeResult = UpdateStatusAndIncomeSuccess;

export type UpdateStatusAndIncomeSuccess = {
  __typename?: 'UpdateStatusAndIncomeSuccess';
  isSuccess: Scalars['Boolean'];
};


export type UploadBankStatementResult = {
  __typename?: 'UploadBankStatementResult';
  breakageInsuranceFee: BreakingInsuranceFeeResponse;
  hasAlreadyDeclaredAndPaidDamages: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  verification: Scalars['Boolean'];
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  caption?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  ext?: Maybe<StringFilterInput>;
  folder?: Maybe<UploadFolderFiltersInput>;
  folderPath?: Maybe<StringFilterInput>;
  formats?: Maybe<JsonFilterInput>;
  hash?: Maybe<StringFilterInput>;
  height?: Maybe<IntFilterInput>;
  id?: Maybe<IdFilterInput>;
  mime?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<UploadFileFiltersInput>;
  or?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  previewUrl?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  provider_metadata?: Maybe<JsonFilterInput>;
  size?: Maybe<FloatFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  url?: Maybe<StringFilterInput>;
  width?: Maybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['ID']>;
  folderPath?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['JSON']>;
  size?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  children?: Maybe<UploadFolderFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  files?: Maybe<UploadFileFiltersInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<UploadFolderFiltersInput>;
  or?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  parent?: Maybe<UploadFolderFiltersInput>;
  path?: Maybe<StringFilterInput>;
  pathId?: Maybe<IntFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  files?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  pathId?: Maybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UpsAccessPoint = {
  city?: Maybe<Scalars['String']>;
  distanceInKM?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserCountResult = UserCountSuccess;

export type UserCountSuccess = {
  __typename?: 'UserCountSuccess';
  count: Scalars['Int'];
};

export type UserDetailsError = {
  __typename?: 'UserDetailsError';
  message: Scalars['String'];
};

export type UserDetailsResult = UserDetailsError | UserDetailsSuccess;

export type UserDetailsSuccess = {
  __typename?: 'UserDetailsSuccess';
  createdAt?: Maybe<Scalars['Timestamp']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  permissions: Array<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  role: UserRole;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UserDto = {
  __typename?: 'UserDto';
  email: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastConnectedAt?: Maybe<Scalars['Timestamp']>;
  name?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  role: UserRole;
};

export enum UserRole {
  Admin = 'ADMIN',
  LimitedAccess = 'LIMITED_ACCESS',
  System = 'SYSTEM'
}

export type UserRoleAndPermissionsError = {
  __typename?: 'UserRoleAndPermissionsError';
  message: Scalars['String'];
};

export type UserRoleAndPermissionsResult = UserRoleAndPermissionsError | UserRoleAndPermissionsSuccess;

export type UserRoleAndPermissionsSuccess = {
  __typename?: 'UserRoleAndPermissionsSuccess';
  permissions: Array<Scalars['String']>;
  role: UserRole;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<UsersPermissionsPermissionFiltersInput>;
  or?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: Maybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  description?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<UsersPermissionsRoleFiltersInput>;
  or?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: Maybe<UsersPermissionsPermissionFiltersInput>;
  type?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  users?: Maybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: Maybe<BooleanFilterInput>;
  confirmationToken?: Maybe<StringFilterInput>;
  confirmed?: Maybe<BooleanFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  email?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<UsersPermissionsUserFiltersInput>;
  or?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  password?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  resetPasswordToken?: Maybe<StringFilterInput>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  username?: Maybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: Maybe<Scalars['Boolean']>;
  confirmationToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type UsersResult = UsersSuccess;

export type UsersSuccess = {
  __typename?: 'UsersSuccess';
  users: Array<Maybe<UserDto>>;
};

export enum WarrantyTicketCategory {
  AccessoriesAccessoriesCharge = 'Accessories_AccessoriesCharge',
  AccessoriesCase = 'Accessories_Case',
  AccessoriesHeadphones = 'Accessories_Headphones',
  AccessoriesLighteningCable = 'Accessories_LighteningCable',
  AccessoriesLighteningJack = 'Accessories_LighteningJack',
  AccessoriesPin = 'Accessories_Pin',
  AccessoriesTemperedGlass = 'Accessories_TemperedGlass',
  BatteryAutonomy = 'Battery_Autonomy',
  BatteryBatteryCharge = 'Battery_BatteryCharge',
  BatteryBatteryOff = 'Battery_BatteryOff',
  BatteryOverheating = 'Battery_Overheating',
  BatterySwelling = 'Battery_Swelling',
  ButtonsHomeTouchId = 'Buttons_HomeTouchId',
  ButtonsPower = 'Buttons_Power',
  ButtonsSilence = 'Buttons_Silence',
  ButtonsVolume = 'Buttons_Volume',
  CameraBlackScreen = 'Camera_BlackScreen',
  CameraFaceId = 'Camera_FaceId',
  CameraFaceTime = 'Camera_FaceTime',
  CameraFocus = 'Camera_Focus',
  CameraShaking = 'Camera_Shaking',
  CameraStains = 'Camera_Stains',
  NetworkActivation = 'Network_Activation',
  NetworkBluetooth = 'Network_Bluetooth',
  NetworkNoService = 'Network_NoService',
  NetworkSimcard = 'Network_Simcard',
  NetworkWifi = 'Network_Wifi',
  ScreenBrightness = 'Screen_Brightness',
  ScreenColorBar = 'Screen_ColorBar',
  ScreenMad = 'Screen_Mad',
  ScreenPixels = 'Screen_Pixels',
  ScreenTouch = 'Screen_Touch',
  SoundHeadphoneConnections = 'Sound_HeadphoneConnections',
  SoundInternalSpeaker = 'Sound_InternalSpeaker',
  SoundMicro = 'Sound_Micro',
  SoundVibrator = 'Sound_Vibrator',
  SystemDeviceOff = 'System_DeviceOff',
  SystemErrors = 'System_Errors',
  SystemFreeze = 'System_Freeze',
  SystemLockedDevice = 'System_LockedDevice',
  SystemUpdate = 'System_Update'
}

export type WebsiteMetadata = {
  __typename?: 'WebsiteMetadata';
  isAcceptingNewOrders: Scalars['Boolean'];
  isBancontactEnabled: Scalars['Boolean'];
  stocks: Scalars['JSON'];
};

export type WebsiteSetting = {
  __typename?: 'WebsiteSetting';
  createdAt?: Maybe<Scalars['DateTime']>;
  heroPicture: UploadFileEntityResponse;
  heroPictureBlackFriday?: Maybe<UploadFileEntityResponse>;
  heroPictureBlackFridayMobile?: Maybe<UploadFileEntityResponse>;
  heroPictureMobile?: Maybe<UploadFileEntityResponse>;
  isBlackFriday: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WebsiteSettingEntity = {
  __typename?: 'WebsiteSettingEntity';
  attributes?: Maybe<WebsiteSetting>;
  id?: Maybe<Scalars['ID']>;
};

export type WebsiteSettingEntityResponse = {
  __typename?: 'WebsiteSettingEntityResponse';
  data?: Maybe<WebsiteSettingEntity>;
};

export type WebsiteSettingInput = {
  heroPicture?: Maybe<Scalars['ID']>;
  heroPictureBlackFriday?: Maybe<Scalars['ID']>;
  heroPictureBlackFridayMobile?: Maybe<Scalars['ID']>;
  heroPictureMobile?: Maybe<Scalars['ID']>;
  isBlackFriday?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


/** Boolean expression to compare columns of type "_jsonb". All fields are combined with logical 'AND'. */
export type _Jsonb_Comparison_Exp = {
  _eq?: Maybe<Scalars['_jsonb']>;
  _gt?: Maybe<Scalars['_jsonb']>;
  _gte?: Maybe<Scalars['_jsonb']>;
  _in?: Maybe<Array<Scalars['_jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_jsonb']>;
  _lte?: Maybe<Scalars['_jsonb']>;
  _neq?: Maybe<Scalars['_jsonb']>;
  _nin?: Maybe<Array<Scalars['_jsonb']>>;
};

/** columns and relationships of "b2b_accounts" */
export type B2b_Accounts = {
  __typename?: 'b2b_accounts';
  /** An array relationship */
  b2b_users: Array<B2b_Users>;
  /** An aggregate relationship */
  b2b_users_aggregate: B2b_Users_Aggregate;
  billing_address?: Maybe<Scalars['jsonb']>;
  cleaq_instance_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  shipping_address?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  signatories: Array<Signatory>;
  siret?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "b2b_accounts" */
export type B2b_AccountsB2b_UsersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Order_By>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


/** columns and relationships of "b2b_accounts" */
export type B2b_AccountsB2b_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Order_By>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


/** columns and relationships of "b2b_accounts" */
export type B2b_AccountsBilling_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "b2b_accounts" */
export type B2b_AccountsShipping_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "b2b_accounts" */
export type B2b_AccountsSignatoriesArgs = {
  distinct_on?: Maybe<Array<Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Signatory_Order_By>>;
  where?: Maybe<Signatory_Bool_Exp>;
};

/** order by aggregate values of table "b2b_accounts" */
export type B2b_Accounts_Aggregate_Order_By = {
  avg?: Maybe<B2b_Accounts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<B2b_Accounts_Max_Order_By>;
  min?: Maybe<B2b_Accounts_Min_Order_By>;
  stddev?: Maybe<B2b_Accounts_Stddev_Order_By>;
  stddev_pop?: Maybe<B2b_Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<B2b_Accounts_Stddev_Samp_Order_By>;
  sum?: Maybe<B2b_Accounts_Sum_Order_By>;
  var_pop?: Maybe<B2b_Accounts_Var_Pop_Order_By>;
  var_samp?: Maybe<B2b_Accounts_Var_Samp_Order_By>;
  variance?: Maybe<B2b_Accounts_Variance_Order_By>;
};

/** order by avg() on columns of table "b2b_accounts" */
export type B2b_Accounts_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "b2b_accounts". All fields are combined with a logical 'AND'. */
export type B2b_Accounts_Bool_Exp = {
  _and?: Maybe<Array<B2b_Accounts_Bool_Exp>>;
  _not?: Maybe<B2b_Accounts_Bool_Exp>;
  _or?: Maybe<Array<B2b_Accounts_Bool_Exp>>;
  b2b_users?: Maybe<B2b_Users_Bool_Exp>;
  b2b_users_aggregate?: Maybe<B2b_Users_Aggregate_Bool_Exp>;
  billing_address?: Maybe<Jsonb_Comparison_Exp>;
  cleaq_instance_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  shipping_address?: Maybe<Jsonb_Comparison_Exp>;
  signatories?: Maybe<Signatory_Bool_Exp>;
  siret?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "b2b_accounts" */
export type B2b_Accounts_Max_Order_By = {
  cleaq_instance_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  siret?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "b2b_accounts" */
export type B2b_Accounts_Min_Order_By = {
  cleaq_instance_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  siret?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "b2b_accounts". */
export type B2b_Accounts_Order_By = {
  b2b_users_aggregate?: Maybe<B2b_Users_Aggregate_Order_By>;
  billing_address?: Maybe<Order_By>;
  cleaq_instance_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  shipping_address?: Maybe<Order_By>;
  signatories_aggregate?: Maybe<Signatory_Aggregate_Order_By>;
  siret?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "b2b_accounts" */
export enum B2b_Accounts_Select_Column {
  /** column name */
  BillingAddress = 'billing_address',
  /** column name */
  CleaqInstanceId = 'cleaq_instance_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  Siret = 'siret',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "b2b_accounts" */
export type B2b_Accounts_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "b2b_accounts" */
export type B2b_Accounts_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "b2b_accounts" */
export type B2b_Accounts_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "b2b_accounts" */
export type B2b_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: B2b_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type B2b_Accounts_Stream_Cursor_Value_Input = {
  billing_address?: Maybe<Scalars['jsonb']>;
  cleaq_instance_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<Scalars['jsonb']>;
  siret?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "b2b_accounts" */
export type B2b_Accounts_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "b2b_accounts" */
export type B2b_Accounts_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "b2b_accounts" */
export type B2b_Accounts_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "b2b_accounts" */
export type B2b_Accounts_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "b2b_users" */
export type B2b_Users = {
  __typename?: 'b2b_users';
  /** An object relationship */
  b2b_account: B2b_Accounts;
  b2b_account_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  /** An array relationship */
  orders: Array<B2b_Users_Orders>;
  /** An aggregate relationship */
  orders_aggregate: B2b_Users_Orders_Aggregate;
  phone: Scalars['String'];
};


/** columns and relationships of "b2b_users" */
export type B2b_UsersOrdersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


/** columns and relationships of "b2b_users" */
export type B2b_UsersOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};

/** aggregated selection of "b2b_users" */
export type B2b_Users_Aggregate = {
  __typename?: 'b2b_users_aggregate';
  aggregate?: Maybe<B2b_Users_Aggregate_Fields>;
  nodes: Array<B2b_Users>;
};

export type B2b_Users_Aggregate_Bool_Exp = {
  count?: Maybe<B2b_Users_Aggregate_Bool_Exp_Count>;
};

export type B2b_Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<B2b_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<B2b_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "b2b_users" */
export type B2b_Users_Aggregate_Fields = {
  __typename?: 'b2b_users_aggregate_fields';
  avg?: Maybe<B2b_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<B2b_Users_Max_Fields>;
  min?: Maybe<B2b_Users_Min_Fields>;
  stddev?: Maybe<B2b_Users_Stddev_Fields>;
  stddev_pop?: Maybe<B2b_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<B2b_Users_Stddev_Samp_Fields>;
  sum?: Maybe<B2b_Users_Sum_Fields>;
  var_pop?: Maybe<B2b_Users_Var_Pop_Fields>;
  var_samp?: Maybe<B2b_Users_Var_Samp_Fields>;
  variance?: Maybe<B2b_Users_Variance_Fields>;
};


/** aggregate fields of "b2b_users" */
export type B2b_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<B2b_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "b2b_users" */
export type B2b_Users_Aggregate_Order_By = {
  avg?: Maybe<B2b_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<B2b_Users_Max_Order_By>;
  min?: Maybe<B2b_Users_Min_Order_By>;
  stddev?: Maybe<B2b_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<B2b_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<B2b_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<B2b_Users_Sum_Order_By>;
  var_pop?: Maybe<B2b_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<B2b_Users_Var_Samp_Order_By>;
  variance?: Maybe<B2b_Users_Variance_Order_By>;
};

/** aggregate avg on columns */
export type B2b_Users_Avg_Fields = {
  __typename?: 'b2b_users_avg_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "b2b_users" */
export type B2b_Users_Avg_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "b2b_users". All fields are combined with a logical 'AND'. */
export type B2b_Users_Bool_Exp = {
  _and?: Maybe<Array<B2b_Users_Bool_Exp>>;
  _not?: Maybe<B2b_Users_Bool_Exp>;
  _or?: Maybe<Array<B2b_Users_Bool_Exp>>;
  b2b_account?: Maybe<B2b_Accounts_Bool_Exp>;
  b2b_account_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<B2b_Users_Orders_Bool_Exp>;
  orders_aggregate?: Maybe<B2b_Users_Orders_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type B2b_Users_Max_Fields = {
  __typename?: 'b2b_users_max_fields';
  b2b_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "b2b_users" */
export type B2b_Users_Max_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type B2b_Users_Min_Fields = {
  __typename?: 'b2b_users_min_fields';
  b2b_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "b2b_users" */
export type B2b_Users_Min_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
};

/** response of any mutation on the table "b2b_users" */
export type B2b_Users_Mutation_Response = {
  __typename?: 'b2b_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<B2b_Users>;
};

/** Ordering options when selecting data from "b2b_users". */
export type B2b_Users_Order_By = {
  b2b_account?: Maybe<B2b_Accounts_Order_By>;
  b2b_account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<B2b_Users_Orders_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
};

/** columns and relationships of "b2b_users_orders" */
export type B2b_Users_Orders = {
  __typename?: 'b2b_users_orders';
  /** An object relationship */
  b2b_user: B2b_Users;
  b2b_user_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
};

/** aggregated selection of "b2b_users_orders" */
export type B2b_Users_Orders_Aggregate = {
  __typename?: 'b2b_users_orders_aggregate';
  aggregate?: Maybe<B2b_Users_Orders_Aggregate_Fields>;
  nodes: Array<B2b_Users_Orders>;
};

export type B2b_Users_Orders_Aggregate_Bool_Exp = {
  count?: Maybe<B2b_Users_Orders_Aggregate_Bool_Exp_Count>;
};

export type B2b_Users_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<B2b_Users_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "b2b_users_orders" */
export type B2b_Users_Orders_Aggregate_Fields = {
  __typename?: 'b2b_users_orders_aggregate_fields';
  avg?: Maybe<B2b_Users_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<B2b_Users_Orders_Max_Fields>;
  min?: Maybe<B2b_Users_Orders_Min_Fields>;
  stddev?: Maybe<B2b_Users_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<B2b_Users_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<B2b_Users_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<B2b_Users_Orders_Sum_Fields>;
  var_pop?: Maybe<B2b_Users_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<B2b_Users_Orders_Var_Samp_Fields>;
  variance?: Maybe<B2b_Users_Orders_Variance_Fields>;
};


/** aggregate fields of "b2b_users_orders" */
export type B2b_Users_Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "b2b_users_orders" */
export type B2b_Users_Orders_Aggregate_Order_By = {
  avg?: Maybe<B2b_Users_Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<B2b_Users_Orders_Max_Order_By>;
  min?: Maybe<B2b_Users_Orders_Min_Order_By>;
  stddev?: Maybe<B2b_Users_Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<B2b_Users_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<B2b_Users_Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<B2b_Users_Orders_Sum_Order_By>;
  var_pop?: Maybe<B2b_Users_Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<B2b_Users_Orders_Var_Samp_Order_By>;
  variance?: Maybe<B2b_Users_Orders_Variance_Order_By>;
};

/** aggregate avg on columns */
export type B2b_Users_Orders_Avg_Fields = {
  __typename?: 'b2b_users_orders_avg_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Avg_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "b2b_users_orders". All fields are combined with a logical 'AND'. */
export type B2b_Users_Orders_Bool_Exp = {
  _and?: Maybe<Array<B2b_Users_Orders_Bool_Exp>>;
  _not?: Maybe<B2b_Users_Orders_Bool_Exp>;
  _or?: Maybe<Array<B2b_Users_Orders_Bool_Exp>>;
  b2b_user?: Maybe<B2b_Users_Bool_Exp>;
  b2b_user_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "b2b_users_orders" */
export enum B2b_Users_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkE2164b8291d72989d3d5ebfac34 = 'PK_e2164b8291d72989d3d5ebfac34'
}

/** input type for incrementing numeric columns in table "b2b_users_orders" */
export type B2b_Users_Orders_Inc_Input = {
  b2b_user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "b2b_users_orders" */
export type B2b_Users_Orders_Insert_Input = {
  b2b_user_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type B2b_Users_Orders_Max_Fields = {
  __typename?: 'b2b_users_orders_max_fields';
  b2b_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Max_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type B2b_Users_Orders_Min_Fields = {
  __typename?: 'b2b_users_orders_min_fields';
  b2b_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Min_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "b2b_users_orders" */
export type B2b_Users_Orders_Mutation_Response = {
  __typename?: 'b2b_users_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<B2b_Users_Orders>;
};

/** on_conflict condition type for table "b2b_users_orders" */
export type B2b_Users_Orders_On_Conflict = {
  constraint: B2b_Users_Orders_Constraint;
  update_columns?: Array<B2b_Users_Orders_Update_Column>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "b2b_users_orders". */
export type B2b_Users_Orders_Order_By = {
  b2b_user?: Maybe<B2b_Users_Order_By>;
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: b2b_users_orders */
export type B2b_Users_Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "b2b_users_orders" */
export enum B2b_Users_Orders_Select_Column {
  /** column name */
  B2bUserId = 'b2b_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id'
}

/** input type for updating data in table "b2b_users_orders" */
export type B2b_Users_Orders_Set_Input = {
  b2b_user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type B2b_Users_Orders_Stddev_Fields = {
  __typename?: 'b2b_users_orders_stddev_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Stddev_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type B2b_Users_Orders_Stddev_Pop_Fields = {
  __typename?: 'b2b_users_orders_stddev_pop_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Stddev_Pop_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type B2b_Users_Orders_Stddev_Samp_Fields = {
  __typename?: 'b2b_users_orders_stddev_samp_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Stddev_Samp_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "b2b_users_orders" */
export type B2b_Users_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: B2b_Users_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type B2b_Users_Orders_Stream_Cursor_Value_Input = {
  b2b_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type B2b_Users_Orders_Sum_Fields = {
  __typename?: 'b2b_users_orders_sum_fields';
  b2b_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Sum_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** update columns of table "b2b_users_orders" */
export enum B2b_Users_Orders_Update_Column {
  /** column name */
  B2bUserId = 'b2b_user_id'
}

export type B2b_Users_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<B2b_Users_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<B2b_Users_Orders_Set_Input>;
  where: B2b_Users_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type B2b_Users_Orders_Var_Pop_Fields = {
  __typename?: 'b2b_users_orders_var_pop_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Var_Pop_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type B2b_Users_Orders_Var_Samp_Fields = {
  __typename?: 'b2b_users_orders_var_samp_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Var_Samp_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type B2b_Users_Orders_Variance_Fields = {
  __typename?: 'b2b_users_orders_variance_fields';
  b2b_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "b2b_users_orders" */
export type B2b_Users_Orders_Variance_Order_By = {
  b2b_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: b2b_users */
export type B2b_Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "b2b_users" */
export enum B2b_Users_Select_Column {
  /** column name */
  B2bAccountId = 'b2b_account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone'
}

/** input type for updating data in table "b2b_users" */
export type B2b_Users_Set_Input = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type B2b_Users_Stddev_Fields = {
  __typename?: 'b2b_users_stddev_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "b2b_users" */
export type B2b_Users_Stddev_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type B2b_Users_Stddev_Pop_Fields = {
  __typename?: 'b2b_users_stddev_pop_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "b2b_users" */
export type B2b_Users_Stddev_Pop_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type B2b_Users_Stddev_Samp_Fields = {
  __typename?: 'b2b_users_stddev_samp_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "b2b_users" */
export type B2b_Users_Stddev_Samp_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "b2b_users" */
export type B2b_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: B2b_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type B2b_Users_Stream_Cursor_Value_Input = {
  b2b_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type B2b_Users_Sum_Fields = {
  __typename?: 'b2b_users_sum_fields';
  b2b_account_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "b2b_users" */
export type B2b_Users_Sum_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type B2b_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<B2b_Users_Set_Input>;
  where: B2b_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type B2b_Users_Var_Pop_Fields = {
  __typename?: 'b2b_users_var_pop_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "b2b_users" */
export type B2b_Users_Var_Pop_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type B2b_Users_Var_Samp_Fields = {
  __typename?: 'b2b_users_var_samp_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "b2b_users" */
export type B2b_Users_Var_Samp_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type B2b_Users_Variance_Fields = {
  __typename?: 'b2b_users_variance_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "b2b_users" */
export type B2b_Users_Variance_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "bank_details" */
export type Bank_Details = {
  __typename?: 'bank_details';
  bank_name: Scalars['String'];
  bic: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  /** An array relationship */
  files: Array<Bank_Details_Files>;
  iban: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  open_banking_connection?: Maybe<Open_Banking_Connections>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "bank_details" */
export type Bank_DetailsFilesArgs = {
  distinct_on?: Maybe<Array<Bank_Details_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Details_Files_Order_By>>;
  where?: Maybe<Bank_Details_Files_Bool_Exp>;
};

/** order by aggregate values of table "bank_details" */
export type Bank_Details_Aggregate_Order_By = {
  avg?: Maybe<Bank_Details_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Bank_Details_Max_Order_By>;
  min?: Maybe<Bank_Details_Min_Order_By>;
  stddev?: Maybe<Bank_Details_Stddev_Order_By>;
  stddev_pop?: Maybe<Bank_Details_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Bank_Details_Stddev_Samp_Order_By>;
  sum?: Maybe<Bank_Details_Sum_Order_By>;
  var_pop?: Maybe<Bank_Details_Var_Pop_Order_By>;
  var_samp?: Maybe<Bank_Details_Var_Samp_Order_By>;
  variance?: Maybe<Bank_Details_Variance_Order_By>;
};

/** order by avg() on columns of table "bank_details" */
export type Bank_Details_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bank_details". All fields are combined with a logical 'AND'. */
export type Bank_Details_Bool_Exp = {
  _and?: Maybe<Array<Bank_Details_Bool_Exp>>;
  _not?: Maybe<Bank_Details_Bool_Exp>;
  _or?: Maybe<Array<Bank_Details_Bool_Exp>>;
  bank_name?: Maybe<String_Comparison_Exp>;
  bic?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  files?: Maybe<Bank_Details_Files_Bool_Exp>;
  iban?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  open_banking_connection?: Maybe<Open_Banking_Connections_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "bank_details_files" */
export type Bank_Details_Files = {
  __typename?: 'bank_details_files';
  /** An object relationship */
  bank_details?: Maybe<Bank_Details>;
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "bank_details_files" */
export type Bank_Details_Files_Aggregate_Order_By = {
  avg?: Maybe<Bank_Details_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Bank_Details_Files_Max_Order_By>;
  min?: Maybe<Bank_Details_Files_Min_Order_By>;
  stddev?: Maybe<Bank_Details_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Bank_Details_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Bank_Details_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Bank_Details_Files_Sum_Order_By>;
  var_pop?: Maybe<Bank_Details_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Bank_Details_Files_Var_Samp_Order_By>;
  variance?: Maybe<Bank_Details_Files_Variance_Order_By>;
};

/** order by avg() on columns of table "bank_details_files" */
export type Bank_Details_Files_Avg_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bank_details_files". All fields are combined with a logical 'AND'. */
export type Bank_Details_Files_Bool_Exp = {
  _and?: Maybe<Array<Bank_Details_Files_Bool_Exp>>;
  _not?: Maybe<Bank_Details_Files_Bool_Exp>;
  _or?: Maybe<Array<Bank_Details_Files_Bool_Exp>>;
  bank_details?: Maybe<Bank_Details_Bool_Exp>;
  content_length?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  resource_type?: Maybe<String_Comparison_Exp>;
  storage?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "bank_details_files" */
export type Bank_Details_Files_Max_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** order by min() on columns of table "bank_details_files" */
export type Bank_Details_Files_Min_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "bank_details_files". */
export type Bank_Details_Files_Order_By = {
  bank_details?: Maybe<Bank_Details_Order_By>;
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "bank_details_files" */
export enum Bank_Details_Files_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Storage = 'storage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** order by stddev() on columns of table "bank_details_files" */
export type Bank_Details_Files_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bank_details_files" */
export type Bank_Details_Files_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bank_details_files" */
export type Bank_Details_Files_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "bank_details_files" */
export type Bank_Details_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bank_Details_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bank_Details_Files_Stream_Cursor_Value_Input = {
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "bank_details_files" */
export type Bank_Details_Files_Sum_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "bank_details_files" */
export type Bank_Details_Files_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "bank_details_files" */
export type Bank_Details_Files_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "bank_details_files" */
export type Bank_Details_Files_Variance_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by max() on columns of table "bank_details" */
export type Bank_Details_Max_Order_By = {
  bank_name?: Maybe<Order_By>;
  bic?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "bank_details" */
export type Bank_Details_Min_Order_By = {
  bank_name?: Maybe<Order_By>;
  bic?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "bank_details". */
export type Bank_Details_Order_By = {
  bank_name?: Maybe<Order_By>;
  bic?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  files_aggregate?: Maybe<Bank_Details_Files_Aggregate_Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  open_banking_connection?: Maybe<Open_Banking_Connections_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "bank_details" */
export enum Bank_Details_Select_Column {
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Bic = 'bic',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "bank_details" */
export type Bank_Details_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bank_details" */
export type Bank_Details_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bank_details" */
export type Bank_Details_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "bank_details" */
export type Bank_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bank_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bank_Details_Stream_Cursor_Value_Input = {
  bank_name?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "bank_details" */
export type Bank_Details_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "bank_details" */
export type Bank_Details_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "bank_details" */
export type Bank_Details_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "bank_details" */
export type Bank_Details_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** An array relationship */
  taxes: Array<Taxes>;
};


/** columns and relationships of "countries" */
export type CountriesOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "countries" */
export type CountriesOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "countries" */
export type CountriesTaxesArgs = {
  distinct_on?: Maybe<Array<Taxes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxes_Order_By>>;
  where?: Maybe<Taxes_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: Maybe<Array<Countries_Bool_Exp>>;
  _not?: Maybe<Countries_Bool_Exp>;
  _or?: Maybe<Array<Countries_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  taxes?: Maybe<Taxes_Bool_Exp>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  taxes_aggregate?: Maybe<Taxes_Aggregate_Order_By>;
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "coupon_orders" */
export type Coupon_Orders = {
  __typename?: 'coupon_orders';
  /** An object relationship */
  coupon: Coupons;
  coupon_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "coupon_orders" */
export type Coupon_Orders_Aggregate_Order_By = {
  avg?: Maybe<Coupon_Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Coupon_Orders_Max_Order_By>;
  min?: Maybe<Coupon_Orders_Min_Order_By>;
  stddev?: Maybe<Coupon_Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Coupon_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Coupon_Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Coupon_Orders_Sum_Order_By>;
  var_pop?: Maybe<Coupon_Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Coupon_Orders_Var_Samp_Order_By>;
  variance?: Maybe<Coupon_Orders_Variance_Order_By>;
};

/** order by avg() on columns of table "coupon_orders" */
export type Coupon_Orders_Avg_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "coupon_orders". All fields are combined with a logical 'AND'. */
export type Coupon_Orders_Bool_Exp = {
  _and?: Maybe<Array<Coupon_Orders_Bool_Exp>>;
  _not?: Maybe<Coupon_Orders_Bool_Exp>;
  _or?: Maybe<Array<Coupon_Orders_Bool_Exp>>;
  coupon?: Maybe<Coupons_Bool_Exp>;
  coupon_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "coupon_orders" */
export type Coupon_Orders_Max_Order_By = {
  coupon_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "coupon_orders" */
export type Coupon_Orders_Min_Order_By = {
  coupon_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "coupon_orders". */
export type Coupon_Orders_Order_By = {
  coupon?: Maybe<Coupons_Order_By>;
  coupon_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "coupon_orders" */
export enum Coupon_Orders_Select_Column {
  /** column name */
  CouponId = 'coupon_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "coupon_orders" */
export type Coupon_Orders_Stddev_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "coupon_orders" */
export type Coupon_Orders_Stddev_Pop_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "coupon_orders" */
export type Coupon_Orders_Stddev_Samp_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "coupon_orders" */
export type Coupon_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupon_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupon_Orders_Stream_Cursor_Value_Input = {
  coupon_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "coupon_orders" */
export type Coupon_Orders_Sum_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "coupon_orders" */
export type Coupon_Orders_Var_Pop_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "coupon_orders" */
export type Coupon_Orders_Var_Samp_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "coupon_orders" */
export type Coupon_Orders_Variance_Order_By = {
  coupon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** columns and relationships of "coupons" */
export type Coupons = {
  __typename?: 'coupons';
  amount_in_cents: Scalars['Int'];
  code: Scalars['String'];
  /** An array relationship */
  coupon_orders: Array<Coupon_Orders>;
  id: Scalars['Int'];
};


/** columns and relationships of "coupons" */
export type CouponsCoupon_OrdersArgs = {
  distinct_on?: Maybe<Array<Coupon_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Orders_Order_By>>;
  where?: Maybe<Coupon_Orders_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "coupons". All fields are combined with a logical 'AND'. */
export type Coupons_Bool_Exp = {
  _and?: Maybe<Array<Coupons_Bool_Exp>>;
  _not?: Maybe<Coupons_Bool_Exp>;
  _or?: Maybe<Array<Coupons_Bool_Exp>>;
  amount_in_cents?: Maybe<Int_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  coupon_orders?: Maybe<Coupon_Orders_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "coupons". */
export type Coupons_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  coupon_orders_aggregate?: Maybe<Coupon_Orders_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "coupons" */
export enum Coupons_Select_Column {
  /** column name */
  AmountInCents = 'amount_in_cents',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "coupons" */
export type Coupons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupons_Stream_Cursor_Value_Input = {
  amount_in_cents?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer_contract_states" */
export type Customer_Contract_States = {
  __typename?: 'customer_contract_states';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer_contract: Customer_Contracts;
  /** An array relationship */
  customer_contract_states: Array<Customer_Contract_States>;
  /** An array relationship */
  customer_contracts: Array<Customer_Contracts>;
  from_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  from_state?: Maybe<Customer_Contract_States>;
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  resource_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "customer_contract_states" */
export type Customer_Contract_StatesCustomer_Contract_StatesArgs = {
  distinct_on?: Maybe<Array<Customer_Contract_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contract_States_Order_By>>;
  where?: Maybe<Customer_Contract_States_Bool_Exp>;
};


/** columns and relationships of "customer_contract_states" */
export type Customer_Contract_StatesCustomer_ContractsArgs = {
  distinct_on?: Maybe<Array<Customer_Contracts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contracts_Order_By>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};


/** columns and relationships of "customer_contract_states" */
export type Customer_Contract_StatesMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "customer_contract_states" */
export type Customer_Contract_States_Aggregate_Order_By = {
  avg?: Maybe<Customer_Contract_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Contract_States_Max_Order_By>;
  min?: Maybe<Customer_Contract_States_Min_Order_By>;
  stddev?: Maybe<Customer_Contract_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Contract_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Contract_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Contract_States_Sum_Order_By>;
  var_pop?: Maybe<Customer_Contract_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Contract_States_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Contract_States_Variance_Order_By>;
};

/** order by avg() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Avg_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_contract_states". All fields are combined with a logical 'AND'. */
export type Customer_Contract_States_Bool_Exp = {
  _and?: Maybe<Array<Customer_Contract_States_Bool_Exp>>;
  _not?: Maybe<Customer_Contract_States_Bool_Exp>;
  _or?: Maybe<Array<Customer_Contract_States_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_contract?: Maybe<Customer_Contracts_Bool_Exp>;
  customer_contract_states?: Maybe<Customer_Contract_States_Bool_Exp>;
  customer_contracts?: Maybe<Customer_Contracts_Bool_Exp>;
  from_id?: Maybe<Int_Comparison_Exp>;
  from_state?: Maybe<Customer_Contract_States_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "customer_contract_states". */
export type Customer_Contract_States_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_contract?: Maybe<Customer_Contracts_Order_By>;
  customer_contract_states_aggregate?: Maybe<Customer_Contract_States_Aggregate_Order_By>;
  customer_contracts_aggregate?: Maybe<Customer_Contracts_Aggregate_Order_By>;
  from_id?: Maybe<Order_By>;
  from_state?: Maybe<Customer_Contract_States_Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "customer_contract_states" */
export enum Customer_Contract_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromId = 'from_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Stddev_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Stddev_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Stddev_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_contract_states" */
export type Customer_Contract_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Contract_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Contract_States_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Sum_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Var_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Var_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "customer_contract_states" */
export type Customer_Contract_States_Variance_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "customer_contracts" */
export type Customer_Contracts = {
  __typename?: 'customer_contracts';
  contract_type: Scalars['String'];
  created_at: Scalars['timestamp'];
  /** An object relationship */
  current_state?: Maybe<Customer_Contract_States>;
  current_state_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  documents?: Maybe<Scalars['_jsonb']>;
  id: Scalars['Int'];
  order_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  signatory?: Maybe<Signatory>;
  signatory_id?: Maybe<Scalars['Int']>;
  signature_finished_at?: Maybe<Scalars['timestamptz']>;
  signature_link?: Maybe<Scalars['String']>;
  signature_uuid: Scalars['String'];
  updated_at: Scalars['timestamp'];
  yousign_api_version?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "customer_contracts" */
export type Customer_Contracts_Aggregate_Order_By = {
  avg?: Maybe<Customer_Contracts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Contracts_Max_Order_By>;
  min?: Maybe<Customer_Contracts_Min_Order_By>;
  stddev?: Maybe<Customer_Contracts_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Contracts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Contracts_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Contracts_Sum_Order_By>;
  var_pop?: Maybe<Customer_Contracts_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Contracts_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Contracts_Variance_Order_By>;
};

/** order by avg() on columns of table "customer_contracts" */
export type Customer_Contracts_Avg_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_contracts". All fields are combined with a logical 'AND'. */
export type Customer_Contracts_Bool_Exp = {
  _and?: Maybe<Array<Customer_Contracts_Bool_Exp>>;
  _not?: Maybe<Customer_Contracts_Bool_Exp>;
  _or?: Maybe<Array<Customer_Contracts_Bool_Exp>>;
  contract_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  current_state?: Maybe<Customer_Contract_States_Bool_Exp>;
  current_state_id?: Maybe<Int_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  documents?: Maybe<_Jsonb_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  signatory?: Maybe<Signatory_Bool_Exp>;
  signatory_id?: Maybe<Int_Comparison_Exp>;
  signature_finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  signature_link?: Maybe<String_Comparison_Exp>;
  signature_uuid?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  yousign_api_version?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "customer_contracts" */
export type Customer_Contracts_Max_Order_By = {
  contract_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  signature_finished_at?: Maybe<Order_By>;
  signature_link?: Maybe<Order_By>;
  signature_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** order by min() on columns of table "customer_contracts" */
export type Customer_Contracts_Min_Order_By = {
  contract_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  signature_finished_at?: Maybe<Order_By>;
  signature_link?: Maybe<Order_By>;
  signature_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "customer_contracts". */
export type Customer_Contracts_Order_By = {
  contract_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_state?: Maybe<Customer_Contract_States_Order_By>;
  current_state_id?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  documents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory?: Maybe<Signatory_Order_By>;
  signatory_id?: Maybe<Order_By>;
  signature_finished_at?: Maybe<Order_By>;
  signature_link?: Maybe<Order_By>;
  signature_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** select columns of table "customer_contracts" */
export enum Customer_Contracts_Select_Column {
  /** column name */
  ContractType = 'contract_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStateId = 'current_state_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Documents = 'documents',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  SignatoryId = 'signatory_id',
  /** column name */
  SignatureFinishedAt = 'signature_finished_at',
  /** column name */
  SignatureLink = 'signature_link',
  /** column name */
  SignatureUuid = 'signature_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YousignApiVersion = 'yousign_api_version'
}

/** order by stddev() on columns of table "customer_contracts" */
export type Customer_Contracts_Stddev_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "customer_contracts" */
export type Customer_Contracts_Stddev_Pop_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "customer_contracts" */
export type Customer_Contracts_Stddev_Samp_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_contracts" */
export type Customer_Contracts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Contracts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Contracts_Stream_Cursor_Value_Input = {
  contract_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_state_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  documents?: Maybe<Scalars['_jsonb']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  signatory_id?: Maybe<Scalars['Int']>;
  signature_finished_at?: Maybe<Scalars['timestamptz']>;
  signature_link?: Maybe<Scalars['String']>;
  signature_uuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  yousign_api_version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_contracts" */
export type Customer_Contracts_Sum_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "customer_contracts" */
export type Customer_Contracts_Var_Pop_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "customer_contracts" */
export type Customer_Contracts_Var_Samp_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** order by variance() on columns of table "customer_contracts" */
export type Customer_Contracts_Variance_Order_By = {
  current_state_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  signatory_id?: Maybe<Order_By>;
  yousign_api_version?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_reminders". All fields are combined with a logical 'AND'. */
export type Customer_Reminders_Bool_Exp = {
  _and?: Maybe<Array<Customer_Reminders_Bool_Exp>>;
  _not?: Maybe<Customer_Reminders_Bool_Exp>;
  _or?: Maybe<Array<Customer_Reminders_Bool_Exp>>;
};

/** unique or primary key constraints on table "customer_reminders" */
export enum Customer_Reminders_Constraint {
  /** unique or primary key constraint on columns "subscription_id", "email" */
  CustomerRemindersEmailSubscriptionIdKey = 'customer_reminders_email_subscription_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerRemindersPkey = 'customer_reminders_pkey'
}

/** input type for incrementing numeric columns in table "customer_reminders" */
export type Customer_Reminders_Inc_Input = {
  subscription_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer_reminders" */
export type Customer_Reminders_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "customer_reminders" */
export type Customer_Reminders_Mutation_Response = {
  __typename?: 'customer_reminders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
};

/** on_conflict condition type for table "customer_reminders" */
export type Customer_Reminders_On_Conflict = {
  constraint: Customer_Reminders_Constraint;
  update_columns?: Array<Customer_Reminders_Update_Column>;
  where?: Maybe<Customer_Reminders_Bool_Exp>;
};

/** input type for updating data in table "customer_reminders" */
export type Customer_Reminders_Set_Input = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "customer_reminders" */
export enum Customer_Reminders_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  SubscriptionId = 'subscription_id'
}

export type Customer_Reminders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Customer_Reminders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Reminders_Set_Input>;
  where: Customer_Reminders_Bool_Exp;
};

/** columns and relationships of "customer_verification_files" */
export type Customer_Verification_Files = {
  __typename?: 'customer_verification_files';
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer_verification?: Maybe<Customer_Verifications>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "customer_verification_files" */
export type Customer_Verification_Files_Aggregate_Order_By = {
  avg?: Maybe<Customer_Verification_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Verification_Files_Max_Order_By>;
  min?: Maybe<Customer_Verification_Files_Min_Order_By>;
  stddev?: Maybe<Customer_Verification_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Verification_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Verification_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Verification_Files_Sum_Order_By>;
  var_pop?: Maybe<Customer_Verification_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Verification_Files_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Verification_Files_Variance_Order_By>;
};

/** order by avg() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Avg_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_verification_files". All fields are combined with a logical 'AND'. */
export type Customer_Verification_Files_Bool_Exp = {
  _and?: Maybe<Array<Customer_Verification_Files_Bool_Exp>>;
  _not?: Maybe<Customer_Verification_Files_Bool_Exp>;
  _or?: Maybe<Array<Customer_Verification_Files_Bool_Exp>>;
  content_length?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_verification?: Maybe<Customer_Verifications_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  resource_type?: Maybe<String_Comparison_Exp>;
  storage?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Max_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** order by min() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Min_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "customer_verification_files". */
export type Customer_Verification_Files_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_verification?: Maybe<Customer_Verifications_Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "customer_verification_files" */
export enum Customer_Verification_Files_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Storage = 'storage',
  /** column name */
  Uuid = 'uuid'
}

/** order by stddev() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_verification_files" */
export type Customer_Verification_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Verification_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Verification_Files_Stream_Cursor_Value_Input = {
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Sum_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "customer_verification_files" */
export type Customer_Verification_Files_Variance_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** columns and relationships of "customer_verification_states" */
export type Customer_Verification_States = {
  __typename?: 'customer_verification_states';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer_verification: Customer_Verifications;
  from_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  from_state?: Maybe<Customer_Verification_States>;
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  resource_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "customer_verification_states" */
export type Customer_Verification_StatesMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "customer_verification_states" */
export type Customer_Verification_States_Aggregate_Order_By = {
  avg?: Maybe<Customer_Verification_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Verification_States_Max_Order_By>;
  min?: Maybe<Customer_Verification_States_Min_Order_By>;
  stddev?: Maybe<Customer_Verification_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Verification_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Verification_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Verification_States_Sum_Order_By>;
  var_pop?: Maybe<Customer_Verification_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Verification_States_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Verification_States_Variance_Order_By>;
};

/** order by avg() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Avg_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_verification_states". All fields are combined with a logical 'AND'. */
export type Customer_Verification_States_Bool_Exp = {
  _and?: Maybe<Array<Customer_Verification_States_Bool_Exp>>;
  _not?: Maybe<Customer_Verification_States_Bool_Exp>;
  _or?: Maybe<Array<Customer_Verification_States_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_verification?: Maybe<Customer_Verifications_Bool_Exp>;
  from_id?: Maybe<Int_Comparison_Exp>;
  from_state?: Maybe<Customer_Verification_States_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "customer_verification_states". */
export type Customer_Verification_States_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_verification?: Maybe<Customer_Verifications_Order_By>;
  from_id?: Maybe<Order_By>;
  from_state?: Maybe<Customer_Verification_States_Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "customer_verification_states" */
export enum Customer_Verification_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromId = 'from_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Stddev_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Stddev_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Stddev_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_verification_states" */
export type Customer_Verification_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Verification_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Verification_States_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Sum_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Var_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Var_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "customer_verification_states" */
export type Customer_Verification_States_Variance_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "customer_verifications" */
export type Customer_Verifications = {
  __typename?: 'customer_verifications';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_identification_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  current_state?: Maybe<Customer_Verification_States>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  /** An array relationship */
  files: Array<Customer_Verification_Files>;
  id: Scalars['Int'];
  is_phone_validated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  states: Array<Customer_Verification_States>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "customer_verifications" */
export type Customer_VerificationsFilesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_Files_Order_By>>;
  where?: Maybe<Customer_Verification_Files_Bool_Exp>;
};


/** columns and relationships of "customer_verifications" */
export type Customer_VerificationsStatesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_States_Order_By>>;
  where?: Maybe<Customer_Verification_States_Bool_Exp>;
};

/** order by aggregate values of table "customer_verifications" */
export type Customer_Verifications_Aggregate_Order_By = {
  avg?: Maybe<Customer_Verifications_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Verifications_Max_Order_By>;
  min?: Maybe<Customer_Verifications_Min_Order_By>;
  stddev?: Maybe<Customer_Verifications_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Verifications_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Verifications_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Verifications_Sum_Order_By>;
  var_pop?: Maybe<Customer_Verifications_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Verifications_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Verifications_Variance_Order_By>;
};

/** order by avg() on columns of table "customer_verifications" */
export type Customer_Verifications_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_verifications". All fields are combined with a logical 'AND'. */
export type Customer_Verifications_Bool_Exp = {
  _and?: Maybe<Array<Customer_Verifications_Bool_Exp>>;
  _not?: Maybe<Customer_Verifications_Bool_Exp>;
  _or?: Maybe<Array<Customer_Verifications_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_identification_id?: Maybe<String_Comparison_Exp>;
  current_state?: Maybe<Customer_Verification_States_Bool_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  files?: Maybe<Customer_Verification_Files_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_phone_validated?: Maybe<Boolean_Comparison_Exp>;
  states?: Maybe<Customer_Verification_States_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "customer_verifications" */
export type Customer_Verifications_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  current_identification_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "customer_verifications" */
export type Customer_Verifications_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  current_identification_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "customer_verifications". */
export type Customer_Verifications_Order_By = {
  created_at?: Maybe<Order_By>;
  current_identification_id?: Maybe<Order_By>;
  current_state?: Maybe<Customer_Verification_States_Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  files_aggregate?: Maybe<Customer_Verification_Files_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  is_phone_validated?: Maybe<Order_By>;
  states_aggregate?: Maybe<Customer_Verification_States_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "customer_verifications_scoring_model_view" */
export type Customer_Verifications_Scoring_Model_View = {
  __typename?: 'customer_verifications_scoring_model_view';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  current_state?: Maybe<Customer_Verification_States>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  files: Array<Customer_Verification_Files>;
  id?: Maybe<Scalars['Int']>;
  is_phone_validated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  states: Array<Customer_Verification_States>;
  ubble_identification_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "customer_verifications_scoring_model_view" */
export type Customer_Verifications_Scoring_Model_ViewFilesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_Files_Order_By>>;
  where?: Maybe<Customer_Verification_Files_Bool_Exp>;
};


/** columns and relationships of "customer_verifications_scoring_model_view" */
export type Customer_Verifications_Scoring_Model_ViewStatesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_States_Order_By>>;
  where?: Maybe<Customer_Verification_States_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "customer_verifications_scoring_model_view". All fields are combined with a logical 'AND'. */
export type Customer_Verifications_Scoring_Model_View_Bool_Exp = {
  _and?: Maybe<Array<Customer_Verifications_Scoring_Model_View_Bool_Exp>>;
  _not?: Maybe<Customer_Verifications_Scoring_Model_View_Bool_Exp>;
  _or?: Maybe<Array<Customer_Verifications_Scoring_Model_View_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_state?: Maybe<Customer_Verification_States_Bool_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  files?: Maybe<Customer_Verification_Files_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_phone_validated?: Maybe<Boolean_Comparison_Exp>;
  states?: Maybe<Customer_Verification_States_Bool_Exp>;
  ubble_identification_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "customer_verifications_scoring_model_view". */
export type Customer_Verifications_Scoring_Model_View_Order_By = {
  created_at?: Maybe<Order_By>;
  current_state?: Maybe<Customer_Verification_States_Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  files_aggregate?: Maybe<Customer_Verification_Files_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  is_phone_validated?: Maybe<Order_By>;
  states_aggregate?: Maybe<Customer_Verification_States_Aggregate_Order_By>;
  ubble_identification_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "customer_verifications_scoring_model_view" */
export enum Customer_Verifications_Scoring_Model_View_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPhoneValidated = 'is_phone_validated',
  /** column name */
  UbbleIdentificationId = 'ubble_identification_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "customer_verifications_scoring_model_view" */
export type Customer_Verifications_Scoring_Model_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Verifications_Scoring_Model_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Verifications_Scoring_Model_View_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_phone_validated?: Maybe<Scalars['Boolean']>;
  ubble_identification_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** select columns of table "customer_verifications" */
export enum Customer_Verifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentIdentificationId = 'current_identification_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPhoneValidated = 'is_phone_validated',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "customer_verifications" */
export type Customer_Verifications_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "customer_verifications" */
export type Customer_Verifications_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "customer_verifications" */
export type Customer_Verifications_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_verifications" */
export type Customer_Verifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Verifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Verifications_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  current_identification_id?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_phone_validated?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "customer_verifications" */
export type Customer_Verifications_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "customer_verifications" */
export type Customer_Verifications_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "customer_verifications" */
export type Customer_Verifications_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "customer_verifications" */
export type Customer_Verifications_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "customers" */
export type Customers = {
  __typename?: 'customers';
  accepted_cgu_at?: Maybe<Scalars['timestamptz']>;
  accepted_marketing_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  b2b_accounts: Array<B2b_Accounts>;
  /** An array relationship */
  bank_details: Array<Bank_Details>;
  birthdate?: Maybe<Scalars['date']>;
  /** An array relationship */
  customer_contracts: Array<Customer_Contracts>;
  /** An array relationship */
  customer_verifications: Array<Customer_Verifications>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  experiments_allocations: Array<Experiments_Allocations>;
  /** An aggregate relationship */
  experiments_allocations_aggregate: Experiments_Allocations_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  /** An object relationship */
  godfather?: Maybe<Customers>;
  godfather_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  godsons: Array<Customers>;
  id: Scalars['Int'];
  is_deliverable_address: Scalars['Boolean'];
  is_password_set?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  phone?: Maybe<Scalars['String']>;
  provenance?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  unsanitized_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "customers" */
export type CustomersB2b_AccountsArgs = {
  distinct_on?: Maybe<Array<B2b_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Accounts_Order_By>>;
  where?: Maybe<B2b_Accounts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersBank_DetailsArgs = {
  distinct_on?: Maybe<Array<Bank_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Details_Order_By>>;
  where?: Maybe<Bank_Details_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomer_ContractsArgs = {
  distinct_on?: Maybe<Array<Customer_Contracts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contracts_Order_By>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomer_VerificationsArgs = {
  distinct_on?: Maybe<Array<Customer_Verifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verifications_Order_By>>;
  where?: Maybe<Customer_Verifications_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersExperiments_AllocationsArgs = {
  distinct_on?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Experiments_Allocations_Order_By>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersExperiments_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Experiments_Allocations_Order_By>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersGodsonsArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** order by aggregate values of table "customers" */
export type Customers_Aggregate_Order_By = {
  avg?: Maybe<Customers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customers_Max_Order_By>;
  min?: Maybe<Customers_Min_Order_By>;
  stddev?: Maybe<Customers_Stddev_Order_By>;
  stddev_pop?: Maybe<Customers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customers_Stddev_Samp_Order_By>;
  sum?: Maybe<Customers_Sum_Order_By>;
  var_pop?: Maybe<Customers_Var_Pop_Order_By>;
  var_samp?: Maybe<Customers_Var_Samp_Order_By>;
  variance?: Maybe<Customers_Variance_Order_By>;
};

/** order by avg() on columns of table "customers" */
export type Customers_Avg_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
  _and?: Maybe<Array<Customers_Bool_Exp>>;
  _not?: Maybe<Customers_Bool_Exp>;
  _or?: Maybe<Array<Customers_Bool_Exp>>;
  accepted_cgu_at?: Maybe<Timestamptz_Comparison_Exp>;
  accepted_marketing_at?: Maybe<Timestamptz_Comparison_Exp>;
  b2b_accounts?: Maybe<B2b_Accounts_Bool_Exp>;
  bank_details?: Maybe<Bank_Details_Bool_Exp>;
  birthdate?: Maybe<Date_Comparison_Exp>;
  customer_contracts?: Maybe<Customer_Contracts_Bool_Exp>;
  customer_verifications?: Maybe<Customer_Verifications_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  experiments_allocations?: Maybe<Experiments_Allocations_Bool_Exp>;
  experiments_allocations_aggregate?: Maybe<Experiments_Allocations_Aggregate_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  godfather?: Maybe<Customers_Bool_Exp>;
  godfather_id?: Maybe<Int_Comparison_Exp>;
  godsons?: Maybe<Customers_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_deliverable_address?: Maybe<Boolean_Comparison_Exp>;
  is_password_set?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  provenance?: Maybe<String_Comparison_Exp>;
  referral_code?: Maybe<String_Comparison_Exp>;
  unsanitized_email?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "customers" */
export type Customers_Max_Order_By = {
  accepted_cgu_at?: Maybe<Order_By>;
  accepted_marketing_at?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  provenance?: Maybe<Order_By>;
  referral_code?: Maybe<Order_By>;
  unsanitized_email?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "customers" */
export type Customers_Min_Order_By = {
  accepted_cgu_at?: Maybe<Order_By>;
  accepted_marketing_at?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  provenance?: Maybe<Order_By>;
  referral_code?: Maybe<Order_By>;
  unsanitized_email?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
  accepted_cgu_at?: Maybe<Order_By>;
  accepted_marketing_at?: Maybe<Order_By>;
  b2b_accounts_aggregate?: Maybe<B2b_Accounts_Aggregate_Order_By>;
  bank_details_aggregate?: Maybe<Bank_Details_Aggregate_Order_By>;
  birthdate?: Maybe<Order_By>;
  customer_contracts_aggregate?: Maybe<Customer_Contracts_Aggregate_Order_By>;
  customer_verifications_aggregate?: Maybe<Customer_Verifications_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  experiments_allocations_aggregate?: Maybe<Experiments_Allocations_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  godfather?: Maybe<Customers_Order_By>;
  godfather_id?: Maybe<Order_By>;
  godsons_aggregate?: Maybe<Customers_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  is_deliverable_address?: Maybe<Order_By>;
  is_password_set?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  provenance?: Maybe<Order_By>;
  referral_code?: Maybe<Order_By>;
  unsanitized_email?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
  /** column name */
  AcceptedCguAt = 'accepted_cgu_at',
  /** column name */
  AcceptedMarketingAt = 'accepted_marketing_at',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  GodfatherId = 'godfather_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeliverableAddress = 'is_deliverable_address',
  /** column name */
  IsPasswordSet = 'is_password_set',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Provenance = 'provenance',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  UnsanitizedEmail = 'unsanitized_email',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "customers" */
export type Customers_Stddev_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "customers" */
export type Customers_Stddev_Pop_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "customers" */
export type Customers_Stddev_Samp_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
  accepted_cgu_at?: Maybe<Scalars['timestamptz']>;
  accepted_marketing_at?: Maybe<Scalars['timestamptz']>;
  birthdate?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  godfather_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_deliverable_address?: Maybe<Scalars['Boolean']>;
  is_password_set?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provenance?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  unsanitized_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "customers" */
export type Customers_Sum_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "customers" */
export type Customers_Var_Pop_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "customers" */
export type Customers_Var_Samp_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "customers" */
export type Customers_Variance_Order_By = {
  godfather_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "devices_stock_view_cache" */
export type Devices_Stock_View_Cache = {
  __typename?: 'devices_stock_view_cache';
  id?: Maybe<Scalars['Int']>;
  is_online?: Maybe<Scalars['Boolean']>;
  subscription_id?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "devices_stock_view_cache". All fields are combined with a logical 'AND'. */
export type Devices_Stock_View_Cache_Bool_Exp = {
  _and?: Maybe<Array<Devices_Stock_View_Cache_Bool_Exp>>;
  _not?: Maybe<Devices_Stock_View_Cache_Bool_Exp>;
  _or?: Maybe<Array<Devices_Stock_View_Cache_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  is_online?: Maybe<Boolean_Comparison_Exp>;
  subscription_id?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "devices_stock_view_cache". */
export type Devices_Stock_View_Cache_Order_By = {
  id?: Maybe<Order_By>;
  is_online?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
};

/** select columns of table "devices_stock_view_cache" */
export enum Devices_Stock_View_Cache_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsOnline = 'is_online',
  /** column name */
  SubscriptionId = 'subscription_id'
}

/** Streaming cursor of the table "devices_stock_view_cache" */
export type Devices_Stock_View_Cache_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stock_View_Cache_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Devices_Stock_View_Cache_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  is_online?: Maybe<Scalars['Boolean']>;
  subscription_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "experiments_allocations" */
export type Experiments_Allocations = {
  __typename?: 'experiments_allocations';
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['Int'];
  experiment_id: Scalars['String'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  variant_id: Scalars['String'];
};

/** aggregated selection of "experiments_allocations" */
export type Experiments_Allocations_Aggregate = {
  __typename?: 'experiments_allocations_aggregate';
  aggregate?: Maybe<Experiments_Allocations_Aggregate_Fields>;
  nodes: Array<Experiments_Allocations>;
};

export type Experiments_Allocations_Aggregate_Bool_Exp = {
  count?: Maybe<Experiments_Allocations_Aggregate_Bool_Exp_Count>;
};

export type Experiments_Allocations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Experiments_Allocations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "experiments_allocations" */
export type Experiments_Allocations_Aggregate_Fields = {
  __typename?: 'experiments_allocations_aggregate_fields';
  avg?: Maybe<Experiments_Allocations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Experiments_Allocations_Max_Fields>;
  min?: Maybe<Experiments_Allocations_Min_Fields>;
  stddev?: Maybe<Experiments_Allocations_Stddev_Fields>;
  stddev_pop?: Maybe<Experiments_Allocations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Experiments_Allocations_Stddev_Samp_Fields>;
  sum?: Maybe<Experiments_Allocations_Sum_Fields>;
  var_pop?: Maybe<Experiments_Allocations_Var_Pop_Fields>;
  var_samp?: Maybe<Experiments_Allocations_Var_Samp_Fields>;
  variance?: Maybe<Experiments_Allocations_Variance_Fields>;
};


/** aggregate fields of "experiments_allocations" */
export type Experiments_Allocations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "experiments_allocations" */
export type Experiments_Allocations_Aggregate_Order_By = {
  avg?: Maybe<Experiments_Allocations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Experiments_Allocations_Max_Order_By>;
  min?: Maybe<Experiments_Allocations_Min_Order_By>;
  stddev?: Maybe<Experiments_Allocations_Stddev_Order_By>;
  stddev_pop?: Maybe<Experiments_Allocations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Experiments_Allocations_Stddev_Samp_Order_By>;
  sum?: Maybe<Experiments_Allocations_Sum_Order_By>;
  var_pop?: Maybe<Experiments_Allocations_Var_Pop_Order_By>;
  var_samp?: Maybe<Experiments_Allocations_Var_Samp_Order_By>;
  variance?: Maybe<Experiments_Allocations_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Experiments_Allocations_Avg_Fields = {
  __typename?: 'experiments_allocations_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "experiments_allocations". All fields are combined with a logical 'AND'. */
export type Experiments_Allocations_Bool_Exp = {
  _and?: Maybe<Array<Experiments_Allocations_Bool_Exp>>;
  _not?: Maybe<Experiments_Allocations_Bool_Exp>;
  _or?: Maybe<Array<Experiments_Allocations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  experiment_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  variant_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "experiments_allocations" */
export enum Experiments_Allocations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExperimentsAllocationsPkey = 'experiments_allocations_pkey'
}

/** input type for inserting data into table "experiments_allocations" */
export type Experiments_Allocations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  experiment_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Experiments_Allocations_Max_Fields = {
  __typename?: 'experiments_allocations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  experiment_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  experiment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Experiments_Allocations_Min_Fields = {
  __typename?: 'experiments_allocations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  experiment_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  experiment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "experiments_allocations" */
export type Experiments_Allocations_Mutation_Response = {
  __typename?: 'experiments_allocations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Experiments_Allocations>;
};

/** on_conflict condition type for table "experiments_allocations" */
export type Experiments_Allocations_On_Conflict = {
  constraint: Experiments_Allocations_Constraint;
  update_columns?: Array<Experiments_Allocations_Update_Column>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};

/** Ordering options when selecting data from "experiments_allocations". */
export type Experiments_Allocations_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  experiment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant_id?: Maybe<Order_By>;
};

/** select columns of table "experiments_allocations" */
export enum Experiments_Allocations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExperimentId = 'experiment_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VariantId = 'variant_id'
}

/** aggregate stddev on columns */
export type Experiments_Allocations_Stddev_Fields = {
  __typename?: 'experiments_allocations_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Experiments_Allocations_Stddev_Pop_Fields = {
  __typename?: 'experiments_allocations_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Experiments_Allocations_Stddev_Samp_Fields = {
  __typename?: 'experiments_allocations_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "experiments_allocations" */
export type Experiments_Allocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Experiments_Allocations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiments_Allocations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  experiment_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_id?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Experiments_Allocations_Sum_Fields = {
  __typename?: 'experiments_allocations_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** placeholder for update columns of table "experiments_allocations" (current role has no relevant permissions) */
export enum Experiments_Allocations_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type Experiments_Allocations_Var_Pop_Fields = {
  __typename?: 'experiments_allocations_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Experiments_Allocations_Var_Samp_Fields = {
  __typename?: 'experiments_allocations_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Experiments_Allocations_Variance_Fields = {
  __typename?: 'experiments_allocations_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "experiments_allocations" */
export type Experiments_Allocations_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "financing_contract" */
export type Financing_Contract = {
  __typename?: 'financing_contract';
  created_at?: Maybe<Scalars['timestamptz']>;
  financier_accepted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  financing_contract_application: Financing_Contract_Application;
  financing_contract_application_id: Scalars['Int'];
  financing_provider: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  product_residual_value_grid?: Maybe<Scalars['jsonb']>;
  provider_order_id: Scalars['String'];
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "financing_contract" */
export type Financing_ContractProduct_Residual_Value_GridArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "financing_contract" */
export type Financing_Contract_Aggregate = {
  __typename?: 'financing_contract_aggregate';
  aggregate?: Maybe<Financing_Contract_Aggregate_Fields>;
  nodes: Array<Financing_Contract>;
};

export type Financing_Contract_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Financing_Contract_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Financing_Contract_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Financing_Contract_Aggregate_Bool_Exp_Count>;
};

export type Financing_Contract_Aggregate_Bool_Exp_Bool_And = {
  arguments: Financing_Contract_Select_Column_Financing_Contract_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Financing_Contract_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Financing_Contract_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Financing_Contract_Select_Column_Financing_Contract_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Financing_Contract_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Financing_Contract_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Financing_Contract_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Financing_Contract_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "financing_contract" */
export type Financing_Contract_Aggregate_Fields = {
  __typename?: 'financing_contract_aggregate_fields';
  avg?: Maybe<Financing_Contract_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Financing_Contract_Max_Fields>;
  min?: Maybe<Financing_Contract_Min_Fields>;
  stddev?: Maybe<Financing_Contract_Stddev_Fields>;
  stddev_pop?: Maybe<Financing_Contract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Financing_Contract_Stddev_Samp_Fields>;
  sum?: Maybe<Financing_Contract_Sum_Fields>;
  var_pop?: Maybe<Financing_Contract_Var_Pop_Fields>;
  var_samp?: Maybe<Financing_Contract_Var_Samp_Fields>;
  variance?: Maybe<Financing_Contract_Variance_Fields>;
};


/** aggregate fields of "financing_contract" */
export type Financing_Contract_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Financing_Contract_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "financing_contract" */
export type Financing_Contract_Aggregate_Order_By = {
  avg?: Maybe<Financing_Contract_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Financing_Contract_Max_Order_By>;
  min?: Maybe<Financing_Contract_Min_Order_By>;
  stddev?: Maybe<Financing_Contract_Stddev_Order_By>;
  stddev_pop?: Maybe<Financing_Contract_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Financing_Contract_Stddev_Samp_Order_By>;
  sum?: Maybe<Financing_Contract_Sum_Order_By>;
  var_pop?: Maybe<Financing_Contract_Var_Pop_Order_By>;
  var_samp?: Maybe<Financing_Contract_Var_Samp_Order_By>;
  variance?: Maybe<Financing_Contract_Variance_Order_By>;
};

/** columns and relationships of "financing_contract_application" */
export type Financing_Contract_Application = {
  __typename?: 'financing_contract_application';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  financing_contracts: Array<Financing_Contract>;
  /** An aggregate relationship */
  financing_contracts_aggregate: Financing_Contract_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  score_result?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "financing_contract_application" */
export type Financing_Contract_ApplicationFinancing_ContractsArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


/** columns and relationships of "financing_contract_application" */
export type Financing_Contract_ApplicationFinancing_Contracts_AggregateArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};

/** aggregated selection of "financing_contract_application" */
export type Financing_Contract_Application_Aggregate = {
  __typename?: 'financing_contract_application_aggregate';
  aggregate?: Maybe<Financing_Contract_Application_Aggregate_Fields>;
  nodes: Array<Financing_Contract_Application>;
};

/** aggregate fields of "financing_contract_application" */
export type Financing_Contract_Application_Aggregate_Fields = {
  __typename?: 'financing_contract_application_aggregate_fields';
  avg?: Maybe<Financing_Contract_Application_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Financing_Contract_Application_Max_Fields>;
  min?: Maybe<Financing_Contract_Application_Min_Fields>;
  stddev?: Maybe<Financing_Contract_Application_Stddev_Fields>;
  stddev_pop?: Maybe<Financing_Contract_Application_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Financing_Contract_Application_Stddev_Samp_Fields>;
  sum?: Maybe<Financing_Contract_Application_Sum_Fields>;
  var_pop?: Maybe<Financing_Contract_Application_Var_Pop_Fields>;
  var_samp?: Maybe<Financing_Contract_Application_Var_Samp_Fields>;
  variance?: Maybe<Financing_Contract_Application_Variance_Fields>;
};


/** aggregate fields of "financing_contract_application" */
export type Financing_Contract_Application_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Financing_Contract_Application_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Financing_Contract_Application_Avg_Fields = {
  __typename?: 'financing_contract_application_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "financing_contract_application". All fields are combined with a logical 'AND'. */
export type Financing_Contract_Application_Bool_Exp = {
  _and?: Maybe<Array<Financing_Contract_Application_Bool_Exp>>;
  _not?: Maybe<Financing_Contract_Application_Bool_Exp>;
  _or?: Maybe<Array<Financing_Contract_Application_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  financing_contracts?: Maybe<Financing_Contract_Bool_Exp>;
  financing_contracts_aggregate?: Maybe<Financing_Contract_Aggregate_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  score_result?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Financing_Contract_Application_Max_Fields = {
  __typename?: 'financing_contract_application_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Financing_Contract_Application_Min_Fields = {
  __typename?: 'financing_contract_application_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "financing_contract_application". */
export type Financing_Contract_Application_Order_By = {
  created_at?: Maybe<Order_By>;
  financing_contracts_aggregate?: Maybe<Financing_Contract_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  score_result?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "financing_contract_application" */
export enum Financing_Contract_Application_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ScoreResult = 'score_result',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Financing_Contract_Application_Stddev_Fields = {
  __typename?: 'financing_contract_application_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Financing_Contract_Application_Stddev_Pop_Fields = {
  __typename?: 'financing_contract_application_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Financing_Contract_Application_Stddev_Samp_Fields = {
  __typename?: 'financing_contract_application_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "financing_contract_application" */
export type Financing_Contract_Application_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Financing_Contract_Application_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Financing_Contract_Application_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  score_result?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Financing_Contract_Application_Sum_Fields = {
  __typename?: 'financing_contract_application_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Financing_Contract_Application_Var_Pop_Fields = {
  __typename?: 'financing_contract_application_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Financing_Contract_Application_Var_Samp_Fields = {
  __typename?: 'financing_contract_application_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Financing_Contract_Application_Variance_Fields = {
  __typename?: 'financing_contract_application_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Financing_Contract_Avg_Fields = {
  __typename?: 'financing_contract_avg_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "financing_contract" */
export type Financing_Contract_Avg_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "financing_contract". All fields are combined with a logical 'AND'. */
export type Financing_Contract_Bool_Exp = {
  _and?: Maybe<Array<Financing_Contract_Bool_Exp>>;
  _not?: Maybe<Financing_Contract_Bool_Exp>;
  _or?: Maybe<Array<Financing_Contract_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  financier_accepted?: Maybe<Boolean_Comparison_Exp>;
  financing_contract_application?: Maybe<Financing_Contract_Application_Bool_Exp>;
  financing_contract_application_id?: Maybe<Int_Comparison_Exp>;
  financing_provider?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  product_residual_value_grid?: Maybe<Jsonb_Comparison_Exp>;
  provider_order_id?: Maybe<String_Comparison_Exp>;
  service_pack_selling_price_excl_tax?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Financing_Contract_Max_Fields = {
  __typename?: 'financing_contract_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  financing_contract_application_id?: Maybe<Scalars['Int']>;
  financing_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  provider_order_id?: Maybe<Scalars['String']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "financing_contract" */
export type Financing_Contract_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  financing_contract_application_id?: Maybe<Order_By>;
  financing_provider?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  provider_order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Financing_Contract_Min_Fields = {
  __typename?: 'financing_contract_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  financing_contract_application_id?: Maybe<Scalars['Int']>;
  financing_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  provider_order_id?: Maybe<Scalars['String']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "financing_contract" */
export type Financing_Contract_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  financing_contract_application_id?: Maybe<Order_By>;
  financing_provider?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  provider_order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "financing_contract". */
export type Financing_Contract_Order_By = {
  created_at?: Maybe<Order_By>;
  financier_accepted?: Maybe<Order_By>;
  financing_contract_application?: Maybe<Financing_Contract_Application_Order_By>;
  financing_contract_application_id?: Maybe<Order_By>;
  financing_provider?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  product_residual_value_grid?: Maybe<Order_By>;
  provider_order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "financing_contract" */
export enum Financing_Contract_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinancierAccepted = 'financier_accepted',
  /** column name */
  FinancingContractApplicationId = 'financing_contract_application_id',
  /** column name */
  FinancingProvider = 'financing_provider',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductResidualValueGrid = 'product_residual_value_grid',
  /** column name */
  ProviderOrderId = 'provider_order_id',
  /** column name */
  ServicePackSellingPriceExclTax = 'service_pack_selling_price_excl_tax',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "financing_contract_aggregate_bool_exp_bool_and_arguments_columns" columns of table "financing_contract" */
export enum Financing_Contract_Select_Column_Financing_Contract_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FinancierAccepted = 'financier_accepted'
}

/** select "financing_contract_aggregate_bool_exp_bool_or_arguments_columns" columns of table "financing_contract" */
export enum Financing_Contract_Select_Column_Financing_Contract_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FinancierAccepted = 'financier_accepted'
}

/** aggregate stddev on columns */
export type Financing_Contract_Stddev_Fields = {
  __typename?: 'financing_contract_stddev_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "financing_contract" */
export type Financing_Contract_Stddev_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Financing_Contract_Stddev_Pop_Fields = {
  __typename?: 'financing_contract_stddev_pop_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "financing_contract" */
export type Financing_Contract_Stddev_Pop_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Financing_Contract_Stddev_Samp_Fields = {
  __typename?: 'financing_contract_stddev_samp_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "financing_contract" */
export type Financing_Contract_Stddev_Samp_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** Streaming cursor of the table "financing_contract" */
export type Financing_Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Financing_Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Financing_Contract_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  financier_accepted?: Maybe<Scalars['Boolean']>;
  financing_contract_application_id?: Maybe<Scalars['Int']>;
  financing_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  product_residual_value_grid?: Maybe<Scalars['jsonb']>;
  provider_order_id?: Maybe<Scalars['String']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Financing_Contract_Sum_Fields = {
  __typename?: 'financing_contract_sum_fields';
  financing_contract_application_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "financing_contract" */
export type Financing_Contract_Sum_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Financing_Contract_Var_Pop_Fields = {
  __typename?: 'financing_contract_var_pop_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "financing_contract" */
export type Financing_Contract_Var_Pop_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Financing_Contract_Var_Samp_Fields = {
  __typename?: 'financing_contract_var_samp_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "financing_contract" */
export type Financing_Contract_Var_Samp_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Financing_Contract_Variance_Fields = {
  __typename?: 'financing_contract_variance_fields';
  financing_contract_application_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  service_pack_selling_price_excl_tax?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "financing_contract" */
export type Financing_Contract_Variance_Order_By = {
  financing_contract_application_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  service_pack_selling_price_excl_tax?: Maybe<Order_By>;
};

/** columns and relationships of "insurance_ticket_states" */
export type Insurance_Ticket_States = {
  __typename?: 'insurance_ticket_states';
  created_at: Scalars['timestamptz'];
  from_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  from_state?: Maybe<Insurance_Ticket_States>;
  id: Scalars['Int'];
  /** An object relationship */
  insurance_ticket: Insurance_Tickets;
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  resource_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "insurance_ticket_states" */
export type Insurance_Ticket_StatesMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Ticket_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Ticket_States_Max_Order_By>;
  min?: Maybe<Insurance_Ticket_States_Min_Order_By>;
  stddev?: Maybe<Insurance_Ticket_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Ticket_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Ticket_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Ticket_States_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Ticket_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Ticket_States_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Ticket_States_Variance_Order_By>;
};

/** order by avg() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Avg_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_ticket_states". All fields are combined with a logical 'AND'. */
export type Insurance_Ticket_States_Bool_Exp = {
  _and?: Maybe<Array<Insurance_Ticket_States_Bool_Exp>>;
  _not?: Maybe<Insurance_Ticket_States_Bool_Exp>;
  _or?: Maybe<Array<Insurance_Ticket_States_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_id?: Maybe<Int_Comparison_Exp>;
  from_state?: Maybe<Insurance_Ticket_States_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  insurance_ticket?: Maybe<Insurance_Tickets_Bool_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "insurance_ticket_states". */
export type Insurance_Ticket_States_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  from_state?: Maybe<Insurance_Ticket_States_Order_By>;
  id?: Maybe<Order_By>;
  insurance_ticket?: Maybe<Insurance_Tickets_Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "insurance_ticket_states" */
export enum Insurance_Ticket_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromId = 'from_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Stddev_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Stddev_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Stddev_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "insurance_ticket_states" */
export type Insurance_Ticket_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Ticket_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Ticket_States_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Sum_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Var_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Var_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "insurance_ticket_states" */
export type Insurance_Ticket_States_Variance_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "insurance_tickets" */
export type Insurance_Tickets = {
  __typename?: 'insurance_tickets';
  after_action?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  /** An object relationship */
  current_state?: Maybe<Insurance_Ticket_States>;
  /** An array relationship */
  files: Array<Insurance_Tickets_Files>;
  id: Scalars['Int'];
  incident_date?: Maybe<Scalars['timestamptz']>;
  incident_description?: Maybe<Scalars['String']>;
  incident_type: Scalars['String'];
  /** An object relationship */
  order?: Maybe<Orders>;
  /** undefined */
  order_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order_item?: Maybe<Order_Items>;
  order_item_id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  /** An array relationship */
  states: Array<Insurance_Ticket_States>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "insurance_tickets" */
export type Insurance_TicketsFilesArgs = {
  distinct_on?: Maybe<Array<Insurance_Tickets_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Tickets_Files_Order_By>>;
  where?: Maybe<Insurance_Tickets_Files_Bool_Exp>;
};


/** columns and relationships of "insurance_tickets" */
export type Insurance_TicketsStatesArgs = {
  distinct_on?: Maybe<Array<Insurance_Ticket_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Ticket_States_Order_By>>;
  where?: Maybe<Insurance_Ticket_States_Bool_Exp>;
};

/** order by aggregate values of table "insurance_tickets" */
export type Insurance_Tickets_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Tickets_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Tickets_Max_Order_By>;
  min?: Maybe<Insurance_Tickets_Min_Order_By>;
  stddev?: Maybe<Insurance_Tickets_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Tickets_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Tickets_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Tickets_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Tickets_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Tickets_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Tickets_Variance_Order_By>;
};

/** order by avg() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Avg_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_tickets". All fields are combined with a logical 'AND'. */
export type Insurance_Tickets_Bool_Exp = {
  _and?: Maybe<Array<Insurance_Tickets_Bool_Exp>>;
  _not?: Maybe<Insurance_Tickets_Bool_Exp>;
  _or?: Maybe<Array<Insurance_Tickets_Bool_Exp>>;
  after_action?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  current_state?: Maybe<Insurance_Ticket_States_Bool_Exp>;
  files?: Maybe<Insurance_Tickets_Files_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  incident_date?: Maybe<Timestamptz_Comparison_Exp>;
  incident_description?: Maybe<String_Comparison_Exp>;
  incident_type?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  order_item?: Maybe<Order_Items_Bool_Exp>;
  order_item_id?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  states?: Maybe<Insurance_Ticket_States_Bool_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** columns and relationships of "insurance_tickets_files" */
export type Insurance_Tickets_Files = {
  __typename?: 'insurance_tickets_files';
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  insurance_ticket?: Maybe<Insurance_Tickets>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Tickets_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Tickets_Files_Max_Order_By>;
  min?: Maybe<Insurance_Tickets_Files_Min_Order_By>;
  stddev?: Maybe<Insurance_Tickets_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Tickets_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Tickets_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Tickets_Files_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Tickets_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Tickets_Files_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Tickets_Files_Variance_Order_By>;
};

/** order by avg() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Avg_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_tickets_files". All fields are combined with a logical 'AND'. */
export type Insurance_Tickets_Files_Bool_Exp = {
  _and?: Maybe<Array<Insurance_Tickets_Files_Bool_Exp>>;
  _not?: Maybe<Insurance_Tickets_Files_Bool_Exp>;
  _or?: Maybe<Array<Insurance_Tickets_Files_Bool_Exp>>;
  content_length?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  insurance_ticket?: Maybe<Insurance_Tickets_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  resource_type?: Maybe<String_Comparison_Exp>;
  storage?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Max_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** order by min() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Min_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "insurance_tickets_files". */
export type Insurance_Tickets_Files_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_ticket?: Maybe<Insurance_Tickets_Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "insurance_tickets_files" */
export enum Insurance_Tickets_Files_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Storage = 'storage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** order by stddev() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Tickets_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Tickets_Files_Stream_Cursor_Value_Input = {
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Sum_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "insurance_tickets_files" */
export type Insurance_Tickets_Files_Variance_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by max() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Max_Order_By = {
  after_action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incident_date?: Maybe<Order_By>;
  incident_description?: Maybe<Order_By>;
  incident_type?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Min_Order_By = {
  after_action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incident_date?: Maybe<Order_By>;
  incident_description?: Maybe<Order_By>;
  incident_type?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "insurance_tickets" */
export type Insurance_Tickets_Mutation_Response = {
  __typename?: 'insurance_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance_Tickets>;
};

/** Ordering options when selecting data from "insurance_tickets". */
export type Insurance_Tickets_Order_By = {
  after_action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_state?: Maybe<Insurance_Ticket_States_Order_By>;
  files_aggregate?: Maybe<Insurance_Tickets_Files_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  incident_date?: Maybe<Order_By>;
  incident_description?: Maybe<Order_By>;
  incident_type?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  order_item?: Maybe<Order_Items_Order_By>;
  order_item_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  states_aggregate?: Maybe<Insurance_Ticket_States_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: insurance_tickets */
export type Insurance_Tickets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "insurance_tickets" */
export enum Insurance_Tickets_Select_Column {
  /** column name */
  AfterAction = 'after_action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentDate = 'incident_date',
  /** column name */
  IncidentDescription = 'incident_description',
  /** column name */
  IncidentType = 'incident_type',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderItemId = 'order_item_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "insurance_tickets" */
export type Insurance_Tickets_Set_Input = {
  after_action?: Maybe<Scalars['String']>;
  incident_date?: Maybe<Scalars['timestamptz']>;
  incident_description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "insurance_tickets" */
export type Insurance_Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Tickets_Stream_Cursor_Value_Input = {
  after_action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  incident_date?: Maybe<Scalars['timestamptz']>;
  incident_description?: Maybe<Scalars['String']>;
  incident_type?: Maybe<Scalars['String']>;
  /** undefined */
  order_id?: Maybe<Scalars['Int']>;
  order_item_id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Sum_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

export type Insurance_Tickets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insurance_Tickets_Set_Input>;
  where: Insurance_Tickets_Bool_Exp;
};

/** order by var_pop() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "insurance_tickets" */
export type Insurance_Tickets_Variance_Order_By = {
  id?: Maybe<Order_By>;
  /** undefined */
  order_id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "invoice_files" */
export type Invoice_Files = {
  __typename?: 'invoice_files';
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  invoice?: Maybe<Invoices>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "invoice_files" */
export type Invoice_Files_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Files_Max_Order_By>;
  min?: Maybe<Invoice_Files_Min_Order_By>;
  stddev?: Maybe<Invoice_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Files_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Files_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Files_Variance_Order_By>;
};

/** order by avg() on columns of table "invoice_files" */
export type Invoice_Files_Avg_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_files". All fields are combined with a logical 'AND'. */
export type Invoice_Files_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Files_Bool_Exp>>;
  _not?: Maybe<Invoice_Files_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Files_Bool_Exp>>;
  content_length?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice?: Maybe<Invoices_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  resource_type?: Maybe<String_Comparison_Exp>;
  storage?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "invoice_files" */
export type Invoice_Files_Max_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** order by min() on columns of table "invoice_files" */
export type Invoice_Files_Min_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "invoice_files". */
export type Invoice_Files_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice?: Maybe<Invoices_Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "invoice_files" */
export enum Invoice_Files_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Storage = 'storage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** order by stddev() on columns of table "invoice_files" */
export type Invoice_Files_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "invoice_files" */
export type Invoice_Files_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "invoice_files" */
export type Invoice_Files_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoice_files" */
export type Invoice_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Files_Stream_Cursor_Value_Input = {
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "invoice_files" */
export type Invoice_Files_Sum_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "invoice_files" */
export type Invoice_Files_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "invoice_files" */
export type Invoice_Files_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "invoice_files" */
export type Invoice_Files_Variance_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** columns and relationships of "invoice_groups" */
export type Invoice_Groups = {
  __typename?: 'invoice_groups';
  /** An object relationship */
  b2b_account: B2b_Accounts;
  b2b_account_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  invoices: Array<Invoices>;
  reference: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "invoice_groups" */
export type Invoice_GroupsInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};

/** aggregated selection of "invoice_groups" */
export type Invoice_Groups_Aggregate = {
  __typename?: 'invoice_groups_aggregate';
  aggregate?: Maybe<Invoice_Groups_Aggregate_Fields>;
  nodes: Array<Invoice_Groups>;
};

/** aggregate fields of "invoice_groups" */
export type Invoice_Groups_Aggregate_Fields = {
  __typename?: 'invoice_groups_aggregate_fields';
  avg?: Maybe<Invoice_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Groups_Max_Fields>;
  min?: Maybe<Invoice_Groups_Min_Fields>;
  stddev?: Maybe<Invoice_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Groups_Sum_Fields>;
  var_pop?: Maybe<Invoice_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Groups_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Groups_Variance_Fields>;
};


/** aggregate fields of "invoice_groups" */
export type Invoice_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Invoice_Groups_Avg_Fields = {
  __typename?: 'invoice_groups_avg_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "invoice_groups". All fields are combined with a logical 'AND'. */
export type Invoice_Groups_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Groups_Bool_Exp>>;
  _not?: Maybe<Invoice_Groups_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Groups_Bool_Exp>>;
  b2b_account?: Maybe<B2b_Accounts_Bool_Exp>;
  b2b_account_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Invoice_Groups_Max_Fields = {
  __typename?: 'invoice_groups_max_fields';
  b2b_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Invoice_Groups_Min_Fields = {
  __typename?: 'invoice_groups_min_fields';
  b2b_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "invoice_groups". */
export type Invoice_Groups_Order_By = {
  b2b_account?: Maybe<B2b_Accounts_Order_By>;
  b2b_account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "invoice_groups" */
export enum Invoice_Groups_Select_Column {
  /** column name */
  B2bAccountId = 'b2b_account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Invoice_Groups_Stddev_Fields = {
  __typename?: 'invoice_groups_stddev_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Groups_Stddev_Pop_Fields = {
  __typename?: 'invoice_groups_stddev_pop_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Groups_Stddev_Samp_Fields = {
  __typename?: 'invoice_groups_stddev_samp_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "invoice_groups" */
export type Invoice_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Groups_Stream_Cursor_Value_Input = {
  b2b_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invoice_Groups_Sum_Fields = {
  __typename?: 'invoice_groups_sum_fields';
  b2b_account_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Invoice_Groups_Var_Pop_Fields = {
  __typename?: 'invoice_groups_var_pop_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Invoice_Groups_Var_Samp_Fields = {
  __typename?: 'invoice_groups_var_samp_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Invoice_Groups_Variance_Fields = {
  __typename?: 'invoice_groups_variance_fields';
  b2b_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "invoice_payment" */
export type Invoice_Payment = {
  __typename?: 'invoice_payment';
  id: Scalars['Int'];
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars['Int'];
  /** An object relationship */
  payment: Payments;
  payment_id: Scalars['Int'];
};

/** order by aggregate values of table "invoice_payment" */
export type Invoice_Payment_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Payment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Payment_Max_Order_By>;
  min?: Maybe<Invoice_Payment_Min_Order_By>;
  stddev?: Maybe<Invoice_Payment_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Payment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Payment_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Payment_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Payment_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Payment_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Payment_Variance_Order_By>;
};

/** order by avg() on columns of table "invoice_payment" */
export type Invoice_Payment_Avg_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_payment". All fields are combined with a logical 'AND'. */
export type Invoice_Payment_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Payment_Bool_Exp>>;
  _not?: Maybe<Invoice_Payment_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Payment_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice?: Maybe<Invoices_Bool_Exp>;
  invoice_id?: Maybe<Int_Comparison_Exp>;
  payment?: Maybe<Payments_Bool_Exp>;
  payment_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "invoice_payment" */
export type Invoice_Payment_Max_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "invoice_payment" */
export type Invoice_Payment_Min_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "invoice_payment". */
export type Invoice_Payment_Order_By = {
  id?: Maybe<Order_By>;
  invoice?: Maybe<Invoices_Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment?: Maybe<Payments_Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** select columns of table "invoice_payment" */
export enum Invoice_Payment_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  PaymentId = 'payment_id'
}

/** order by stddev() on columns of table "invoice_payment" */
export type Invoice_Payment_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "invoice_payment" */
export type Invoice_Payment_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "invoice_payment" */
export type Invoice_Payment_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoice_payment" */
export type Invoice_Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Payment_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  payment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice_payment" */
export type Invoice_Payment_Sum_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "invoice_payment" */
export type Invoice_Payment_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "invoice_payment" */
export type Invoice_Payment_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "invoice_payment" */
export type Invoice_Payment_Variance_Order_By = {
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
};

/** columns and relationships of "invoices" */
export type Invoices = {
  __typename?: 'invoices';
  charge_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  files: Array<Invoice_Files>;
  id: Scalars['Int'];
  /** An object relationship */
  invoice_group?: Maybe<Invoice_Groups>;
  invoice_type: Scalars['String'];
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An object relationship */
  order: Orders;
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
  paid_at?: Maybe<Scalars['timestamptz']>;
  payment_source?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  payments: Array<Invoice_Payment>;
  reference: Scalars['String'];
  /** An object relationship */
  refunding_invoice?: Maybe<Invoices>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "invoices" */
export type InvoicesFilesArgs = {
  distinct_on?: Maybe<Array<Invoice_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Files_Order_By>>;
  where?: Maybe<Invoice_Files_Bool_Exp>;
};


/** columns and relationships of "invoices" */
export type InvoicesInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "invoices" */
export type InvoicesOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "invoices" */
export type InvoicesOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "invoices" */
export type InvoicesPayment_SourceArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "invoices" */
export type InvoicesPaymentsArgs = {
  distinct_on?: Maybe<Array<Invoice_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Payment_Order_By>>;
  where?: Maybe<Invoice_Payment_Bool_Exp>;
};

/** order by aggregate values of table "invoices" */
export type Invoices_Aggregate_Order_By = {
  avg?: Maybe<Invoices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoices_Max_Order_By>;
  min?: Maybe<Invoices_Min_Order_By>;
  stddev?: Maybe<Invoices_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoices_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoices_Sum_Order_By>;
  var_pop?: Maybe<Invoices_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoices_Var_Samp_Order_By>;
  variance?: Maybe<Invoices_Variance_Order_By>;
};

/** order by avg() on columns of table "invoices" */
export type Invoices_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices". All fields are combined with a logical 'AND'. */
export type Invoices_Bool_Exp = {
  _and?: Maybe<Array<Invoices_Bool_Exp>>;
  _not?: Maybe<Invoices_Bool_Exp>;
  _or?: Maybe<Array<Invoices_Bool_Exp>>;
  charge_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  files?: Maybe<Invoice_Files_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_group?: Maybe<Invoice_Groups_Bool_Exp>;
  invoice_type?: Maybe<String_Comparison_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_items?: Maybe<Order_Items_Bool_Exp>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Bool_Exp>;
  paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  payment_source?: Maybe<Jsonb_Comparison_Exp>;
  payments?: Maybe<Invoice_Payment_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  refunding_invoice?: Maybe<Invoices_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "invoices" */
export type Invoices_Max_Order_By = {
  charge_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_type?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "invoices" */
export type Invoices_Min_Order_By = {
  charge_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_type?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "invoices". */
export type Invoices_Order_By = {
  charge_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  files_aggregate?: Maybe<Invoice_Files_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  invoice_group?: Maybe<Invoice_Groups_Order_By>;
  invoice_type?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Order_By>;
  paid_at?: Maybe<Order_By>;
  payment_source?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Invoice_Payment_Aggregate_Order_By>;
  reference?: Maybe<Order_By>;
  refunding_invoice?: Maybe<Invoices_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "invoices" */
export enum Invoices_Select_Column {
  /** column name */
  ChargeAt = 'charge_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceType = 'invoice_type',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PaymentSource = 'payment_source',
  /** column name */
  Reference = 'reference',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "invoices" */
export type Invoices_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "invoices" */
export type Invoices_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "invoices" */
export type Invoices_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoices" */
export type Invoices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_Stream_Cursor_Value_Input = {
  charge_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_type?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  payment_source?: Maybe<Scalars['jsonb']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "invoices" */
export type Invoices_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "invoices" */
export type Invoices_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "invoices" */
export type Invoices_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "invoices" */
export type Invoices_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "items" */
export type Items = {
  __typename?: 'items';
  id: Scalars['Int'];
  is_giveaway?: Maybe<Scalars['Boolean']>;
  is_online: Scalars['Boolean'];
  is_physical?: Maybe<Scalars['Boolean']>;
  is_purchasable?: Maybe<Scalars['Boolean']>;
  item_type: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
  properties?: Maybe<Scalars['jsonb']>;
  sell_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  sku: Scalars['String'];
  /** Create a slug from brand, model, display size and color of an item (if available) */
  slug?: Maybe<Scalars['String']>;
  /** An object relationship */
  stock?: Maybe<Devices_Stock_View_Cache>;
  /** An array relationship */
  stock_items: Array<Stock_Items>;
  /** An object relationship */
  tax?: Maybe<Taxes>;
};


/** columns and relationships of "items" */
export type ItemsOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsPropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "items" */
export type ItemsStock_ItemsArgs = {
  distinct_on?: Maybe<Array<Stock_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Items_Order_By>>;
  where?: Maybe<Stock_Items_Bool_Exp>;
};

/** order by aggregate values of table "items" */
export type Items_Aggregate_Order_By = {
  avg?: Maybe<Items_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Items_Max_Order_By>;
  min?: Maybe<Items_Min_Order_By>;
  stddev?: Maybe<Items_Stddev_Order_By>;
  stddev_pop?: Maybe<Items_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Items_Stddev_Samp_Order_By>;
  sum?: Maybe<Items_Sum_Order_By>;
  var_pop?: Maybe<Items_Var_Pop_Order_By>;
  var_samp?: Maybe<Items_Var_Samp_Order_By>;
  variance?: Maybe<Items_Variance_Order_By>;
};

/** order by avg() on columns of table "items" */
export type Items_Avg_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  _and?: Maybe<Array<Items_Bool_Exp>>;
  _not?: Maybe<Items_Bool_Exp>;
  _or?: Maybe<Array<Items_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  is_giveaway?: Maybe<Boolean_Comparison_Exp>;
  is_online?: Maybe<Boolean_Comparison_Exp>;
  is_physical?: Maybe<Boolean_Comparison_Exp>;
  is_purchasable?: Maybe<Boolean_Comparison_Exp>;
  item_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_items?: Maybe<Order_Items_Bool_Exp>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Bool_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  sell_price_in_cents_excl_tax?: Maybe<Int_Comparison_Exp>;
  sku?: Maybe<String_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  stock?: Maybe<Devices_Stock_View_Cache_Bool_Exp>;
  stock_items?: Maybe<Stock_Items_Bool_Exp>;
  tax?: Maybe<Taxes_Bool_Exp>;
};

/** order by max() on columns of table "items" */
export type Items_Max_Order_By = {
  id?: Maybe<Order_By>;
  item_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
};

/** order by min() on columns of table "items" */
export type Items_Min_Order_By = {
  id?: Maybe<Order_By>;
  item_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  id?: Maybe<Order_By>;
  is_giveaway?: Maybe<Order_By>;
  is_online?: Maybe<Order_By>;
  is_physical?: Maybe<Order_By>;
  is_purchasable?: Maybe<Order_By>;
  item_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Order_By>;
  properties?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  stock?: Maybe<Devices_Stock_View_Cache_Order_By>;
  stock_items_aggregate?: Maybe<Stock_Items_Aggregate_Order_By>;
  tax?: Maybe<Taxes_Order_By>;
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsGiveaway = 'is_giveaway',
  /** column name */
  IsOnline = 'is_online',
  /** column name */
  IsPhysical = 'is_physical',
  /** column name */
  IsPurchasable = 'is_purchasable',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Name = 'name',
  /** column name */
  Properties = 'properties',
  /** column name */
  SellPriceInCentsExclTax = 'sell_price_in_cents_excl_tax',
  /** column name */
  Sku = 'sku'
}

/** order by stddev() on columns of table "items" */
export type Items_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "items" */
export type Items_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "items" */
export type Items_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  is_giveaway?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  is_physical?: Maybe<Scalars['Boolean']>;
  is_purchasable?: Maybe<Scalars['Boolean']>;
  item_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  sell_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
};

/** order by sum() on columns of table "items" */
export type Items_Sum_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "items" */
export type Items_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "items" */
export type Items_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};

/** order by variance() on columns of table "items" */
export type Items_Variance_Order_By = {
  id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  LpmOrdering_createOrder: CreateLpmOrderResult;
  admin_customerAccount_deleteCard: DeleteCardResponse;
  afone_swapEsim: SuccessObjectDto;
  auth_unban_customer: UnbanCustomerResult;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  cleaq_createOrders: CreateOrdersResult;
  contract_createContractAndRequestSignature: SuccessObjectDto;
  createAccessory?: Maybe<AccessoryEntityResponse>;
  createAccessoryLocalization?: Maybe<AccessoryEntityResponse>;
  createB2CSmartphoneCatalogLocalization?: Maybe<B2CSmartphoneCatalogEntityResponse>;
  createCatalog?: Maybe<CatalogEntityResponse>;
  createComparatorRival?: Maybe<ComparatorRivalEntityResponse>;
  createContent?: Maybe<ContentEntityResponse>;
  createEmailTemplaterLayout?: Maybe<EmailTemplaterLayoutEntityResponse>;
  createEmailTemplaterTemplate?: Maybe<EmailTemplaterTemplateEntityResponse>;
  createFaq?: Maybe<FaqEntityResponse>;
  createLaptopFamily?: Maybe<LaptopFamilyEntityResponse>;
  createLaptopSubscription?: Maybe<LaptopSubscriptionEntityResponse>;
  createLaptopVariant?: Maybe<LaptopVariantEntityResponse>;
  createPhonePlanSubscription?: Maybe<PhonePlanSubscriptionEntityResponse>;
  createReview?: Maybe<ReviewEntityResponse>;
  createReviewLocalization?: Maybe<ReviewEntityResponse>;
  createSeoProperty?: Maybe<SeoPropertyEntityResponse>;
  createSeoPropertyLocalization?: Maybe<SeoPropertyEntityResponse>;
  createSmartphoneFamily?: Maybe<SmartphoneFamilyEntityResponse>;
  createSmartphoneFamilyLocalization?: Maybe<SmartphoneFamilyEntityResponse>;
  createSmartphoneSubscription?: Maybe<SmartphoneSubscriptionEntityResponse>;
  createSmartphoneSubscriptionLocalization?: Maybe<SmartphoneSubscriptionEntityResponse>;
  createSmartphoneVariant?: Maybe<SmartphoneVariantEntityResponse>;
  createSmartphoneVariantLocalization?: Maybe<SmartphoneVariantEntityResponse>;
  createTabletFamily?: Maybe<TabletFamilyEntityResponse>;
  createTabletSubscription?: Maybe<TabletSubscriptionEntityResponse>;
  createTabletVariant?: Maybe<TabletVariantEntityResponse>;
  createTag?: Maybe<TagEntityResponse>;
  createTagLocalization?: Maybe<TagEntityResponse>;
  createTranslation?: Maybe<TranslationEntityResponse>;
  createTranslationLocalization?: Maybe<TranslationEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  customerAccount_UpdateInsuranceTicket: UpdateInsuranceTicketResponse;
  customerAccount_addBankDetail: AddBankDetailResult;
  customerAccount_addCard: AddCardResult;
  customerAccount_addOwnershipProof: AddOwnershipProofResult;
  customerAccount_deleteCard: DeleteCardResponse;
  customerAccount_payInsuranceTicket: PayInsuranceTicketResult;
  customerAccount_payUnpaidInvoices: PayUnpaidInvoicesResult;
  customerAccount_placeInsuranceTicket: PlaceInsuranceTicketResponse;
  customer_markAddressAsDeliverable: CustomerMarkAddressAsDeliverableResult;
  customer_markAddressAsUndeliverable: CustomerMarkAddressAsUndeliverableResult;
  customer_recordJunkMailTrial: SuccessObjectDto;
  deleteAccessory?: Maybe<AccessoryEntityResponse>;
  deleteB2BLaptopCatalog?: Maybe<B2BLaptopCatalogEntityResponse>;
  deleteB2BPhonePlanCatalog?: Maybe<B2BPhonePlanCatalogEntityResponse>;
  deleteB2BSmartphoneCatalog?: Maybe<B2BSmartphoneCatalogEntityResponse>;
  deleteB2BTabletCatalog?: Maybe<B2BTabletCatalogEntityResponse>;
  deleteB2CSmartphoneBestSellersCatalog?: Maybe<B2CSmartphoneBestSellersCatalogEntityResponse>;
  deleteB2CSmartphoneCatalog?: Maybe<B2CSmartphoneCatalogEntityResponse>;
  deleteB2CSmartphoneOthersCatalog?: Maybe<B2CSmartphoneOthersCatalogEntityResponse>;
  deleteCatalog?: Maybe<CatalogEntityResponse>;
  deleteCgu?: Maybe<CguEntityResponse>;
  deleteComparatorRival?: Maybe<ComparatorRivalEntityResponse>;
  deleteContent?: Maybe<ContentEntityResponse>;
  deleteEmailTemplaterLayout?: Maybe<EmailTemplaterLayoutEntityResponse>;
  deleteEmailTemplaterTemplate?: Maybe<EmailTemplaterTemplateEntityResponse>;
  deleteFaq?: Maybe<FaqEntityResponse>;
  deleteLaptopFamily?: Maybe<LaptopFamilyEntityResponse>;
  deleteLaptopSubscription?: Maybe<LaptopSubscriptionEntityResponse>;
  deleteLaptopVariant?: Maybe<LaptopVariantEntityResponse>;
  deleteLpmSalesFunnelCatalog?: Maybe<LpmSalesFunnelCatalogEntityResponse>;
  deletePhonePlanSubscription?: Maybe<PhonePlanSubscriptionEntityResponse>;
  deletePrivacy?: Maybe<PrivacyEntityResponse>;
  deleteReview?: Maybe<ReviewEntityResponse>;
  deleteSeoProperty?: Maybe<SeoPropertyEntityResponse>;
  deleteSmartphoneFamily?: Maybe<SmartphoneFamilyEntityResponse>;
  deleteSmartphoneSubscription?: Maybe<SmartphoneSubscriptionEntityResponse>;
  deleteSmartphoneVariant?: Maybe<SmartphoneVariantEntityResponse>;
  deleteTabletFamily?: Maybe<TabletFamilyEntityResponse>;
  deleteTabletSubscription?: Maybe<TabletSubscriptionEntityResponse>;
  deleteTabletVariant?: Maybe<TabletVariantEntityResponse>;
  deleteTag?: Maybe<TagEntityResponse>;
  deleteTranslation?: Maybe<TranslationEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteWebsiteSetting?: Maybe<WebsiteSettingEntityResponse>;
  /** delete data from the table: "b2b_users_orders" */
  delete_b2b_users_orders?: Maybe<B2b_Users_Orders_Mutation_Response>;
  /** delete single row from the table: "b2b_users_orders" */
  delete_b2b_users_orders_by_pk?: Maybe<B2b_Users_Orders>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  /** insert data into the table: "b2b_users_orders" */
  insert_b2b_users_orders?: Maybe<B2b_Users_Orders_Mutation_Response>;
  /** insert a single row into the table: "b2b_users_orders" */
  insert_b2b_users_orders_one?: Maybe<B2b_Users_Orders>;
  /** insert data into the table: "customer_reminders" */
  insert_customer_reminders?: Maybe<Customer_Reminders_Mutation_Response>;
  /** insert data into the table: "experiments_allocations" */
  insert_experiments_allocations?: Maybe<Experiments_Allocations_Mutation_Response>;
  /** insert a single row into the table: "experiments_allocations" */
  insert_experiments_allocations_one?: Maybe<Experiments_Allocations>;
  insurance_declareDamages: SuccessObjectDto;
  invoice_refundPartial: RefundPartial;
  invoice_refundTotal: RefundTotalResult;
  kyc_KycValidate: KycValidateResult;
  kyc_RevokeKycSignature: RevokeKycSignatureResult;
  kyc_TrackBankConnection: TrackBankConnectionResult;
  kyc_finishKyc: FinishKycResult;
  kyc_updateStatusAndIncome: UpdateStatusAndIncomeResult;
  login: UsersPermissionsLoginPayload;
  logistic_printPreparationNote: PrintPreparationNoteResult;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  nest_adminAddAccessoriesIntoStockMovementParcel: SuccessObjectDto;
  nest_adminAddCustomerBankDetails: BankDetailsResults;
  nest_adminAddDeviceIntoStockMovementParcel: SuccessObjectDto;
  nest_adminAddOrderItems: SuccessObjectDto;
  nest_adminAddSupplierOrderToParcel: AddSupplierOrderItemsResult;
  nest_adminAssignOrder: AssignedOrderDto;
  nest_adminAssignOrderItemIccid: SuccessObjectDto;
  nest_adminCancelPayment: SuccessObjectDto;
  nest_adminChangeOrderItemColor: SuccessObjectDto;
  nest_adminChargeB2BInvoiceGroup: SuccessObjectDto;
  nest_adminChargeInvoice: InvoiceChargeSuccessDto;
  nest_adminChargeManyInvoices: InvoiceChargeSuccessDto;
  nest_adminCloseParcel: SuccessObjectDto;
  nest_adminCloseStockMovementParcel: SuccessObjectDto;
  nest_adminConvertToCompliant: SuccessObjectDto;
  nest_adminCreateB2BAccount: SuccessObjectDto;
  nest_adminCreateB2BOrderBatch: SuccessObjectDto;
  nest_adminCreateDraftStockMovementParcel: SuccessObjectDto;
  nest_adminCreateInvoice: SuccessObjectDto;
  nest_adminCreateParcel: Parcel;
  nest_adminDeleteOrderItem: SuccessObjectDto;
  nest_adminDestroyDraftOrder: SuccessObjectDto;
  nest_adminDestroyDraftStockMovementParcel: SuccessObjectDto;
  nest_adminDestroyParcel: SuccessObjectDto;
  nest_adminDowngradeOrder: SuccessObjectDto;
  nest_adminForceOrderParcelAsReceived: SuccessObjectDto;
  nest_adminForceStockMovementParcelAsReceived: SuccessObjectDto;
  nest_adminGenerateB2BPurchaseOrder: SuccessObjectDto;
  nest_adminGenerateStockMovementNote: SuccessObjectDto;
  nest_adminGenerateTemporaryToken: AuthResponse;
  nest_adminGenerateTrackingForOrder: SuccessObjectDto;
  nest_adminInvoiceCollectionCancel: SuccessObjectDto;
  nest_adminInvoiceCollectionCollect: SuccessObjectDto;
  nest_adminInvoiceDevice: SuccessObjectDto;
  nest_adminMigrateB2BOrder: SuccessObjectDto;
  nest_adminProcessReturnParcel: SuccessObjectDto;
  nest_adminReAssignOrder: SuccessObjectDto;
  nest_adminRefreshIcloudStatus: SuccessObjectDto;
  nest_adminRejectKYC: SuccessObjectDto;
  nest_adminRemoveOrderFromPreparation: SuccessObjectDto;
  nest_adminRemoveStockItemsFromDraftStockMovementParcel: SuccessObjectDto;
  nest_adminRollbackOrder: SuccessObjectDto;
  nest_adminRollbackOrderItem: SuccessObjectDto;
  nest_adminScheduleReturn: SuccessObjectDto;
  nest_adminSendIcloudReturnInstructions: SuccessObjectDto;
  nest_adminSendReturnEmail: SuccessObjectDto;
  nest_adminTerminateOrder: SuccessObjectDto;
  nest_adminTransformInsuranceOrderToResignation: SuccessObjectDto;
  nest_adminTransformInsuranceOrderToSwap: SuccessObjectDto;
  nest_adminTransformParcelState: SuccessObjectDto;
  nest_adminTransitionInsuranceTicketState: SuccessObjectDto;
  nest_adminTransitionOrder: SuccessObjectDto;
  nest_adminTransitionOrderItem: SuccessObjectDto;
  nest_adminTransitionStockItem: SuccessObjectDto;
  nest_adminTriggerKYCEmail: SuccessObjectDto;
  nest_adminUnAssignOrder: SuccessObjectDto;
  nest_adminUpdateB2BAccountInformation: SuccessObjectDto;
  nest_adminUpdateCustomer: SuccessObjectDto;
  nest_adminUpdateInvoice: SuccessObjectDto;
  nest_adminUpdateOrderPaymentSource: SuccessObjectDto;
  nest_adminUpdateParcelTracking: SuccessObjectDto;
  nest_adminUpdateUserStockAssignation: SuccessObjectDto;
  nest_adminUploadKYCFile: SuccessObjectDto;
  nest_adminUpsertUser: SuccessObjectDto;
  nest_adminValidateB2BDraftOrders: SuccessObjectDto;
  nest_askResetCustomerPassword: SuccessObjectDto;
  nest_b2b_updateAccountDefaultBusinessEntity: SuccessObjectDto;
  nest_b2b_updateB2bAccountAddress: BusinessAccountAddressResult;
  nest_b2b_updateB2bAccountName: BusinessAccountNameResult;
  nest_businessConfirmDraftOrders: SuccessObjectDto;
  nest_businessCreateDraftOrder: CreateBusinessDraftOrderResponse;
  nest_businessOrderAssign: SuccessObjectDto;
  nest_businessOrderUnassign: SuccessObjectDto;
  nest_businessUpdateDraftOrders: SuccessObjectDto;
  nest_confirmToPurchase: SuccessObjectDto;
  nest_createB2BUser: B2BCreateUserResponseDto;
  nest_createCustomerReminder: SuccessObjectDto;
  nest_createDraftInsuranceTicket: CreateDraftInsuranceTicketOutput;
  nest_createDraftOrder: CreateDraftOrderResponse;
  nest_createHelpScoutTicket: SuccessObjectDto;
  nest_createInsuranceTicketPayment: CreateOrderPaymentResponse;
  nest_createPipedriveDeal: PipedriveResolverResponse;
  nest_createWarrantyTicket: SuccessObjectDto;
  nest_customerAddBankDetails: BankDetailsResults;
  nest_customerCreateUbbleKyc: CreateUbbleKycResult;
  nest_customerLookupBankDetails?: Maybe<BankDetailsLookup>;
  nest_customerSetPassword: SuccessObjectDto;
  nest_customerUpdateShippingGateway: SuccessObjectDto;
  nest_customerUploadBankStatement: UploadBankStatementResult;
  nest_deleteB2BUser: B2BUserResponseDto;
  nest_deleteFile: SuccessObjectDto;
  nest_deletePurchases: SuccessObjectDto;
  nest_destroyCustomerDraftOrder: SuccessObjectDto;
  nest_finalizeInsuranceTicketPayment: SuccessObjectDto;
  nest_loginCustomer: AuthResponse;
  nest_newsletterSubscribe: SuccessObjectDto;
  nest_orderApplyDiscountCode: ApplyDiscountCodeResponse;
  nest_orderSwap_createSwap: OrderSwapCreateSwap;
  nest_orderSwap_disableSwap: OrderSwapDisableSwap;
  nest_orderSwap_enableSwap: OrderSwapEnableSwap;
  nest_orderSwap_swap: OrderSwapSwap;
  nest_reGenerateTrackingForIncomingParcel: SuccessObjectDto;
  nest_requestPhoneLineActivation: ActivationResult;
  nest_resetCustomerPassword: SuccessObjectDto;
  nest_updateB2BUser: B2BUserResponseDto;
  nest_updateCustomer: SuccessObjectDto;
  nest_updateCustomerBillingAddress: SuccessObjectDto;
  nest_updateCustomerDraftOrder: SuccessObjectDto;
  nest_updateCustomerShippingAddress: SuccessObjectDto;
  nest_updateDraftOrderOptionalAccessoriesNeeds: SuccessObjectDto;
  nest_updatePaymentSourcesToBankDetailsId: SuccessObjectDto;
  nest_updatePaymentSourcesToTokenId: SuccessObjectDto;
  nest_updatePurchasedInfos: SuccessObjectDto;
  nest_uploadFile: File;
  nest_verifyIcloudStatus: SuccessObjectDto;
  openBanking_powensOpenBankingInitiateAccountCheck: OpenBankingInitiateAccountCheckResult;
  openBanking_powensOpenBankingRevokeAccountCheck: OpenBankingRevokeAccountCheckResult;
  orderManagement_cancel?: Maybe<Scalars['String']>;
  orderManagement_requestCancelation?: Maybe<Scalars['String']>;
  order_subscription_changeOrderSubscription: ChangeOrderSubscriptionResult;
  order_subscription_customizeOrderSubscription: CustomizeOrderSubscriptionResult;
  order_subscription_disableOrderSubscriptionAutomaticRenewal: DisableOrderSubscriptionAutomaticRenewalResult;
  order_subscription_enableOrderSubscriptionAutomaticRenewal: EnableOrderSubscriptionAutomaticRenewalResult;
  ordering_checkout: CheckoutResult;
  ordering_payOrder: PayOrderResponse;
  ordering_removeCoupon: RemoveCouponResult;
  payment_authorize3dsPayment: Authorize3dsPaymentResponse;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  return_convertBnpToCompliant: ConvertBnpReturnToCompliantResult;
  signature_createPhonePlanContract: SuccessObjectDto;
  signature_deletePhonePlanContract: SuccessObjectDto;
  stocks_rollbackStockItem: RollbackStockItemResult;
  updateAccessory?: Maybe<AccessoryEntityResponse>;
  updateB2BLaptopCatalog?: Maybe<B2BLaptopCatalogEntityResponse>;
  updateB2BPhonePlanCatalog?: Maybe<B2BPhonePlanCatalogEntityResponse>;
  updateB2BSmartphoneCatalog?: Maybe<B2BSmartphoneCatalogEntityResponse>;
  updateB2BTabletCatalog?: Maybe<B2BTabletCatalogEntityResponse>;
  updateB2CSmartphoneBestSellersCatalog?: Maybe<B2CSmartphoneBestSellersCatalogEntityResponse>;
  updateB2CSmartphoneCatalog?: Maybe<B2CSmartphoneCatalogEntityResponse>;
  updateB2CSmartphoneOthersCatalog?: Maybe<B2CSmartphoneOthersCatalogEntityResponse>;
  updateCatalog?: Maybe<CatalogEntityResponse>;
  updateCgu?: Maybe<CguEntityResponse>;
  updateComparatorRival?: Maybe<ComparatorRivalEntityResponse>;
  updateContent?: Maybe<ContentEntityResponse>;
  updateEmailTemplaterLayout?: Maybe<EmailTemplaterLayoutEntityResponse>;
  updateEmailTemplaterTemplate?: Maybe<EmailTemplaterTemplateEntityResponse>;
  updateFaq?: Maybe<FaqEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateLaptopFamily?: Maybe<LaptopFamilyEntityResponse>;
  updateLaptopSubscription?: Maybe<LaptopSubscriptionEntityResponse>;
  updateLaptopVariant?: Maybe<LaptopVariantEntityResponse>;
  updateLpmSalesFunnelCatalog?: Maybe<LpmSalesFunnelCatalogEntityResponse>;
  updatePhonePlanSubscription?: Maybe<PhonePlanSubscriptionEntityResponse>;
  updatePrivacy?: Maybe<PrivacyEntityResponse>;
  updateReview?: Maybe<ReviewEntityResponse>;
  updateSeoProperty?: Maybe<SeoPropertyEntityResponse>;
  updateSmartphoneFamily?: Maybe<SmartphoneFamilyEntityResponse>;
  updateSmartphoneSubscription?: Maybe<SmartphoneSubscriptionEntityResponse>;
  updateSmartphoneVariant?: Maybe<SmartphoneVariantEntityResponse>;
  updateTabletFamily?: Maybe<TabletFamilyEntityResponse>;
  updateTabletSubscription?: Maybe<TabletSubscriptionEntityResponse>;
  updateTabletVariant?: Maybe<TabletVariantEntityResponse>;
  updateTag?: Maybe<TagEntityResponse>;
  updateTranslation?: Maybe<TranslationEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateWebsiteSetting?: Maybe<WebsiteSettingEntityResponse>;
  /** update data of the table: "b2b_users" */
  update_b2b_users?: Maybe<B2b_Users_Mutation_Response>;
  /** update single row of the table: "b2b_users" */
  update_b2b_users_by_pk?: Maybe<B2b_Users>;
  /** update multiples rows of table: "b2b_users" */
  update_b2b_users_many?: Maybe<Array<Maybe<B2b_Users_Mutation_Response>>>;
  /** update data of the table: "b2b_users_orders" */
  update_b2b_users_orders?: Maybe<B2b_Users_Orders_Mutation_Response>;
  /** update single row of the table: "b2b_users_orders" */
  update_b2b_users_orders_by_pk?: Maybe<B2b_Users_Orders>;
  /** update multiples rows of table: "b2b_users_orders" */
  update_b2b_users_orders_many?: Maybe<Array<Maybe<B2b_Users_Orders_Mutation_Response>>>;
  /** update data of the table: "customer_reminders" */
  update_customer_reminders?: Maybe<Customer_Reminders_Mutation_Response>;
  /** update multiples rows of table: "customer_reminders" */
  update_customer_reminders_many?: Maybe<Array<Maybe<Customer_Reminders_Mutation_Response>>>;
  /** update data of the table: "insurance_tickets" */
  update_insurance_tickets?: Maybe<Insurance_Tickets_Mutation_Response>;
  /** update single row of the table: "insurance_tickets" */
  update_insurance_tickets_by_pk?: Maybe<Insurance_Tickets>;
  /** update multiples rows of table: "insurance_tickets" */
  update_insurance_tickets_many?: Maybe<Array<Maybe<Insurance_Tickets_Mutation_Response>>>;
  upload: UploadFileEntityResponse;
  userManagement_createNewUser: CreateNewUserResult;
  userManagement_setRoleAndPermissions: SetRoleAndPermissionsResult;
  userManagement_toggleUserStatus: ToggleUserStatusResult;
};


/** mutation root */
export type Mutation_RootLpmOrdering_CreateOrderArgs = {
  lpmOrder: LpmCreateOrderArgs;
};


/** mutation root */
export type Mutation_RootAdmin_CustomerAccount_DeleteCardArgs = {
  card: DeleteCardCard;
  customerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAfone_SwapEsimArgs = {
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAuth_Unban_CustomerArgs = {
  customerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCleaq_CreateOrdersArgs = {
  orders: CreateOrdersArg;
};


/** mutation root */
export type Mutation_RootContract_CreateContractAndRequestSignatureArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCreateAccessoryArgs = {
  data: AccessoryInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateAccessoryLocalizationArgs = {
  data?: Maybe<AccessoryInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateB2CSmartphoneCatalogLocalizationArgs = {
  data?: Maybe<B2CSmartphoneCatalogInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateCatalogArgs = {
  data: CatalogInput;
};


/** mutation root */
export type Mutation_RootCreateComparatorRivalArgs = {
  data: ComparatorRivalInput;
};


/** mutation root */
export type Mutation_RootCreateContentArgs = {
  data: ContentInput;
};


/** mutation root */
export type Mutation_RootCreateEmailTemplaterLayoutArgs = {
  data: EmailTemplaterLayoutInput;
};


/** mutation root */
export type Mutation_RootCreateEmailTemplaterTemplateArgs = {
  data: EmailTemplaterTemplateInput;
};


/** mutation root */
export type Mutation_RootCreateFaqArgs = {
  data: FaqInput;
};


/** mutation root */
export type Mutation_RootCreateLaptopFamilyArgs = {
  data: LaptopFamilyInput;
};


/** mutation root */
export type Mutation_RootCreateLaptopSubscriptionArgs = {
  data: LaptopSubscriptionInput;
};


/** mutation root */
export type Mutation_RootCreateLaptopVariantArgs = {
  data: LaptopVariantInput;
};


/** mutation root */
export type Mutation_RootCreatePhonePlanSubscriptionArgs = {
  data: PhonePlanSubscriptionInput;
};


/** mutation root */
export type Mutation_RootCreateReviewArgs = {
  data: ReviewInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateReviewLocalizationArgs = {
  data?: Maybe<ReviewInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSeoPropertyArgs = {
  data: SeoPropertyInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSeoPropertyLocalizationArgs = {
  data?: Maybe<SeoPropertyInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSmartphoneFamilyArgs = {
  data: SmartphoneFamilyInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSmartphoneFamilyLocalizationArgs = {
  data?: Maybe<SmartphoneFamilyInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSmartphoneSubscriptionArgs = {
  data: SmartphoneSubscriptionInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSmartphoneSubscriptionLocalizationArgs = {
  data?: Maybe<SmartphoneSubscriptionInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSmartphoneVariantArgs = {
  data: SmartphoneVariantInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateSmartphoneVariantLocalizationArgs = {
  data?: Maybe<SmartphoneVariantInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateTabletFamilyArgs = {
  data: TabletFamilyInput;
};


/** mutation root */
export type Mutation_RootCreateTabletSubscriptionArgs = {
  data: TabletSubscriptionInput;
};


/** mutation root */
export type Mutation_RootCreateTabletVariantArgs = {
  data: TabletVariantInput;
};


/** mutation root */
export type Mutation_RootCreateTagArgs = {
  data: TagInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateTagLocalizationArgs = {
  data?: Maybe<TagInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateTranslationArgs = {
  data: TranslationInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateTranslationLocalizationArgs = {
  data?: Maybe<TranslationInput>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootCreateUploadFileArgs = {
  data: UploadFileInput;
};


/** mutation root */
export type Mutation_RootCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


/** mutation root */
export type Mutation_RootCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


/** mutation root */
export type Mutation_RootCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


/** mutation root */
export type Mutation_RootCustomerAccount_UpdateInsuranceTicketArgs = {
  incidentDate?: Maybe<Scalars['DateTime']>;
  incidentDescription?: Maybe<Scalars['String']>;
  insuranceTicketId: Scalars['Int'];
  swapRequested?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootCustomerAccount_AddBankDetailArgs = {
  accountHolderName: Scalars['String'];
  iban: Scalars['String'];
  ownershipProofId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootCustomerAccount_AddCardArgs = {
  pspInput: Scalars['JSONObject'];
};


/** mutation root */
export type Mutation_RootCustomerAccount_AddOwnershipProofArgs = {
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootCustomerAccount_DeleteCardArgs = {
  card: DeleteCardCard;
};


/** mutation root */
export type Mutation_RootCustomerAccount_PayInsuranceTicketArgs = {
  insuranceTicketId: Scalars['Int'];
  pspInput: Scalars['JSONObject'];
  returnUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCustomerAccount_PayUnpaidInvoicesArgs = {
  args: PaySelectedInvoicesArgs;
};


/** mutation root */
export type Mutation_RootCustomerAccount_PlaceInsuranceTicketArgs = {
  insuranceTicketId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCustomer_MarkAddressAsDeliverableArgs = {
  customerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCustomer_MarkAddressAsUndeliverableArgs = {
  customerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAccessoryArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteB2CSmartphoneCatalogArgs = {
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteCatalogArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteComparatorRivalArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteContentArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteEmailTemplaterLayoutArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteEmailTemplaterTemplateArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteFaqArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteLaptopFamilyArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteLaptopSubscriptionArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteLaptopVariantArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeletePhonePlanSubscriptionArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteReviewArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteSeoPropertyArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteSmartphoneFamilyArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteSmartphoneSubscriptionArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteSmartphoneVariantArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteTabletFamilyArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteTabletSubscriptionArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteTabletVariantArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteTagArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteTranslationArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootDelete_B2b_Users_OrdersArgs = {
  where: B2b_Users_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_B2b_Users_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_B2b_Users_OrdersArgs = {
  objects: Array<B2b_Users_Orders_Insert_Input>;
  on_conflict?: Maybe<B2b_Users_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_B2b_Users_Orders_OneArgs = {
  object: B2b_Users_Orders_Insert_Input;
  on_conflict?: Maybe<B2b_Users_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_RemindersArgs = {
  objects: Array<Customer_Reminders_Insert_Input>;
  on_conflict?: Maybe<Customer_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Experiments_AllocationsArgs = {
  objects: Array<Experiments_Allocations_Insert_Input>;
  on_conflict?: Maybe<Experiments_Allocations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Experiments_Allocations_OneArgs = {
  object: Experiments_Allocations_Insert_Input;
  on_conflict?: Maybe<Experiments_Allocations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsurance_DeclareDamagesArgs = {
  DeclareDamagesArgs: DeclareDamagesArgs;
};


/** mutation root */
export type Mutation_RootInvoice_RefundPartialArgs = {
  invoiceId: Scalars['Int'];
  itemsPartialAmounts: Array<ItemsPartialAmounts>;
};


/** mutation root */
export type Mutation_RootInvoice_RefundTotalArgs = {
  invoiceId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootKyc_KycValidateArgs = {
  estimatedIncomeDay: Scalars['Int'];
  kycId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootKyc_RevokeKycSignatureArgs = {
  customerId: Scalars['Int'];
  skipSendingSignatureMail: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootKyc_TrackBankConnectionArgs = {
  connectionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootKyc_FinishKycArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootKyc_UpdateStatusAndIncomeArgs = {
  updateStatusAndIncomeArgs: UpdateStatusAndIncomeArgs;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  input: UsersPermissionsLoginInput;
};


/** mutation root */
export type Mutation_RootLogistic_PrintPreparationNoteArgs = {
  orderIds: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootMultipleUploadArgs = {
  field?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
};


/** mutation root */
export type Mutation_RootNest_AdminAddAccessoriesIntoStockMovementParcelArgs = {
  parcelId: Scalars['Int'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminAddCustomerBankDetailsArgs = {
  accountHolderName: Scalars['String'];
  customerId: Scalars['Int'];
  iban: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminAddDeviceIntoStockMovementParcelArgs = {
  parcelId: Scalars['Int'];
  serial: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminAddOrderItemsArgs = {
  orderId: Scalars['Int'];
  skus: Array<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootNest_AdminAddSupplierOrderToParcelArgs = {
  file: Scalars['Upload'];
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminAssignOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminAssignOrderItemIccidArgs = {
  iccid: Scalars['String'];
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminCancelPaymentArgs = {
  paymentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminChangeOrderItemColorArgs = {
  color: Scalars['String'];
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminChargeB2BInvoiceGroupArgs = {
  invoiceGroupId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminChargeInvoiceArgs = {
  invoiceId: Scalars['Int'];
  partialAmount?: Maybe<Scalars['Int']>;
  paymentSource?: Maybe<InvoicePaymentSourceInput>;
};


/** mutation root */
export type Mutation_RootNest_AdminChargeManyInvoicesArgs = {
  invoiceIds: Array<Scalars['Int']>;
  paymentSource?: Maybe<InvoicePaymentSourceInput>;
};


/** mutation root */
export type Mutation_RootNest_AdminCloseParcelArgs = {
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminCloseStockMovementParcelArgs = {
  gateway: ParcelGateway;
  parcelId: Scalars['Int'];
  tracking?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootNest_AdminConvertToCompliantArgs = {
  orderItemId: Scalars['Int'];
  pictureFileIds?: Maybe<Array<Scalars['Int']>>;
  repairFeesIds?: Maybe<Array<Scalars['Int']>>;
  tametRepairCosts?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootNest_AdminCreateB2BAccountArgs = {
  billingAddress: OrderAddressInput;
  ceoId: Array<Scalars['Upload']>;
  companyDetails: B2BCompanyDetails;
  customer: B2BCustomerInput;
  kbis: Scalars['Upload'];
  shippingAddress: OrderAddressInput;
};


/** mutation root */
export type Mutation_RootNest_AdminCreateB2BOrderBatchArgs = {
  input: B2BNewOrderBatchInput;
};


/** mutation root */
export type Mutation_RootNest_AdminCreateDraftStockMovementParcelArgs = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminCreateInvoiceArgs = {
  body: CreateInvoiceInput;
};


/** mutation root */
export type Mutation_RootNest_AdminCreateParcelArgs = {
  parcelInput: CreateParcelInput;
};


/** mutation root */
export type Mutation_RootNest_AdminDeleteOrderItemArgs = {
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminDestroyDraftOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminDestroyDraftStockMovementParcelArgs = {
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminDestroyParcelArgs = {
  parcelId: Scalars['Int'];
  withRollback: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootNest_AdminDowngradeOrderArgs = {
  orderId: Scalars['Int'];
  orderItemId: Scalars['Int'];
  properties: OrderSubscriptionDeviceInputDto;
};


/** mutation root */
export type Mutation_RootNest_AdminForceOrderParcelAsReceivedArgs = {
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminForceStockMovementParcelAsReceivedArgs = {
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminGenerateB2BPurchaseOrderArgs = {
  orderIds: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootNest_AdminGenerateStockMovementNoteArgs = {
  format: StockMovementNoteFormat;
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminGenerateTrackingForOrderArgs = {
  gateway?: Maybe<Scalars['String']>;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminInvoiceCollectionCancelArgs = {
  invoiceCollectionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminInvoiceCollectionCollectArgs = {
  invoiceCollectionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminInvoiceDeviceArgs = {
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminMigrateB2BOrderArgs = {
  b2bCustomerId: Scalars['Int'];
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminProcessReturnParcelArgs = {
  processParcelInput: ProcessParcelInput;
};


/** mutation root */
export type Mutation_RootNest_AdminReAssignOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminRefreshIcloudStatusArgs = {
  stockItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminRejectKycArgs = {
  rejectKycInput: RejectKycInput;
};


/** mutation root */
export type Mutation_RootNest_AdminRemoveOrderFromPreparationArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminRemoveStockItemsFromDraftStockMovementParcelArgs = {
  parcelId: Scalars['Int'];
  stockItemsIds: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootNest_AdminRollbackOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminRollbackOrderItemArgs = {
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminScheduleReturnArgs = {
  orderItemId: Scalars['Int'];
  skipIcloudCheck?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootNest_AdminSendIcloudReturnInstructionsArgs = {
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminSendReturnEmailArgs = {
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTerminateOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransformInsuranceOrderToResignationArgs = {
  comment?: Maybe<Scalars['String']>;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransformInsuranceOrderToSwapArgs = {
  comment?: Maybe<Scalars['String']>;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransformParcelStateArgs = {
  parcelId: Scalars['Int'];
  toState: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransitionInsuranceTicketStateArgs = {
  nextState: Scalars['String'];
  ticketId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransitionOrderArgs = {
  metadata?: Maybe<Scalars['JSONObject']>;
  nextState: OrderStateName;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransitionOrderItemArgs = {
  metadata?: Maybe<Scalars['JSONObject']>;
  nextState: OrderItemStateName;
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTransitionStockItemArgs = {
  nextStateSet: Array<StockItemStateName>;
  stockItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminTriggerKycEmailArgs = {
  kycId: Scalars['Int'];
  trigger: KycEmailTrigger;
};


/** mutation root */
export type Mutation_RootNest_AdminUnAssignOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminUpdateB2BAccountInformationArgs = {
  b2bAccountId: Scalars['Int'];
  kbis: Scalars['Upload'];
  siret: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminUpdateCustomerArgs = {
  customerAttributes: UpdateCustomerInput;
  customerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminUpdateInvoiceArgs = {
  chargeAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['Int'];
  paymentSource?: Maybe<InvoicePaymentSourceInput>;
};


/** mutation root */
export type Mutation_RootNest_AdminUpdateOrderPaymentSourceArgs = {
  orderId: Scalars['Int'];
  paymentSource: OrderPaymentSourceInput;
};


/** mutation root */
export type Mutation_RootNest_AdminUpdateParcelTrackingArgs = {
  gateway: ParcelGateway;
  parcelId: Scalars['Int'];
  tracking: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminUpdateUserStockAssignationArgs = {
  stockIds: Array<Scalars['Int']>;
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminUploadKycFileArgs = {
  file: Scalars['Upload'];
  kycFileName: KycFileName;
  kycId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_AdminUpsertUserArgs = {
  email: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_AdminValidateB2BDraftOrdersArgs = {
  orderIds: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootNest_AskResetCustomerPasswordArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_B2b_UpdateAccountDefaultBusinessEntityArgs = {
  b2bAccountId: Scalars['Int'];
  businessEntityId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_B2b_UpdateB2bAccountAddressArgs = {
  address: B2BAddressInput;
  addressType: Scalars['String'];
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_B2b_UpdateB2bAccountNameArgs = {
  businessName: Scalars['String'];
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_BusinessConfirmDraftOrdersArgs = {
  orderIds: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootNest_BusinessCreateDraftOrderArgs = {
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_BusinessOrderAssignArgs = {
  orderId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_BusinessOrderUnassignArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_BusinessUpdateDraftOrdersArgs = {
  orders: Array<BusinessUpdateDraftOrdersDto>;
};


/** mutation root */
export type Mutation_RootNest_ConfirmToPurchaseArgs = {
  input: ToPurchaseConfirmationInput;
};


/** mutation root */
export type Mutation_RootNest_CreateB2BUserArgs = {
  b2bUser: B2BUserCreateDto;
};


/** mutation root */
export type Mutation_RootNest_CreateCustomerReminderArgs = {
  customerReminder: CreateCustomerReminderInput;
};


/** mutation root */
export type Mutation_RootNest_CreateDraftInsuranceTicketArgs = {
  ticket: CreateDraftInsuranceTicketInput;
};


/** mutation root */
export type Mutation_RootNest_CreateDraftOrderArgs = {
  orderAttributes: DraftOrderDto;
};


/** mutation root */
export type Mutation_RootNest_CreateHelpScoutTicketArgs = {
  ticket: CreateHelpScoutTicketInput;
};


/** mutation root */
export type Mutation_RootNest_CreateInsuranceTicketPaymentArgs = {
  executeThreeDSecure: Scalars['Boolean'];
  ticketId: Scalars['Int'];
  tokenId: Scalars['String'];
  userAgent: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_CreatePipedriveDealArgs = {
  deal: CreatePipedriveDealInput;
};


/** mutation root */
export type Mutation_RootNest_CreateWarrantyTicketArgs = {
  breakdownSubcategory: WarrantyTicketCategory;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['Upload']>>;
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_CustomerAddBankDetailsArgs = {
  accountHolderName: Scalars['String'];
  iban: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_CustomerCreateUbbleKycArgs = {
  redirectUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_CustomerLookupBankDetailsArgs = {
  iban: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_CustomerSetPasswordArgs = {
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_CustomerUpdateShippingGatewayArgs = {
  accessPoint?: Maybe<UpsAccessPoint>;
  orderId: Scalars['Int'];
  shippingGateway: ShippingGateway;
};


/** mutation root */
export type Mutation_RootNest_CustomerUploadBankStatementArgs = {
  file: Scalars['Upload'];
  kycId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_DeleteB2BUserArgs = {
  b2bUserId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_DeleteFileArgs = {
  fileId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_DeletePurchasesArgs = {
  input: ToAssignDeletePurchases;
};


/** mutation root */
export type Mutation_RootNest_DestroyCustomerDraftOrderArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_FinalizeInsuranceTicketPaymentArgs = {
  paymentId: Scalars['Int'];
  ticketId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_LoginCustomerArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_NewsletterSubscribeArgs = {
  mail: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_OrderApplyDiscountCodeArgs = {
  code: Scalars['String'];
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_OrderSwap_CreateSwapArgs = {
  orderId: Scalars['Int'];
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_OrderSwap_DisableSwapArgs = {
  durationInDays?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_OrderSwap_EnableSwapArgs = {
  durationInDays?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_OrderSwap_SwapArgs = {
  orderId: Scalars['Int'];
  reversed: Scalars['Boolean'];
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_ReGenerateTrackingForIncomingParcelArgs = {
  parcelId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_RequestPhoneLineActivationArgs = {
  activationRequest: ActivationRequestInput;
};


/** mutation root */
export type Mutation_RootNest_ResetCustomerPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_UpdateB2BUserArgs = {
  b2bUser: B2BUserUpdateDto;
  b2bUserId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_UpdateCustomerArgs = {
  customerAttributes: UpdateCustomerInput;
};


/** mutation root */
export type Mutation_RootNest_UpdateCustomerBillingAddressArgs = {
  customerBillingAddress: UpdateCustomerBillingAddressInput;
};


/** mutation root */
export type Mutation_RootNest_UpdateCustomerDraftOrderArgs = {
  itemSkus?: Maybe<Array<Scalars['String']>>;
  orderAttributes?: Maybe<UpdateDraftOrderAttributes>;
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_UpdateCustomerShippingAddressArgs = {
  customerShippingAddress: UpdateCustomerShippingAddressInput;
};


/** mutation root */
export type Mutation_RootNest_UpdateDraftOrderOptionalAccessoriesNeedsArgs = {
  orderId: Scalars['Int'];
  skipCatalogItems: Array<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootNest_UpdatePaymentSourcesToBankDetailsIdArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNest_UpdatePaymentSourcesToTokenIdArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_UpdatePurchasedInfosArgs = {
  input: ToAssignUpdateInfosInput;
};


/** mutation root */
export type Mutation_RootNest_UploadFileArgs = {
  file: Scalars['Upload'];
  resourceId: Scalars['Int'];
  resourceType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootNest_VerifyIcloudStatusArgs = {
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrderManagement_CancelArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrderManagement_RequestCancelationArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrder_Subscription_ChangeOrderSubscriptionArgs = {
  keepCustomizations?: Maybe<Scalars['Boolean']>;
  orderSubscriptionId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrder_Subscription_CustomizeOrderSubscriptionArgs = {
  commitmentPeriodInMonths: Scalars['Int'];
  orderSubscriptionId: Scalars['Int'];
  pricePerMonth: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrder_Subscription_DisableOrderSubscriptionAutomaticRenewalArgs = {
  orderSubscriptionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrder_Subscription_EnableOrderSubscriptionAutomaticRenewalArgs = {
  orderSubscriptionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrdering_CheckoutArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootOrdering_PayOrderArgs = {
  orderId: Scalars['Int'];
  pspInput: Scalars['JSONObject'];
  returnUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootOrdering_RemoveCouponArgs = {
  orderId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootPayment_Authorize3dsPaymentArgs = {
  pspInput: Scalars['JSONObject'];
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


/** mutation root */
export type Mutation_RootRemoveFileArgs = {
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


/** mutation root */
export type Mutation_RootReturn_ConvertBnpToCompliantArgs = {
  decision: BnpReturnDecisionOptions;
  orderItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSignature_CreatePhonePlanContractArgs = {
  customerId: Scalars['Int'];
  idFiles: Array<Scalars['Upload']>;
  signatory: SignatoryInput;
};


/** mutation root */
export type Mutation_RootSignature_DeletePhonePlanContractArgs = {
  customerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootStocks_RollbackStockItemArgs = {
  stockItemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdateAccessoryArgs = {
  data: AccessoryInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateB2BLaptopCatalogArgs = {
  data: B2BLaptopCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdateB2BPhonePlanCatalogArgs = {
  data: B2BPhonePlanCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdateB2BSmartphoneCatalogArgs = {
  data: B2BSmartphoneCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdateB2BTabletCatalogArgs = {
  data: B2BTabletCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdateB2CSmartphoneBestSellersCatalogArgs = {
  data: B2CSmartphoneBestSellersCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdateB2CSmartphoneCatalogArgs = {
  data: B2CSmartphoneCatalogInput;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateB2CSmartphoneOthersCatalogArgs = {
  data: B2CSmartphoneOthersCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdateCatalogArgs = {
  data: CatalogInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateCguArgs = {
  data: CguInput;
};


/** mutation root */
export type Mutation_RootUpdateComparatorRivalArgs = {
  data: ComparatorRivalInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateContentArgs = {
  data: ContentInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateEmailTemplaterLayoutArgs = {
  data: EmailTemplaterLayoutInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateEmailTemplaterTemplateArgs = {
  data: EmailTemplaterTemplateInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateFaqArgs = {
  data: FaqInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: Maybe<FileInfoInput>;
};


/** mutation root */
export type Mutation_RootUpdateLaptopFamilyArgs = {
  data: LaptopFamilyInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateLaptopSubscriptionArgs = {
  data: LaptopSubscriptionInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateLaptopVariantArgs = {
  data: LaptopVariantInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateLpmSalesFunnelCatalogArgs = {
  data: LpmSalesFunnelCatalogInput;
};


/** mutation root */
export type Mutation_RootUpdatePhonePlanSubscriptionArgs = {
  data: PhonePlanSubscriptionInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdatePrivacyArgs = {
  data: PrivacyInput;
};


/** mutation root */
export type Mutation_RootUpdateReviewArgs = {
  data: ReviewInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateSeoPropertyArgs = {
  data: SeoPropertyInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateSmartphoneFamilyArgs = {
  data: SmartphoneFamilyInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateSmartphoneSubscriptionArgs = {
  data: SmartphoneSubscriptionInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateSmartphoneVariantArgs = {
  data: SmartphoneVariantInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateTabletFamilyArgs = {
  data: TabletFamilyInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateTabletSubscriptionArgs = {
  data: TabletSubscriptionInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateTabletVariantArgs = {
  data: TabletVariantInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateTagArgs = {
  data: TagInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateTranslationArgs = {
  data: TranslationInput;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


/** mutation root */
export type Mutation_RootUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootUpdateWebsiteSettingArgs = {
  data: WebsiteSettingInput;
};


/** mutation root */
export type Mutation_RootUpdate_B2b_UsersArgs = {
  _set?: Maybe<B2b_Users_Set_Input>;
  where: B2b_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_B2b_Users_By_PkArgs = {
  _set?: Maybe<B2b_Users_Set_Input>;
  pk_columns: B2b_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_B2b_Users_ManyArgs = {
  updates: Array<B2b_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_B2b_Users_OrdersArgs = {
  _inc?: Maybe<B2b_Users_Orders_Inc_Input>;
  _set?: Maybe<B2b_Users_Orders_Set_Input>;
  where: B2b_Users_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_B2b_Users_Orders_By_PkArgs = {
  _inc?: Maybe<B2b_Users_Orders_Inc_Input>;
  _set?: Maybe<B2b_Users_Orders_Set_Input>;
  pk_columns: B2b_Users_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_B2b_Users_Orders_ManyArgs = {
  updates: Array<B2b_Users_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_RemindersArgs = {
  _inc?: Maybe<Customer_Reminders_Inc_Input>;
  _set?: Maybe<Customer_Reminders_Set_Input>;
  where: Customer_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Reminders_ManyArgs = {
  updates: Array<Customer_Reminders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_TicketsArgs = {
  _set?: Maybe<Insurance_Tickets_Set_Input>;
  where: Insurance_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Tickets_By_PkArgs = {
  _set?: Maybe<Insurance_Tickets_Set_Input>;
  pk_columns: Insurance_Tickets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Tickets_ManyArgs = {
  updates: Array<Insurance_Tickets_Updates>;
};


/** mutation root */
export type Mutation_RootUploadArgs = {
  field?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: Maybe<FileInfoInput>;
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
};


/** mutation root */
export type Mutation_RootUserManagement_CreateNewUserArgs = {
  email: Scalars['String'];
  permissionNames: Array<Maybe<Scalars['String']>>;
  role: UserRole;
};


/** mutation root */
export type Mutation_RootUserManagement_SetRoleAndPermissionsArgs = {
  permissionNames: Array<Maybe<Scalars['String']>>;
  role: UserRole;
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUserManagement_ToggleUserStatusArgs = {
  isActive: Scalars['Boolean'];
  userId: Scalars['Int'];
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "open_banking_connections" */
export type Open_Banking_Connections = {
  __typename?: 'open_banking_connections';
  connection_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "open_banking_connections". All fields are combined with a logical 'AND'. */
export type Open_Banking_Connections_Bool_Exp = {
  _and?: Maybe<Array<Open_Banking_Connections_Bool_Exp>>;
  _not?: Maybe<Open_Banking_Connections_Bool_Exp>;
  _or?: Maybe<Array<Open_Banking_Connections_Bool_Exp>>;
  connection_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "open_banking_connections". */
export type Open_Banking_Connections_Order_By = {
  connection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "open_banking_connections" */
export enum Open_Banking_Connections_Select_Column {
  /** column name */
  ConnectionId = 'connection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "open_banking_connections" */
export type Open_Banking_Connections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Open_Banking_Connections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Open_Banking_Connections_Stream_Cursor_Value_Input = {
  connection_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_files" */
export type Order_Files = {
  __typename?: 'order_files';
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order?: Maybe<Orders>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "order_files" */
export type Order_Files_Aggregate_Order_By = {
  avg?: Maybe<Order_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Files_Max_Order_By>;
  min?: Maybe<Order_Files_Min_Order_By>;
  stddev?: Maybe<Order_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Files_Sum_Order_By>;
  var_pop?: Maybe<Order_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Files_Var_Samp_Order_By>;
  variance?: Maybe<Order_Files_Variance_Order_By>;
};

/** order by avg() on columns of table "order_files" */
export type Order_Files_Avg_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_files". All fields are combined with a logical 'AND'. */
export type Order_Files_Bool_Exp = {
  _and?: Maybe<Array<Order_Files_Bool_Exp>>;
  _not?: Maybe<Order_Files_Bool_Exp>;
  _or?: Maybe<Array<Order_Files_Bool_Exp>>;
  content_length?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  resource_type?: Maybe<String_Comparison_Exp>;
  storage?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "order_files" */
export type Order_Files_Max_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_files" */
export type Order_Files_Min_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_files". */
export type Order_Files_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "order_files" */
export enum Order_Files_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Storage = 'storage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** order by stddev() on columns of table "order_files" */
export type Order_Files_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_files" */
export type Order_Files_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_files" */
export type Order_Files_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "order_files" */
export type Order_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Files_Stream_Cursor_Value_Input = {
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "order_files" */
export type Order_Files_Sum_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "order_files" */
export type Order_Files_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_files" */
export type Order_Files_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_files" */
export type Order_Files_Variance_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** columns and relationships of "order_item_files" */
export type Order_Item_Files = {
  __typename?: 'order_item_files';
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order_item?: Maybe<Order_Items>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "order_item_files" */
export type Order_Item_Files_Aggregate_Order_By = {
  avg?: Maybe<Order_Item_Files_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Item_Files_Max_Order_By>;
  min?: Maybe<Order_Item_Files_Min_Order_By>;
  stddev?: Maybe<Order_Item_Files_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Item_Files_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Item_Files_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Item_Files_Sum_Order_By>;
  var_pop?: Maybe<Order_Item_Files_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Item_Files_Var_Samp_Order_By>;
  variance?: Maybe<Order_Item_Files_Variance_Order_By>;
};

/** order by avg() on columns of table "order_item_files" */
export type Order_Item_Files_Avg_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_item_files". All fields are combined with a logical 'AND'. */
export type Order_Item_Files_Bool_Exp = {
  _and?: Maybe<Array<Order_Item_Files_Bool_Exp>>;
  _not?: Maybe<Order_Item_Files_Bool_Exp>;
  _or?: Maybe<Array<Order_Item_Files_Bool_Exp>>;
  content_length?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order_item?: Maybe<Order_Items_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  resource_type?: Maybe<String_Comparison_Exp>;
  storage?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "order_item_files" */
export type Order_Item_Files_Max_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_item_files" */
export type Order_Item_Files_Min_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_item_files". */
export type Order_Item_Files_Order_By = {
  content_length?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item?: Maybe<Order_Items_Order_By>;
  reference?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_type?: Maybe<Order_By>;
  storage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "order_item_files" */
export enum Order_Item_Files_Select_Column {
  /** column name */
  ContentLength = 'content_length',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Storage = 'storage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** order by stddev() on columns of table "order_item_files" */
export type Order_Item_Files_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_item_files" */
export type Order_Item_Files_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_item_files" */
export type Order_Item_Files_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "order_item_files" */
export type Order_Item_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Item_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Item_Files_Stream_Cursor_Value_Input = {
  content_length?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  resource_type?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "order_item_files" */
export type Order_Item_Files_Sum_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "order_item_files" */
export type Order_Item_Files_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_item_files" */
export type Order_Item_Files_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_item_files" */
export type Order_Item_Files_Variance_Order_By = {
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** columns and relationships of "order_item_states" */
export type Order_Item_States = {
  __typename?: 'order_item_states';
  created_at: Scalars['timestamptz'];
  from_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  /** An object relationship */
  order_item: Order_Items;
  resource_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "order_item_states" */
export type Order_Item_StatesMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "order_item_states" */
export type Order_Item_States_Aggregate_Order_By = {
  avg?: Maybe<Order_Item_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Item_States_Max_Order_By>;
  min?: Maybe<Order_Item_States_Min_Order_By>;
  stddev?: Maybe<Order_Item_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Item_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Item_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Item_States_Sum_Order_By>;
  var_pop?: Maybe<Order_Item_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Item_States_Var_Samp_Order_By>;
  variance?: Maybe<Order_Item_States_Variance_Order_By>;
};

/** order by avg() on columns of table "order_item_states" */
export type Order_Item_States_Avg_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_item_states". All fields are combined with a logical 'AND'. */
export type Order_Item_States_Bool_Exp = {
  _and?: Maybe<Array<Order_Item_States_Bool_Exp>>;
  _not?: Maybe<Order_Item_States_Bool_Exp>;
  _or?: Maybe<Array<Order_Item_States_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_item?: Maybe<Order_Items_Bool_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "order_item_states" */
export type Order_Item_States_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_item_states" */
export type Order_Item_States_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_item_states". */
export type Order_Item_States_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_item?: Maybe<Order_Items_Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "order_item_states" */
export enum Order_Item_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromId = 'from_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "order_item_states" */
export type Order_Item_States_Stddev_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_item_states" */
export type Order_Item_States_Stddev_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_item_states" */
export type Order_Item_States_Stddev_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "order_item_states" */
export type Order_Item_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Item_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Item_States_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_item_states" */
export type Order_Item_States_Sum_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "order_item_states" */
export type Order_Item_States_Var_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_item_states" */
export type Order_Item_States_Var_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_item_states" */
export type Order_Item_States_Variance_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "order_items" */
export type Order_Items = {
  __typename?: 'order_items';
  buying_price_in_cents_excl_tax: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  current_state?: Maybe<Order_Item_States>;
  /** An array relationship */
  files: Array<Order_Item_Files>;
  id: Scalars['Int'];
  /** An array relationship */
  insurance_tickets: Array<Insurance_Tickets>;
  /** An object relationship */
  invoice?: Maybe<Invoices>;
  /** An object relationship */
  item: Items;
  metadata: Scalars['jsonb'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  /** An object relationship */
  parent_order_item?: Maybe<Order_Items>;
  /** An array relationship */
  refund_order_items: Array<Order_Items>;
  /** An aggregate relationship */
  refund_order_items_aggregate: Order_Items_Aggregate;
  /** An array relationship */
  states: Array<Order_Item_States>;
  /** An object relationship */
  stock_item?: Maybe<Stock_Items>;
  /** An object relationship */
  tax?: Maybe<Taxes>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "order_items" */
export type Order_ItemsFilesArgs = {
  distinct_on?: Maybe<Array<Order_Item_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Files_Order_By>>;
  where?: Maybe<Order_Item_Files_Bool_Exp>;
};


/** columns and relationships of "order_items" */
export type Order_ItemsInsurance_TicketsArgs = {
  distinct_on?: Maybe<Array<Insurance_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Tickets_Order_By>>;
  where?: Maybe<Insurance_Tickets_Bool_Exp>;
};


/** columns and relationships of "order_items" */
export type Order_ItemsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "order_items" */
export type Order_ItemsRefund_Order_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "order_items" */
export type Order_ItemsRefund_Order_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "order_items" */
export type Order_ItemsStatesArgs = {
  distinct_on?: Maybe<Array<Order_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_States_Order_By>>;
  where?: Maybe<Order_Item_States_Bool_Exp>;
};

/** aggregated selection of "order_items" */
export type Order_Items_Aggregate = {
  __typename?: 'order_items_aggregate';
  aggregate?: Maybe<Order_Items_Aggregate_Fields>;
  nodes: Array<Order_Items>;
};

export type Order_Items_Aggregate_Bool_Exp = {
  count?: Maybe<Order_Items_Aggregate_Bool_Exp_Count>;
};

export type Order_Items_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Order_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Order_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_items" */
export type Order_Items_Aggregate_Fields = {
  __typename?: 'order_items_aggregate_fields';
  avg?: Maybe<Order_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Items_Max_Fields>;
  min?: Maybe<Order_Items_Min_Fields>;
  stddev?: Maybe<Order_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Items_Sum_Fields>;
  var_pop?: Maybe<Order_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Items_Var_Samp_Fields>;
  variance?: Maybe<Order_Items_Variance_Fields>;
};


/** aggregate fields of "order_items" */
export type Order_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_items" */
export type Order_Items_Aggregate_Order_By = {
  avg?: Maybe<Order_Items_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Items_Max_Order_By>;
  min?: Maybe<Order_Items_Min_Order_By>;
  stddev?: Maybe<Order_Items_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Items_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Items_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Items_Sum_Order_By>;
  var_pop?: Maybe<Order_Items_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Items_Var_Samp_Order_By>;
  variance?: Maybe<Order_Items_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Items_Avg_Fields = {
  __typename?: 'order_items_avg_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_items" */
export type Order_Items_Avg_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_items". All fields are combined with a logical 'AND'. */
export type Order_Items_Bool_Exp = {
  _and?: Maybe<Array<Order_Items_Bool_Exp>>;
  _not?: Maybe<Order_Items_Bool_Exp>;
  _or?: Maybe<Array<Order_Items_Bool_Exp>>;
  buying_price_in_cents_excl_tax?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_state?: Maybe<Order_Item_States_Bool_Exp>;
  files?: Maybe<Order_Item_Files_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  insurance_tickets?: Maybe<Insurance_Tickets_Bool_Exp>;
  invoice?: Maybe<Invoices_Bool_Exp>;
  item?: Maybe<Items_Bool_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  parent_order_item?: Maybe<Order_Items_Bool_Exp>;
  refund_order_items?: Maybe<Order_Items_Bool_Exp>;
  refund_order_items_aggregate?: Maybe<Order_Items_Aggregate_Bool_Exp>;
  states?: Maybe<Order_Item_States_Bool_Exp>;
  stock_item?: Maybe<Stock_Items_Bool_Exp>;
  tax?: Maybe<Taxes_Bool_Exp>;
  tax_amount_in_cents?: Maybe<Int_Comparison_Exp>;
  tax_base?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Items_Max_Fields = {
  __typename?: 'order_items_max_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "order_items" */
export type Order_Items_Max_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Items_Min_Fields = {
  __typename?: 'order_items_min_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "order_items" */
export type Order_Items_Min_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_items". */
export type Order_Items_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_state?: Maybe<Order_Item_States_Order_By>;
  files_aggregate?: Maybe<Order_Item_Files_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  insurance_tickets_aggregate?: Maybe<Insurance_Tickets_Aggregate_Order_By>;
  invoice?: Maybe<Invoices_Order_By>;
  item?: Maybe<Items_Order_By>;
  metadata?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  parent_order_item?: Maybe<Order_Items_Order_By>;
  refund_order_items_aggregate?: Maybe<Order_Items_Aggregate_Order_By>;
  states_aggregate?: Maybe<Order_Item_States_Aggregate_Order_By>;
  stock_item?: Maybe<Stock_Items_Order_By>;
  tax?: Maybe<Taxes_Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** select columns of table "order_items" */
export enum Order_Items_Select_Column {
  /** column name */
  BuyingPriceInCentsExclTax = 'buying_price_in_cents_excl_tax',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  TaxAmountInCents = 'tax_amount_in_cents',
  /** column name */
  TaxBase = 'tax_base'
}

/** aggregate stddev on columns */
export type Order_Items_Stddev_Fields = {
  __typename?: 'order_items_stddev_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_items" */
export type Order_Items_Stddev_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Items_Stddev_Pop_Fields = {
  __typename?: 'order_items_stddev_pop_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_items" */
export type Order_Items_Stddev_Pop_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Items_Stddev_Samp_Fields = {
  __typename?: 'order_items_stddev_samp_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_items" */
export type Order_Items_Stddev_Samp_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** Streaming cursor of the table "order_items" */
export type Order_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Items_Stream_Cursor_Value_Input = {
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  order_id?: Maybe<Scalars['Int']>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Order_Items_Sum_Fields = {
  __typename?: 'order_items_sum_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order_items" */
export type Order_Items_Sum_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Items_Var_Pop_Fields = {
  __typename?: 'order_items_var_pop_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_items" */
export type Order_Items_Var_Pop_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Items_Var_Samp_Fields = {
  __typename?: 'order_items_var_samp_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_items" */
export type Order_Items_Var_Samp_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Items_Variance_Fields = {
  __typename?: 'order_items_variance_fields';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  tax_amount_in_cents?: Maybe<Scalars['Float']>;
  tax_base?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_items" */
export type Order_Items_Variance_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** columns and relationships of "order_parcels" */
export type Order_Parcels = {
  __typename?: 'order_parcels';
  gateway?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracking?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "order_parcels" */
export type Order_Parcels_Aggregate_Order_By = {
  avg?: Maybe<Order_Parcels_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Parcels_Max_Order_By>;
  min?: Maybe<Order_Parcels_Min_Order_By>;
  stddev?: Maybe<Order_Parcels_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Parcels_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Parcels_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Parcels_Sum_Order_By>;
  var_pop?: Maybe<Order_Parcels_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Parcels_Var_Samp_Order_By>;
  variance?: Maybe<Order_Parcels_Variance_Order_By>;
};

/** order by avg() on columns of table "order_parcels" */
export type Order_Parcels_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_parcels". All fields are combined with a logical 'AND'. */
export type Order_Parcels_Bool_Exp = {
  _and?: Maybe<Array<Order_Parcels_Bool_Exp>>;
  _not?: Maybe<Order_Parcels_Bool_Exp>;
  _or?: Maybe<Array<Order_Parcels_Bool_Exp>>;
  gateway?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  tracking?: Maybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "order_parcels" */
export type Order_Parcels_Max_Order_By = {
  gateway?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tracking?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_parcels" */
export type Order_Parcels_Min_Order_By = {
  gateway?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tracking?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_parcels". */
export type Order_Parcels_Order_By = {
  gateway?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tracking?: Maybe<Order_By>;
};

/** select columns of table "order_parcels" */
export enum Order_Parcels_Select_Column {
  /** column name */
  Gateway = 'gateway',
  /** column name */
  Id = 'id',
  /** column name */
  Tracking = 'tracking'
}

/** order by stddev() on columns of table "order_parcels" */
export type Order_Parcels_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_parcels" */
export type Order_Parcels_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_parcels" */
export type Order_Parcels_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "order_parcels" */
export type Order_Parcels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Parcels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Parcels_Stream_Cursor_Value_Input = {
  gateway?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracking?: Maybe<Scalars['String']>;
};

/** order by sum() on columns of table "order_parcels" */
export type Order_Parcels_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "order_parcels" */
export type Order_Parcels_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_parcels" */
export type Order_Parcels_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_parcels" */
export type Order_Parcels_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "order_states" */
export type Order_States = {
  __typename?: 'order_states';
  created_at: Scalars['timestamptz'];
  from_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  from_state?: Maybe<Order_States>;
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  /** An object relationship */
  order: Orders;
  resource_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "order_states" */
export type Order_StatesMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "order_states" */
export type Order_States_Aggregate_Order_By = {
  avg?: Maybe<Order_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_States_Max_Order_By>;
  min?: Maybe<Order_States_Min_Order_By>;
  stddev?: Maybe<Order_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_States_Sum_Order_By>;
  var_pop?: Maybe<Order_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_States_Var_Samp_Order_By>;
  variance?: Maybe<Order_States_Variance_Order_By>;
};

/** order by avg() on columns of table "order_states" */
export type Order_States_Avg_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_states". All fields are combined with a logical 'AND'. */
export type Order_States_Bool_Exp = {
  _and?: Maybe<Array<Order_States_Bool_Exp>>;
  _not?: Maybe<Order_States_Bool_Exp>;
  _or?: Maybe<Array<Order_States_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_id?: Maybe<Int_Comparison_Exp>;
  from_state?: Maybe<Order_States_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "order_states" */
export type Order_States_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_states" */
export type Order_States_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_states". */
export type Order_States_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  from_state?: Maybe<Order_States_Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "order_states" */
export enum Order_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromId = 'from_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "order_states" */
export type Order_States_Stddev_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_states" */
export type Order_States_Stddev_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_states" */
export type Order_States_Stddev_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "order_states" */
export type Order_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_States_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_states" */
export type Order_States_Sum_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "order_states" */
export type Order_States_Var_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_states" */
export type Order_States_Var_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_states" */
export type Order_States_Variance_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "order_subscriptions" */
export type Order_Subscriptions = {
  __typename?: 'order_subscriptions';
  commitment_period_in_months: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  item: Items;
  item_id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  sell_price_in_cents_excl_tax: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "order_subscriptions". All fields are combined with a logical 'AND'. */
export type Order_Subscriptions_Bool_Exp = {
  _and?: Maybe<Array<Order_Subscriptions_Bool_Exp>>;
  _not?: Maybe<Order_Subscriptions_Bool_Exp>;
  _or?: Maybe<Array<Order_Subscriptions_Bool_Exp>>;
  commitment_period_in_months?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  item?: Maybe<Items_Bool_Exp>;
  item_id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  sell_price_in_cents_excl_tax?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "order_subscriptions". */
export type Order_Subscriptions_Order_By = {
  commitment_period_in_months?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item?: Maybe<Items_Order_By>;
  item_id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "order_subscriptions" */
export enum Order_Subscriptions_Select_Column {
  /** column name */
  CommitmentPeriodInMonths = 'commitment_period_in_months',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  SellPriceInCentsExclTax = 'sell_price_in_cents_excl_tax',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "order_subscriptions" */
export type Order_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Subscriptions_Stream_Cursor_Value_Input = {
  commitment_period_in_months?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  item_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  sell_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "orders" */
export type Orders = {
  __typename?: 'orders';
  activated_at?: Maybe<Scalars['timestamptz']>;
  active?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  b2b_users: Array<B2b_Users_Orders>;
  /** An aggregate relationship */
  b2b_users_aggregate: B2b_Users_Orders_Aggregate;
  billing_address: Scalars['jsonb'];
  /** An object relationship */
  child_order?: Maybe<Orders>;
  child_order_id?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  company_name?: Maybe<Scalars['String']>;
  company_registration_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  contracts: Array<Customer_Contracts>;
  /** An object relationship */
  country: Countries;
  country_id: Scalars['Int'];
  /** An array relationship */
  coupon_orders: Array<Coupon_Orders>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  current_state?: Maybe<Order_States>;
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  /** An object relationship */
  device?: Maybe<Orders_Device>;
  /** An array relationship */
  files: Array<Order_Files>;
  /** An array relationship */
  financing_contracts: Array<Financing_Contract>;
  /** An aggregate relationship */
  financing_contracts_aggregate: Financing_Contract_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  incoming_parcels: Array<Order_Parcels>;
  /** An array relationship */
  invoices: Array<Invoices>;
  is_business_order?: Maybe<Scalars['Boolean']>;
  last_billing_at?: Maybe<Scalars['timestamptz']>;
  next_billing_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
  /** An array relationship */
  outgoing_parcels: Array<Order_Parcels>;
  /** An object relationship */
  parent_order?: Maybe<Orders>;
  parent_order_id?: Maybe<Scalars['Int']>;
  payment_source?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  payments: Array<Payments>;
  shipping_address: Scalars['jsonb'];
  /** An array relationship */
  states: Array<Order_States>;
  /** An object relationship */
  subscription?: Maybe<Order_Subscriptions>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "orders" */
export type OrdersB2b_UsersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersB2b_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersBilling_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "orders" */
export type OrdersContractsArgs = {
  distinct_on?: Maybe<Array<Customer_Contracts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contracts_Order_By>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersCoupon_OrdersArgs = {
  distinct_on?: Maybe<Array<Coupon_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Orders_Order_By>>;
  where?: Maybe<Coupon_Orders_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersFilesArgs = {
  distinct_on?: Maybe<Array<Order_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Files_Order_By>>;
  where?: Maybe<Order_Files_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersFinancing_ContractsArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersFinancing_Contracts_AggregateArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersIncoming_ParcelsArgs = {
  distinct_on?: Maybe<Array<Order_Parcels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Parcels_Order_By>>;
  where?: Maybe<Order_Parcels_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersOutgoing_ParcelsArgs = {
  distinct_on?: Maybe<Array<Order_Parcels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Parcels_Order_By>>;
  where?: Maybe<Order_Parcels_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersPayment_SourceArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "orders" */
export type OrdersPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersShipping_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "orders" */
export type OrdersStatesArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  __typename?: 'orders_aggregate';
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

export type Orders_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Orders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Orders_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Orders_Aggregate_Bool_Exp_Count>;
};

export type Orders_Aggregate_Bool_Exp_Bool_And = {
  arguments: Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Orders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Orders_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  __typename?: 'orders_aggregate_fields';
  avg?: Maybe<Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
  stddev?: Maybe<Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Sum_Fields>;
  var_pop?: Maybe<Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Var_Samp_Fields>;
  variance?: Maybe<Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  avg?: Maybe<Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Max_Order_By>;
  min?: Maybe<Orders_Min_Order_By>;
  stddev?: Maybe<Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Sum_Order_By>;
  var_pop?: Maybe<Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Orders_Avg_Fields = {
  __typename?: 'orders_avg_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders" */
export type Orders_Avg_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Orders_Bool_Exp>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Orders_Bool_Exp>>;
  activated_at?: Maybe<Timestamptz_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  b2b_users?: Maybe<B2b_Users_Orders_Bool_Exp>;
  b2b_users_aggregate?: Maybe<B2b_Users_Orders_Aggregate_Bool_Exp>;
  billing_address?: Maybe<Jsonb_Comparison_Exp>;
  child_order?: Maybe<Orders_Bool_Exp>;
  child_order_id?: Maybe<Int_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  company_name?: Maybe<String_Comparison_Exp>;
  company_registration_number?: Maybe<String_Comparison_Exp>;
  contracts?: Maybe<Customer_Contracts_Bool_Exp>;
  country?: Maybe<Countries_Bool_Exp>;
  country_id?: Maybe<Int_Comparison_Exp>;
  coupon_orders?: Maybe<Coupon_Orders_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_state?: Maybe<Order_States_Bool_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  device?: Maybe<Orders_Device_Bool_Exp>;
  files?: Maybe<Order_Files_Bool_Exp>;
  financing_contracts?: Maybe<Financing_Contract_Bool_Exp>;
  financing_contracts_aggregate?: Maybe<Financing_Contract_Aggregate_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  incoming_parcels?: Maybe<Order_Parcels_Bool_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  is_business_order?: Maybe<Boolean_Comparison_Exp>;
  last_billing_at?: Maybe<Timestamptz_Comparison_Exp>;
  next_billing_at?: Maybe<Timestamptz_Comparison_Exp>;
  order_items?: Maybe<Order_Items_Bool_Exp>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Bool_Exp>;
  outgoing_parcels?: Maybe<Order_Parcels_Bool_Exp>;
  parent_order?: Maybe<Orders_Bool_Exp>;
  parent_order_id?: Maybe<Int_Comparison_Exp>;
  payment_source?: Maybe<Jsonb_Comparison_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  shipping_address?: Maybe<Jsonb_Comparison_Exp>;
  states?: Maybe<Order_States_Bool_Exp>;
  subscription?: Maybe<Order_Subscriptions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "orders_device" */
export type Orders_Device = {
  __typename?: 'orders_device';
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  /** An object relationship */
  current_state?: Maybe<Order_Item_States>;
  id?: Maybe<Scalars['Int']>;
  is_giveaway?: Maybe<Scalars['Boolean']>;
  is_physical?: Maybe<Scalars['Boolean']>;
  is_purchasable?: Maybe<Scalars['Boolean']>;
  item_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  order?: Maybe<Orders>;
  order_id?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['jsonb']>;
  sell_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  /** An array relationship */
  states: Array<Order_Item_States>;
  /** An object relationship */
  stock_item?: Maybe<Stock_Items>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "orders_device" */
export type Orders_DevicePropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "orders_device" */
export type Orders_DeviceStatesArgs = {
  distinct_on?: Maybe<Array<Order_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_States_Order_By>>;
  where?: Maybe<Order_Item_States_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "orders_device". All fields are combined with a logical 'AND'. */
export type Orders_Device_Bool_Exp = {
  _and?: Maybe<Array<Orders_Device_Bool_Exp>>;
  _not?: Maybe<Orders_Device_Bool_Exp>;
  _or?: Maybe<Array<Orders_Device_Bool_Exp>>;
  buying_price_in_cents_excl_tax?: Maybe<Int_Comparison_Exp>;
  current_state?: Maybe<Order_Item_States_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_giveaway?: Maybe<Boolean_Comparison_Exp>;
  is_physical?: Maybe<Boolean_Comparison_Exp>;
  is_purchasable?: Maybe<Boolean_Comparison_Exp>;
  item_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  sell_price_in_cents_excl_tax?: Maybe<Int_Comparison_Exp>;
  sku?: Maybe<String_Comparison_Exp>;
  states?: Maybe<Order_Item_States_Bool_Exp>;
  stock_item?: Maybe<Stock_Items_Bool_Exp>;
  tax_amount_in_cents?: Maybe<Int_Comparison_Exp>;
  tax_base?: Maybe<Numeric_Comparison_Exp>;
};

/** Ordering options when selecting data from "orders_device". */
export type Orders_Device_Order_By = {
  buying_price_in_cents_excl_tax?: Maybe<Order_By>;
  current_state?: Maybe<Order_Item_States_Order_By>;
  id?: Maybe<Order_By>;
  is_giveaway?: Maybe<Order_By>;
  is_physical?: Maybe<Order_By>;
  is_purchasable?: Maybe<Order_By>;
  item_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  sell_price_in_cents_excl_tax?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  states_aggregate?: Maybe<Order_Item_States_Aggregate_Order_By>;
  stock_item?: Maybe<Stock_Items_Order_By>;
  tax_amount_in_cents?: Maybe<Order_By>;
  tax_base?: Maybe<Order_By>;
};

/** select columns of table "orders_device" */
export enum Orders_Device_Select_Column {
  /** column name */
  BuyingPriceInCentsExclTax = 'buying_price_in_cents_excl_tax',
  /** column name */
  Id = 'id',
  /** column name */
  IsGiveaway = 'is_giveaway',
  /** column name */
  IsPhysical = 'is_physical',
  /** column name */
  IsPurchasable = 'is_purchasable',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Name = 'name',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Properties = 'properties',
  /** column name */
  SellPriceInCentsExclTax = 'sell_price_in_cents_excl_tax',
  /** column name */
  Sku = 'sku',
  /** column name */
  TaxAmountInCents = 'tax_amount_in_cents',
  /** column name */
  TaxBase = 'tax_base'
}

/** Streaming cursor of the table "orders_device" */
export type Orders_Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Orders_Device_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Device_Stream_Cursor_Value_Input = {
  buying_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_giveaway?: Maybe<Scalars['Boolean']>;
  is_physical?: Maybe<Scalars['Boolean']>;
  is_purchasable?: Maybe<Scalars['Boolean']>;
  item_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['jsonb']>;
  sell_price_in_cents_excl_tax?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  tax_amount_in_cents?: Maybe<Scalars['Int']>;
  tax_base?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  __typename?: 'orders_max_fields';
  activated_at?: Maybe<Scalars['timestamptz']>;
  child_order_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  company_registration_number?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_billing_at?: Maybe<Scalars['timestamptz']>;
  next_billing_at?: Maybe<Scalars['timestamptz']>;
  parent_order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  activated_at?: Maybe<Order_By>;
  child_order_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  company_registration_number?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billing_at?: Maybe<Order_By>;
  next_billing_at?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  __typename?: 'orders_min_fields';
  activated_at?: Maybe<Scalars['timestamptz']>;
  child_order_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  company_registration_number?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_billing_at?: Maybe<Scalars['timestamptz']>;
  next_billing_at?: Maybe<Scalars['timestamptz']>;
  parent_order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  activated_at?: Maybe<Order_By>;
  child_order_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  company_registration_number?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billing_at?: Maybe<Order_By>;
  next_billing_at?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  activated_at?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  b2b_users_aggregate?: Maybe<B2b_Users_Orders_Aggregate_Order_By>;
  billing_address?: Maybe<Order_By>;
  child_order?: Maybe<Orders_Order_By>;
  child_order_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  company_registration_number?: Maybe<Order_By>;
  contracts_aggregate?: Maybe<Customer_Contracts_Aggregate_Order_By>;
  country?: Maybe<Countries_Order_By>;
  country_id?: Maybe<Order_By>;
  coupon_orders_aggregate?: Maybe<Coupon_Orders_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  current_state?: Maybe<Order_States_Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  device?: Maybe<Orders_Device_Order_By>;
  files_aggregate?: Maybe<Order_Files_Aggregate_Order_By>;
  financing_contracts_aggregate?: Maybe<Financing_Contract_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  incoming_parcels_aggregate?: Maybe<Order_Parcels_Aggregate_Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  is_business_order?: Maybe<Order_By>;
  last_billing_at?: Maybe<Order_By>;
  next_billing_at?: Maybe<Order_By>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Order_By>;
  outgoing_parcels_aggregate?: Maybe<Order_Parcels_Aggregate_Order_By>;
  parent_order?: Maybe<Orders_Order_By>;
  parent_order_id?: Maybe<Order_By>;
  payment_source?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  shipping_address?: Maybe<Order_By>;
  states_aggregate?: Maybe<Order_States_Aggregate_Order_By>;
  subscription?: Maybe<Order_Subscriptions_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  ActivatedAt = 'activated_at',
  /** column name */
  Active = 'active',
  /** column name */
  BillingAddress = 'billing_address',
  /** column name */
  ChildOrderId = 'child_order_id',
  /** column name */
  Code = 'code',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CompanyRegistrationNumber = 'company_registration_number',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsBusinessOrder = 'is_business_order',
  /** column name */
  LastBillingAt = 'last_billing_at',
  /** column name */
  NextBillingAt = 'next_billing_at',
  /** column name */
  ParentOrderId = 'parent_order_id',
  /** column name */
  PaymentSource = 'payment_source',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "orders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "orders" */
export enum Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsBusinessOrder = 'is_business_order'
}

/** select "orders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "orders" */
export enum Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsBusinessOrder = 'is_business_order'
}

/** aggregate stddev on columns */
export type Orders_Stddev_Fields = {
  __typename?: 'orders_stddev_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders" */
export type Orders_Stddev_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Orders_Stddev_Pop_Fields = {
  __typename?: 'orders_stddev_pop_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Orders_Stddev_Pop_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Orders_Stddev_Samp_Fields = {
  __typename?: 'orders_stddev_samp_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Orders_Stddev_Samp_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "orders" */
export type Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Stream_Cursor_Value_Input = {
  activated_at?: Maybe<Scalars['timestamptz']>;
  active?: Maybe<Scalars['Boolean']>;
  billing_address?: Maybe<Scalars['jsonb']>;
  child_order_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  company_registration_number?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_business_order?: Maybe<Scalars['Boolean']>;
  last_billing_at?: Maybe<Scalars['timestamptz']>;
  next_billing_at?: Maybe<Scalars['timestamptz']>;
  parent_order_id?: Maybe<Scalars['Int']>;
  payment_source?: Maybe<Scalars['jsonb']>;
  shipping_address?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Orders_Sum_Fields = {
  __typename?: 'orders_sum_fields';
  child_order_id?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent_order_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "orders" */
export type Orders_Sum_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Orders_Var_Pop_Fields = {
  __typename?: 'orders_var_pop_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders" */
export type Orders_Var_Pop_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Orders_Var_Samp_Fields = {
  __typename?: 'orders_var_samp_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders" */
export type Orders_Var_Samp_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Orders_Variance_Fields = {
  __typename?: 'orders_variance_fields';
  child_order_id?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_order_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders" */
export type Orders_Variance_Order_By = {
  child_order_id?: Maybe<Order_By>;
  country_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_order_id?: Maybe<Order_By>;
};

/** columns and relationships of "payments" */
export type Payments = {
  __typename?: 'payments';
  amount_in_cents: Scalars['Int'];
  authorized?: Maybe<Scalars['Boolean']>;
  authorized_at?: Maybe<Scalars['timestamptz']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  captured: Scalars['Boolean'];
  captured_at?: Maybe<Scalars['timestamptz']>;
  chargedback_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gateway: Scalars['String'];
  gateway_reference?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  invoices: Array<Invoice_Payment>;
  is_automatic?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  /** An object relationship */
  parent_payment?: Maybe<Payments>;
  parent_payment_id?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  recurly_invoice_id?: Maybe<Scalars['String']>;
  recurly_invoice_number?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  /** An array relationship */
  refunds: Array<Payments>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "payments" */
export type PaymentsInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoice_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Payment_Order_By>>;
  where?: Maybe<Invoice_Payment_Bool_Exp>;
};


/** columns and relationships of "payments" */
export type PaymentsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "payments" */
export type PaymentsRefundsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** order by aggregate values of table "payments" */
export type Payments_Aggregate_Order_By = {
  avg?: Maybe<Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payments_Max_Order_By>;
  min?: Maybe<Payments_Min_Order_By>;
  stddev?: Maybe<Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Payments_Sum_Order_By>;
  var_pop?: Maybe<Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Payments_Var_Samp_Order_By>;
  variance?: Maybe<Payments_Variance_Order_By>;
};

/** order by avg() on columns of table "payments" */
export type Payments_Avg_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type Payments_Bool_Exp = {
  _and?: Maybe<Array<Payments_Bool_Exp>>;
  _not?: Maybe<Payments_Bool_Exp>;
  _or?: Maybe<Array<Payments_Bool_Exp>>;
  amount_in_cents?: Maybe<Int_Comparison_Exp>;
  authorized?: Maybe<Boolean_Comparison_Exp>;
  authorized_at?: Maybe<Timestamptz_Comparison_Exp>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  captured?: Maybe<Boolean_Comparison_Exp>;
  captured_at?: Maybe<Timestamptz_Comparison_Exp>;
  chargedback_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  gateway?: Maybe<String_Comparison_Exp>;
  gateway_reference?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoices?: Maybe<Invoice_Payment_Bool_Exp>;
  is_automatic?: Maybe<Boolean_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  parent_payment?: Maybe<Payments_Bool_Exp>;
  parent_payment_id?: Maybe<Int_Comparison_Exp>;
  payment_method?: Maybe<String_Comparison_Exp>;
  payment_type?: Maybe<String_Comparison_Exp>;
  recurly_invoice_id?: Maybe<String_Comparison_Exp>;
  recurly_invoice_number?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  refunds?: Maybe<Payments_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "payments" */
export type Payments_Max_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  authorized_at?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  captured_at?: Maybe<Order_By>;
  chargedback_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gateway?: Maybe<Order_By>;
  gateway_reference?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payment_type?: Maybe<Order_By>;
  recurly_invoice_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "payments" */
export type Payments_Min_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  authorized_at?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  captured_at?: Maybe<Order_By>;
  chargedback_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gateway?: Maybe<Order_By>;
  gateway_reference?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payment_type?: Maybe<Order_By>;
  recurly_invoice_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "payments". */
export type Payments_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  authorized?: Maybe<Order_By>;
  authorized_at?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  captured?: Maybe<Order_By>;
  captured_at?: Maybe<Order_By>;
  chargedback_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gateway?: Maybe<Order_By>;
  gateway_reference?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoice_Payment_Aggregate_Order_By>;
  is_automatic?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment?: Maybe<Payments_Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payment_type?: Maybe<Order_By>;
  recurly_invoice_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  refunds_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "payments" */
export enum Payments_Select_Column {
  /** column name */
  AmountInCents = 'amount_in_cents',
  /** column name */
  Authorized = 'authorized',
  /** column name */
  AuthorizedAt = 'authorized_at',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Captured = 'captured',
  /** column name */
  CapturedAt = 'captured_at',
  /** column name */
  ChargedbackAt = 'chargedback_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Gateway = 'gateway',
  /** column name */
  GatewayReference = 'gateway_reference',
  /** column name */
  Id = 'id',
  /** column name */
  IsAutomatic = 'is_automatic',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ParentPaymentId = 'parent_payment_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  RecurlyInvoiceId = 'recurly_invoice_id',
  /** column name */
  RecurlyInvoiceNumber = 'recurly_invoice_number',
  /** column name */
  Reference = 'reference',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "payments" */
export type Payments_Stddev_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "payments" */
export type Payments_Stddev_Pop_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "payments" */
export type Payments_Stddev_Samp_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** Streaming cursor of the table "payments" */
export type Payments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payments_Stream_Cursor_Value_Input = {
  amount_in_cents?: Maybe<Scalars['Int']>;
  authorized?: Maybe<Scalars['Boolean']>;
  authorized_at?: Maybe<Scalars['timestamptz']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  captured?: Maybe<Scalars['Boolean']>;
  captured_at?: Maybe<Scalars['timestamptz']>;
  chargedback_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gateway?: Maybe<Scalars['String']>;
  gateway_reference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_automatic?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['jsonb']>;
  order_id?: Maybe<Scalars['Int']>;
  parent_payment_id?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  recurly_invoice_id?: Maybe<Scalars['String']>;
  recurly_invoice_number?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "payments" */
export type Payments_Sum_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "payments" */
export type Payments_Var_Pop_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "payments" */
export type Payments_Var_Samp_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** order by variance() on columns of table "payments" */
export type Payments_Variance_Order_By = {
  amount_in_cents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  parent_payment_id?: Maybe<Order_By>;
  recurly_invoice_number?: Maybe<Order_By>;
};

/** columns and relationships of "phone_plans_orders" */
export type Phone_Plans_Orders = {
  __typename?: 'phone_plans_orders';
  activable?: Maybe<Scalars['Boolean']>;
  b2b_user_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_esim?: Maybe<Scalars['Boolean']>;
  order_state?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sim_name?: Maybe<Scalars['String']>;
  subscription_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "phone_plans_orders" */
export type Phone_Plans_Orders_Aggregate = {
  __typename?: 'phone_plans_orders_aggregate';
  aggregate?: Maybe<Phone_Plans_Orders_Aggregate_Fields>;
  nodes: Array<Phone_Plans_Orders>;
};

/** aggregate fields of "phone_plans_orders" */
export type Phone_Plans_Orders_Aggregate_Fields = {
  __typename?: 'phone_plans_orders_aggregate_fields';
  avg?: Maybe<Phone_Plans_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Phone_Plans_Orders_Max_Fields>;
  min?: Maybe<Phone_Plans_Orders_Min_Fields>;
  stddev?: Maybe<Phone_Plans_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Phone_Plans_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phone_Plans_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Phone_Plans_Orders_Sum_Fields>;
  var_pop?: Maybe<Phone_Plans_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Phone_Plans_Orders_Var_Samp_Fields>;
  variance?: Maybe<Phone_Plans_Orders_Variance_Fields>;
};


/** aggregate fields of "phone_plans_orders" */
export type Phone_Plans_Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Phone_Plans_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Phone_Plans_Orders_Avg_Fields = {
  __typename?: 'phone_plans_orders_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "phone_plans_orders". All fields are combined with a logical 'AND'. */
export type Phone_Plans_Orders_Bool_Exp = {
  _and?: Maybe<Array<Phone_Plans_Orders_Bool_Exp>>;
  _not?: Maybe<Phone_Plans_Orders_Bool_Exp>;
  _or?: Maybe<Array<Phone_Plans_Orders_Bool_Exp>>;
  activable?: Maybe<Boolean_Comparison_Exp>;
  b2b_user_name?: Maybe<String_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_esim?: Maybe<Boolean_Comparison_Exp>;
  order_state?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  sim_name?: Maybe<String_Comparison_Exp>;
  subscription_name?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Phone_Plans_Orders_Max_Fields = {
  __typename?: 'phone_plans_orders_max_fields';
  b2b_user_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_state?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sim_name?: Maybe<Scalars['String']>;
  subscription_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Phone_Plans_Orders_Min_Fields = {
  __typename?: 'phone_plans_orders_min_fields';
  b2b_user_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order_state?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sim_name?: Maybe<Scalars['String']>;
  subscription_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "phone_plans_orders". */
export type Phone_Plans_Orders_Order_By = {
  activable?: Maybe<Order_By>;
  b2b_user_name?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_esim?: Maybe<Order_By>;
  order_state?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  sim_name?: Maybe<Order_By>;
  subscription_name?: Maybe<Order_By>;
};

/** select columns of table "phone_plans_orders" */
export enum Phone_Plans_Orders_Select_Column {
  /** column name */
  Activable = 'activable',
  /** column name */
  B2bUserName = 'b2b_user_name',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEsim = 'is_esim',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SimName = 'sim_name',
  /** column name */
  SubscriptionName = 'subscription_name'
}

/** aggregate stddev on columns */
export type Phone_Plans_Orders_Stddev_Fields = {
  __typename?: 'phone_plans_orders_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Phone_Plans_Orders_Stddev_Pop_Fields = {
  __typename?: 'phone_plans_orders_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Phone_Plans_Orders_Stddev_Samp_Fields = {
  __typename?: 'phone_plans_orders_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "phone_plans_orders" */
export type Phone_Plans_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Phone_Plans_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Phone_Plans_Orders_Stream_Cursor_Value_Input = {
  activable?: Maybe<Scalars['Boolean']>;
  b2b_user_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_esim?: Maybe<Scalars['Boolean']>;
  order_state?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sim_name?: Maybe<Scalars['String']>;
  subscription_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Phone_Plans_Orders_Sum_Fields = {
  __typename?: 'phone_plans_orders_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Phone_Plans_Orders_Var_Pop_Fields = {
  __typename?: 'phone_plans_orders_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Phone_Plans_Orders_Var_Samp_Fields = {
  __typename?: 'phone_plans_orders_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Phone_Plans_Orders_Variance_Fields = {
  __typename?: 'phone_plans_orders_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  LpmOrdering_getModelInsuranceFees: LpmGetModelInsuranceFeesResult;
  LpmOrdering_lookupBankDetails?: Maybe<BankDetailsLookup>;
  LpmOrdering_retrieveBankDetails?: Maybe<Scalars['String']>;
  accessories?: Maybe<AccessoryEntityResponseCollection>;
  accessory?: Maybe<AccessoryEntityResponse>;
  admin_customerAccount_listCards: ListCardsReponse;
  auth_is_banned_customer: IsBannedCustomerResult;
  b2BLaptopCatalog?: Maybe<B2BLaptopCatalogEntityResponse>;
  b2BPhonePlanCatalog?: Maybe<B2BPhonePlanCatalogEntityResponse>;
  b2BSmartphoneCatalog?: Maybe<B2BSmartphoneCatalogEntityResponse>;
  b2BTabletCatalog?: Maybe<B2BTabletCatalogEntityResponse>;
  b2CSmartphoneBestSellersCatalog?: Maybe<B2CSmartphoneBestSellersCatalogEntityResponse>;
  b2CSmartphoneCatalog?: Maybe<B2CSmartphoneCatalogEntityResponse>;
  b2CSmartphoneOthersCatalog?: Maybe<B2CSmartphoneOthersCatalogEntityResponse>;
  /** An array relationship */
  b2b_accounts: Array<B2b_Accounts>;
  /** fetch data from the table: "b2b_accounts" using primary key columns */
  b2b_accounts_by_pk?: Maybe<B2b_Accounts>;
  /** An array relationship */
  b2b_users: Array<B2b_Users>;
  /** An aggregate relationship */
  b2b_users_aggregate: B2b_Users_Aggregate;
  /** fetch data from the table: "b2b_users" using primary key columns */
  b2b_users_by_pk?: Maybe<B2b_Users>;
  /** fetch data from the table: "b2b_users_orders" */
  b2b_users_orders: Array<B2b_Users_Orders>;
  /** fetch aggregated fields from the table: "b2b_users_orders" */
  b2b_users_orders_aggregate: B2b_Users_Orders_Aggregate;
  /** fetch data from the table: "b2b_users_orders" using primary key columns */
  b2b_users_orders_by_pk?: Maybe<B2b_Users_Orders>;
  bankDetails_getAllForCustomer: Array<BankDetailsOutput>;
  /** An array relationship */
  bank_details: Array<Bank_Details>;
  /** fetch data from the table: "bank_details" using primary key columns */
  bank_details_by_pk?: Maybe<Bank_Details>;
  /** fetch data from the table: "bank_details_files" */
  bank_details_files: Array<Bank_Details_Files>;
  catalog?: Maybe<CatalogEntityResponse>;
  catalogs?: Maybe<CatalogEntityResponseCollection>;
  cgu?: Maybe<CguEntityResponse>;
  comparatorRival?: Maybe<ComparatorRivalEntityResponse>;
  comparatorRivals?: Maybe<ComparatorRivalEntityResponseCollection>;
  consumerFinancing_hasRequestedFinancing: HasRequestedFinancingResult;
  content?: Maybe<ContentEntityResponse>;
  contents?: Maybe<ContentEntityResponseCollection>;
  contract_getCustomerContract: GetCustomerContractResult;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** An array relationship */
  coupon_orders: Array<Coupon_Orders>;
  /** fetch data from the table: "coupon_orders" using primary key columns */
  coupon_orders_by_pk?: Maybe<Coupon_Orders>;
  /** fetch data from the table: "coupons" */
  coupons: Array<Coupons>;
  /** fetch data from the table: "coupons" using primary key columns */
  coupons_by_pk?: Maybe<Coupons>;
  customerAccount_listCards: ListCardsReponse;
  /** An array relationship */
  customer_contract_states: Array<Customer_Contract_States>;
  /** fetch data from the table: "customer_contract_states" using primary key columns */
  customer_contract_states_by_pk?: Maybe<Customer_Contract_States>;
  /** An array relationship */
  customer_contracts: Array<Customer_Contracts>;
  /** fetch data from the table: "customer_contracts" using primary key columns */
  customer_contracts_by_pk?: Maybe<Customer_Contracts>;
  customer_isEligibleToSwap: EligibilityDto;
  /** fetch data from the table: "customer_verification_files" */
  customer_verification_files: Array<Customer_Verification_Files>;
  /** fetch data from the table: "customer_verification_states" */
  customer_verification_states: Array<Customer_Verification_States>;
  /** fetch data from the table: "customer_verification_states" using primary key columns */
  customer_verification_states_by_pk?: Maybe<Customer_Verification_States>;
  /** An array relationship */
  customer_verifications: Array<Customer_Verifications>;
  /** fetch data from the table: "customer_verifications" using primary key columns */
  customer_verifications_by_pk?: Maybe<Customer_Verifications>;
  /** fetch data from the table: "customer_verifications_scoring_model_view" */
  customer_verifications_scoring_model_view: Array<Customer_Verifications_Scoring_Model_View>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  deviceExchange_getDeviceAmortization?: Maybe<GetDeviceAmortizationDto>;
  /** fetch data from the table: "devices_stock_view_cache" */
  devices_stock_view_cache: Array<Devices_Stock_View_Cache>;
  emailTemplaterLayout?: Maybe<EmailTemplaterLayoutEntityResponse>;
  emailTemplaterLayouts?: Maybe<EmailTemplaterLayoutEntityResponseCollection>;
  emailTemplaterTemplate?: Maybe<EmailTemplaterTemplateEntityResponse>;
  emailTemplaterTemplates?: Maybe<EmailTemplaterTemplateEntityResponseCollection>;
  /** An array relationship */
  experiments_allocations: Array<Experiments_Allocations>;
  /** An aggregate relationship */
  experiments_allocations_aggregate: Experiments_Allocations_Aggregate;
  /** fetch data from the table: "experiments_allocations" using primary key columns */
  experiments_allocations_by_pk?: Maybe<Experiments_Allocations>;
  faq?: Maybe<FaqEntityResponse>;
  faqs?: Maybe<FaqEntityResponseCollection>;
  financingContract_getFinancingContract?: Maybe<FinancingContractResult>;
  financingContract_getPaymentScheduleUrl?: Maybe<Scalars['String']>;
  financingContract_getTransactionStatus?: Maybe<TransactionStatusResultDto>;
  /** fetch data from the table: "financing_contract" */
  financing_contract: Array<Financing_Contract>;
  /** fetch aggregated fields from the table: "financing_contract" */
  financing_contract_aggregate: Financing_Contract_Aggregate;
  /** fetch data from the table: "financing_contract_application" */
  financing_contract_application: Array<Financing_Contract_Application>;
  /** fetch aggregated fields from the table: "financing_contract_application" */
  financing_contract_application_aggregate: Financing_Contract_Application_Aggregate;
  /** fetch data from the table: "financing_contract_application" using primary key columns */
  financing_contract_application_by_pk?: Maybe<Financing_Contract_Application>;
  /** fetch data from the table: "financing_contract" using primary key columns */
  financing_contract_by_pk?: Maybe<Financing_Contract>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  insurance_breakageInsurance: SuccessObjectDto;
  /** fetch data from the table: "insurance_ticket_states" */
  insurance_ticket_states: Array<Insurance_Ticket_States>;
  /** fetch data from the table: "insurance_ticket_states" using primary key columns */
  insurance_ticket_states_by_pk?: Maybe<Insurance_Ticket_States>;
  /** An array relationship */
  insurance_tickets: Array<Insurance_Tickets>;
  /** fetch data from the table: "insurance_tickets" using primary key columns */
  insurance_tickets_by_pk?: Maybe<Insurance_Tickets>;
  /** fetch data from the table: "insurance_tickets_files" */
  insurance_tickets_files: Array<Insurance_Tickets_Files>;
  /** fetch data from the table: "invoice_files" */
  invoice_files: Array<Invoice_Files>;
  /** fetch data from the table: "invoice_groups" */
  invoice_groups: Array<Invoice_Groups>;
  /** fetch aggregated fields from the table: "invoice_groups" */
  invoice_groups_aggregate: Invoice_Groups_Aggregate;
  /** fetch data from the table: "invoice_groups" using primary key columns */
  invoice_groups_by_pk?: Maybe<Invoice_Groups>;
  /** fetch data from the table: "invoice_payment" */
  invoice_payment: Array<Invoice_Payment>;
  /** fetch data from the table: "invoice_payment" using primary key columns */
  invoice_payment_by_pk?: Maybe<Invoice_Payment>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** An array relationship */
  items: Array<Items>;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  kyc_getDeclaredStatusAndIncome: GetDeclaredStatusAndIncomeResult;
  kyc_hasDeclaredStatusAndIncome: HasDeclaredStatusAndIncomeResult;
  kyc_retrieveIdentityInfos: RetrieveIdentityInfosResult;
  kyc_shouldDeclareStatusAndIncome: ShouldDeclareStatusAndIncomeResult;
  laptopFamilies?: Maybe<LaptopFamilyEntityResponseCollection>;
  laptopFamily?: Maybe<LaptopFamilyEntityResponse>;
  laptopSubscription?: Maybe<LaptopSubscriptionEntityResponse>;
  laptopSubscriptions?: Maybe<LaptopSubscriptionEntityResponseCollection>;
  laptopVariant?: Maybe<LaptopVariantEntityResponse>;
  laptopVariants?: Maybe<LaptopVariantEntityResponseCollection>;
  lpmSalesFunnelCatalog?: Maybe<LpmSalesFunnelCatalogEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  nest_adminBankDetailsAccountHolderUnpaidCustomersCheckpoint: SimilarCustomerSplit;
  nest_adminBankDetailsUnpaidCustomersCheckpoint: SimilarCustomerSplit;
  nest_adminCheckpointSimilarUnpaidCustomers: Array<SimilarCustomer>;
  nest_adminCheckpointUbble?: Maybe<CheckpointUbbleResult>;
  nest_adminConsumptionInfo: ConsumptionInfo;
  nest_adminGetAssignedOrders: Array<LogisticListOrder>;
  nest_adminGetBreakageFeeItemByDeviceModel: BreakageFeeItem;
  nest_adminGetCleaqOrderInfo?: Maybe<CleaqOrderInfo>;
  nest_adminGetCustomerBankDetails?: Maybe<BankDetailsOutput>;
  nest_adminGetCustomerMetadata: CustomerMetadata;
  nest_adminGetInPreparationOrders: Array<LogisticListOrder>;
  nest_adminGetInTransitOrders: Array<LogisticListOrder>;
  nest_adminGetNotCompliantParcels: Array<IncomingParcelsView>;
  nest_adminGetOrderCustomerRevenue: OrderCustomerRevenueDto;
  nest_adminGetOrderSubscriptionDevices: Array<OrderSubscriptionDeviceDto>;
  nest_adminGetRefundParcels: Array<IncomingParcelsView>;
  nest_adminGetRefundableAmount: RefundableAmountDto;
  nest_adminGetReturnedParcels: Array<IncomingParcelsView>;
  nest_adminGetReturningParcels: Array<IncomingParcelsView>;
  nest_adminGetUnassignedOrders: Array<LogisticListOrder>;
  nest_adminGetWaitingReturnParcels: Array<IncomingParcelsView>;
  nest_adminGetWaitingShipmentOrders: Array<LogisticListOrder>;
  nest_adminGoCardlessCheckpoint: GoCardlessCheckpointResponse;
  nest_adminInvoiceCollectionGetPath: Array<InvoiceCollectionStep>;
  nest_adminKycCheckpoints: KycCheckpointsResult;
  nest_adminNearbyUnpaidCustomersCheckpoint: SimilarUnpaidCustomerNearby;
  nest_adminSameCustomerCheckpoint: Array<SimilarCustomer>;
  nest_customerConsumptionInfo: ConsumptionInfo;
  nest_customerGetBankDetails: Array<BankDetailsOutput>;
  nest_customerGetInsuranceFees: InsuranceFees;
  nest_customerGetSubscriptionAccessoriesSkus: CatalogItemsSkus;
  nest_customerInvoices: Array<CustomerInvoiceDto>;
  nest_getAdminOrderMetadata: OrderMetadata;
  nest_getCustomerMetadata?: Maybe<CustomerMetadata>;
  nest_getCustomerVerification?: Maybe<CustomerVerificationDto>;
  nest_getDiscountCodeMetadata?: Maybe<DiscountCodeMetadata>;
  nest_getGorgiasTickets: Array<GorgiasTicket>;
  nest_getHelpScoutTickets: Array<HelpScoutTicket>;
  nest_getOrderEstimatedDelivery: EstimatedDeliveryTimeRange;
  nest_getOwnershipHistoryByStockItemId: Array<StockItemOwnershipDto>;
  nest_getUPSAccessPoints: Array<Scalars['JSONObject']>;
  nest_getUnpaidCustomers: UnpaidCustomers;
  nest_getWebsiteMetadata: WebsiteMetadata;
  nest_orderSwap_getUpfrontDiscountPercentage: OrderSwapGetUpfrontDiscountPercentage;
  nest_search: Array<SearchResult>;
  /** fetch data from the table: "open_banking_connections" */
  open_banking_connections: Array<Open_Banking_Connections>;
  /** fetch data from the table: "open_banking_connections" using primary key columns */
  open_banking_connections_by_pk?: Maybe<Open_Banking_Connections>;
  orderManagement_availableActions?: Maybe<AvailableActions>;
  orderSeniority: OrderSeniority;
  /** fetch data from the table: "order_files" */
  order_files: Array<Order_Files>;
  /** fetch data from the table: "order_item_files" */
  order_item_files: Array<Order_Item_Files>;
  /** fetch data from the table: "order_item_states" */
  order_item_states: Array<Order_Item_States>;
  /** fetch data from the table: "order_item_states" using primary key columns */
  order_item_states_by_pk?: Maybe<Order_Item_States>;
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
  /** fetch data from the table: "order_items" using primary key columns */
  order_items_by_pk?: Maybe<Order_Items>;
  /** fetch data from the table: "order_parcels" */
  order_parcels: Array<Order_Parcels>;
  /** fetch data from the table: "order_states" */
  order_states: Array<Order_States>;
  /** fetch data from the table: "order_states" using primary key columns */
  order_states_by_pk?: Maybe<Order_States>;
  /** fetch data from the table: "order_subscriptions" */
  order_subscriptions: Array<Order_Subscriptions>;
  /** fetch data from the table: "order_subscriptions" using primary key columns */
  order_subscriptions_by_pk?: Maybe<Order_Subscriptions>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "orders_device" */
  orders_device: Array<Orders_Device>;
  /** An array relationship */
  payments: Array<Payments>;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  phonePlanSubscription?: Maybe<PhonePlanSubscriptionEntityResponse>;
  phonePlanSubscriptions?: Maybe<PhonePlanSubscriptionEntityResponseCollection>;
  /** fetch data from the table: "phone_plans_orders" */
  phone_plans_orders: Array<Phone_Plans_Orders>;
  /** fetch aggregated fields from the table: "phone_plans_orders" */
  phone_plans_orders_aggregate: Phone_Plans_Orders_Aggregate;
  privacy?: Maybe<PrivacyEntityResponse>;
  /** fetch data from the table: "ratings" */
  ratings: Array<Ratings>;
  /** fetch aggregated fields from the table: "ratings" */
  ratings_aggregate: Ratings_Aggregate;
  /** fetch data from the table: "ratings" using primary key columns */
  ratings_by_pk?: Maybe<Ratings>;
  ratings_ratingsAverage?: Maybe<Average>;
  review?: Maybe<ReviewEntityResponse>;
  reviews?: Maybe<ReviewEntityResponseCollection>;
  seoProperties?: Maybe<SeoPropertyEntityResponseCollection>;
  seoProperty?: Maybe<SeoPropertyEntityResponse>;
  /** fetch data from the table: "signatory" */
  signatory: Array<Signatory>;
  /** fetch data from the table: "signatory" using primary key columns */
  signatory_by_pk?: Maybe<Signatory>;
  smartphoneFamilies?: Maybe<SmartphoneFamilyEntityResponseCollection>;
  smartphoneFamily?: Maybe<SmartphoneFamilyEntityResponse>;
  smartphoneSubscription?: Maybe<SmartphoneSubscriptionEntityResponse>;
  smartphoneSubscriptions?: Maybe<SmartphoneSubscriptionEntityResponseCollection>;
  smartphoneVariant?: Maybe<SmartphoneVariantEntityResponse>;
  smartphoneVariants?: Maybe<SmartphoneVariantEntityResponseCollection>;
  /** fetch data from the table: "stock_item_states" */
  stock_item_states: Array<Stock_Item_States>;
  /** fetch data from the table: "stock_item_states" using primary key columns */
  stock_item_states_by_pk?: Maybe<Stock_Item_States>;
  /** An array relationship */
  stock_items: Array<Stock_Items>;
  /** fetch data from the table: "stock_items" using primary key columns */
  stock_items_by_pk?: Maybe<Stock_Items>;
  tabletFamilies?: Maybe<TabletFamilyEntityResponseCollection>;
  tabletFamily?: Maybe<TabletFamilyEntityResponse>;
  tabletSubscription?: Maybe<TabletSubscriptionEntityResponse>;
  tabletSubscriptions?: Maybe<TabletSubscriptionEntityResponseCollection>;
  tabletVariant?: Maybe<TabletVariantEntityResponse>;
  tabletVariants?: Maybe<TabletVariantEntityResponseCollection>;
  tag?: Maybe<TagEntityResponse>;
  tags?: Maybe<TagEntityResponseCollection>;
  /** An array relationship */
  taxes: Array<Taxes>;
  /** fetch data from the table: "taxes" using primary key columns */
  taxes_by_pk?: Maybe<Taxes>;
  translation?: Maybe<TranslationEntityResponse>;
  translations?: Maybe<TranslationEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userManagement_userDetails: UserDetailsResult;
  userManagement_userRoleAndPermissions: UserRoleAndPermissionsResult;
  userManagement_users: UsersResult;
  userManagement_usersCount: UserCountResult;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  websiteSetting?: Maybe<WebsiteSettingEntityResponse>;
};


export type Query_RootLpmOrdering_GetModelInsuranceFeesArgs = {
  model: Scalars['String'];
};


export type Query_RootLpmOrdering_LookupBankDetailsArgs = {
  iban: Scalars['String'];
};


export type Query_RootLpmOrdering_RetrieveBankDetailsArgs = {
  contractId: Scalars['String'];
};


export type Query_RootAccessoriesArgs = {
  filters?: Maybe<AccessoryFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootAccessoryArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootAdmin_CustomerAccount_ListCardsArgs = {
  customerId: Scalars['Int'];
};


export type Query_RootAuth_Is_Banned_CustomerArgs = {
  customerId: Scalars['Int'];
};


export type Query_RootB2BLaptopCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2BPhonePlanCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2BSmartphoneCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2BTabletCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2CSmartphoneBestSellersCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2CSmartphoneCatalogArgs = {
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2CSmartphoneOthersCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootB2b_AccountsArgs = {
  distinct_on?: Maybe<Array<B2b_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Accounts_Order_By>>;
  where?: Maybe<B2b_Accounts_Bool_Exp>;
};


export type Query_RootB2b_Accounts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootB2b_UsersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Order_By>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


export type Query_RootB2b_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Order_By>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


export type Query_RootB2b_Users_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootB2b_Users_OrdersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


export type Query_RootB2b_Users_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


export type Query_RootB2b_Users_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBankDetails_GetAllForCustomerArgs = {
  customerId: Scalars['Int'];
};


export type Query_RootBank_DetailsArgs = {
  distinct_on?: Maybe<Array<Bank_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Details_Order_By>>;
  where?: Maybe<Bank_Details_Bool_Exp>;
};


export type Query_RootBank_Details_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBank_Details_FilesArgs = {
  distinct_on?: Maybe<Array<Bank_Details_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Details_Files_Order_By>>;
  where?: Maybe<Bank_Details_Files_Bool_Exp>;
};


export type Query_RootCatalogArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootCatalogsArgs = {
  filters?: Maybe<CatalogFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootCguArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootComparatorRivalArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootComparatorRivalsArgs = {
  filters?: Maybe<ComparatorRivalFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootConsumerFinancing_HasRequestedFinancingArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootContentArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootContentsArgs = {
  filters?: Maybe<ContentFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootContract_GetCustomerContractArgs = {
  customerId: Scalars['Int'];
  orderId: Scalars['Int'];
};


export type Query_RootCountriesArgs = {
  distinct_on?: Maybe<Array<Countries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Countries_Order_By>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCoupon_OrdersArgs = {
  distinct_on?: Maybe<Array<Coupon_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Orders_Order_By>>;
  where?: Maybe<Coupon_Orders_Bool_Exp>;
};


export type Query_RootCoupon_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCouponsArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


export type Query_RootCoupons_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_Contract_StatesArgs = {
  distinct_on?: Maybe<Array<Customer_Contract_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contract_States_Order_By>>;
  where?: Maybe<Customer_Contract_States_Bool_Exp>;
};


export type Query_RootCustomer_Contract_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_ContractsArgs = {
  distinct_on?: Maybe<Array<Customer_Contracts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contracts_Order_By>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};


export type Query_RootCustomer_Contracts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_IsEligibleToSwapArgs = {
  customerId: Scalars['Int'];
};


export type Query_RootCustomer_Verification_FilesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_Files_Order_By>>;
  where?: Maybe<Customer_Verification_Files_Bool_Exp>;
};


export type Query_RootCustomer_Verification_StatesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_States_Order_By>>;
  where?: Maybe<Customer_Verification_States_Bool_Exp>;
};


export type Query_RootCustomer_Verification_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_VerificationsArgs = {
  distinct_on?: Maybe<Array<Customer_Verifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verifications_Order_By>>;
  where?: Maybe<Customer_Verifications_Bool_Exp>;
};


export type Query_RootCustomer_Verifications_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_Verifications_Scoring_Model_ViewArgs = {
  distinct_on?: Maybe<Array<Customer_Verifications_Scoring_Model_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verifications_Scoring_Model_View_Order_By>>;
  where?: Maybe<Customer_Verifications_Scoring_Model_View_Bool_Exp>;
};


export type Query_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDeviceExchange_GetDeviceAmortizationArgs = {
  deviceId: Scalars['Int'];
};


export type Query_RootDevices_Stock_View_CacheArgs = {
  distinct_on?: Maybe<Array<Devices_Stock_View_Cache_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Stock_View_Cache_Order_By>>;
  where?: Maybe<Devices_Stock_View_Cache_Bool_Exp>;
};


export type Query_RootEmailTemplaterLayoutArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootEmailTemplaterLayoutsArgs = {
  filters?: Maybe<EmailTemplaterLayoutFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootEmailTemplaterTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootEmailTemplaterTemplatesArgs = {
  filters?: Maybe<EmailTemplaterTemplateFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootExperiments_AllocationsArgs = {
  distinct_on?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Experiments_Allocations_Order_By>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


export type Query_RootExperiments_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Experiments_Allocations_Order_By>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


export type Query_RootExperiments_Allocations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFaqArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootFaqsArgs = {
  filters?: Maybe<FaqFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootFinancingContract_GetFinancingContractArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootFinancingContract_GetPaymentScheduleUrlArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootFinancingContract_GetTransactionStatusArgs = {
  orderId: Scalars['Float'];
};


export type Query_RootFinancing_ContractArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


export type Query_RootFinancing_Contract_AggregateArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


export type Query_RootFinancing_Contract_ApplicationArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Application_Order_By>>;
  where?: Maybe<Financing_Contract_Application_Bool_Exp>;
};


export type Query_RootFinancing_Contract_Application_AggregateArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Application_Order_By>>;
  where?: Maybe<Financing_Contract_Application_Bool_Exp>;
};


export type Query_RootFinancing_Contract_Application_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFinancing_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootI18NLocaleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootI18NLocalesArgs = {
  filters?: Maybe<I18NLocaleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootInsurance_Ticket_StatesArgs = {
  distinct_on?: Maybe<Array<Insurance_Ticket_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Ticket_States_Order_By>>;
  where?: Maybe<Insurance_Ticket_States_Bool_Exp>;
};


export type Query_RootInsurance_Ticket_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInsurance_TicketsArgs = {
  distinct_on?: Maybe<Array<Insurance_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Tickets_Order_By>>;
  where?: Maybe<Insurance_Tickets_Bool_Exp>;
};


export type Query_RootInsurance_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInsurance_Tickets_FilesArgs = {
  distinct_on?: Maybe<Array<Insurance_Tickets_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Tickets_Files_Order_By>>;
  where?: Maybe<Insurance_Tickets_Files_Bool_Exp>;
};


export type Query_RootInvoice_FilesArgs = {
  distinct_on?: Maybe<Array<Invoice_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Files_Order_By>>;
  where?: Maybe<Invoice_Files_Bool_Exp>;
};


export type Query_RootInvoice_GroupsArgs = {
  distinct_on?: Maybe<Array<Invoice_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Groups_Order_By>>;
  where?: Maybe<Invoice_Groups_Bool_Exp>;
};


export type Query_RootInvoice_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Groups_Order_By>>;
  where?: Maybe<Invoice_Groups_Bool_Exp>;
};


export type Query_RootInvoice_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoice_PaymentArgs = {
  distinct_on?: Maybe<Array<Invoice_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Payment_Order_By>>;
  where?: Maybe<Invoice_Payment_Bool_Exp>;
};


export type Query_RootInvoice_Payment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Query_RootInvoices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootItemsArgs = {
  distinct_on?: Maybe<Array<Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Items_Order_By>>;
  where?: Maybe<Items_Bool_Exp>;
};


export type Query_RootItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootKyc_RetrieveIdentityInfosArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootKyc_ShouldDeclareStatusAndIncomeArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootLaptopFamiliesArgs = {
  filters?: Maybe<LaptopFamilyFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootLaptopFamilyArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootLaptopSubscriptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootLaptopSubscriptionsArgs = {
  filters?: Maybe<LaptopSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootLaptopVariantArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootLaptopVariantsArgs = {
  filters?: Maybe<LaptopVariantFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootLpmSalesFunnelCatalogArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootNest_AdminBankDetailsAccountHolderUnpaidCustomersCheckpointArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminBankDetailsUnpaidCustomersCheckpointArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminCheckpointSimilarUnpaidCustomersArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminCheckpointUbbleArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminConsumptionInfoArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_AdminGetBreakageFeeItemByDeviceModelArgs = {
  deviceModel: Scalars['String'];
};


export type Query_RootNest_AdminGetCleaqOrderInfoArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_AdminGetCustomerBankDetailsArgs = {
  customerId: Scalars['Int'];
};


export type Query_RootNest_AdminGetCustomerMetadataArgs = {
  customerId: Scalars['Int'];
};


export type Query_RootNest_AdminGetOrderCustomerRevenueArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_AdminGetOrderSubscriptionDevicesArgs = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  upfrontPrice?: Maybe<Scalars['Int']>;
};


export type Query_RootNest_AdminGetRefundableAmountArgs = {
  orderItemId: Scalars['Int'];
};


export type Query_RootNest_AdminGoCardlessCheckpointArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminInvoiceCollectionGetPathArgs = {
  invoiceCollectionId: Scalars['Int'];
};


export type Query_RootNest_AdminKycCheckpointsArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminNearbyUnpaidCustomersCheckpointArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_AdminSameCustomerCheckpointArgs = {
  kycId: Scalars['Int'];
};


export type Query_RootNest_CustomerConsumptionInfoArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_CustomerGetInsuranceFeesArgs = {
  deviceModel: Scalars['String'];
};


export type Query_RootNest_CustomerGetSubscriptionAccessoriesSkusArgs = {
  accessoriesTypes: Array<Scalars['String']>;
  subscriptionSku: Scalars['String'];
};


export type Query_RootNest_CustomerInvoicesArgs = {
  invoiceFilter?: Maybe<InvoiceFilter>;
  limit?: Maybe<Scalars['Int']>;
};


export type Query_RootNest_GetAdminOrderMetadataArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_GetDiscountCodeMetadataArgs = {
  code: Scalars['String'];
};


export type Query_RootNest_GetGorgiasTicketsArgs = {
  searchQuery: Scalars['String'];
};


export type Query_RootNest_GetHelpScoutTicketsArgs = {
  searchQuery: Scalars['String'];
};


export type Query_RootNest_GetOrderEstimatedDeliveryArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_GetOwnershipHistoryByStockItemIdArgs = {
  stockItemId: Scalars['Int'];
};


export type Query_RootNest_GetUpsAccessPointsArgs = {
  address: UpsAccessPointsInput;
};


export type Query_RootNest_OrderSwap_GetUpfrontDiscountPercentageArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootNest_SearchArgs = {
  searchTerm: Scalars['String'];
};


export type Query_RootOpen_Banking_ConnectionsArgs = {
  distinct_on?: Maybe<Array<Open_Banking_Connections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Open_Banking_Connections_Order_By>>;
  where?: Maybe<Open_Banking_Connections_Bool_Exp>;
};


export type Query_RootOpen_Banking_Connections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderManagement_AvailableActionsArgs = {
  orderId: Scalars['Int'];
};


export type Query_RootOrderSeniorityArgs = {
  orderId: Scalars['Int'];
  unitOfTime?: Maybe<Scalars['String']>;
};


export type Query_RootOrder_FilesArgs = {
  distinct_on?: Maybe<Array<Order_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Files_Order_By>>;
  where?: Maybe<Order_Files_Bool_Exp>;
};


export type Query_RootOrder_Item_FilesArgs = {
  distinct_on?: Maybe<Array<Order_Item_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Files_Order_By>>;
  where?: Maybe<Order_Item_Files_Bool_Exp>;
};


export type Query_RootOrder_Item_StatesArgs = {
  distinct_on?: Maybe<Array<Order_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_States_Order_By>>;
  where?: Maybe<Order_Item_States_Bool_Exp>;
};


export type Query_RootOrder_Item_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


export type Query_RootOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


export type Query_RootOrder_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_ParcelsArgs = {
  distinct_on?: Maybe<Array<Order_Parcels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Parcels_Order_By>>;
  where?: Maybe<Order_Parcels_Bool_Exp>;
};


export type Query_RootOrder_StatesArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Query_RootOrder_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Order_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Subscriptions_Order_By>>;
  where?: Maybe<Order_Subscriptions_Bool_Exp>;
};


export type Query_RootOrder_Subscriptions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrders_DeviceArgs = {
  distinct_on?: Maybe<Array<Orders_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Device_Order_By>>;
  where?: Maybe<Orders_Device_Bool_Exp>;
};


export type Query_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPhonePlanSubscriptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootPhonePlanSubscriptionsArgs = {
  filters?: Maybe<PhonePlanSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootPhone_Plans_OrdersArgs = {
  distinct_on?: Maybe<Array<Phone_Plans_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Plans_Orders_Order_By>>;
  where?: Maybe<Phone_Plans_Orders_Bool_Exp>;
};


export type Query_RootPhone_Plans_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Plans_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Plans_Orders_Order_By>>;
  where?: Maybe<Phone_Plans_Orders_Bool_Exp>;
};


export type Query_RootPrivacyArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type Query_RootRatingsArgs = {
  distinct_on?: Maybe<Array<Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ratings_Order_By>>;
  where?: Maybe<Ratings_Bool_Exp>;
};


export type Query_RootRatings_AggregateArgs = {
  distinct_on?: Maybe<Array<Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ratings_Order_By>>;
  where?: Maybe<Ratings_Bool_Exp>;
};


export type Query_RootRatings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootReviewArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootReviewsArgs = {
  filters?: Maybe<ReviewFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootSeoPropertiesArgs = {
  filters?: Maybe<SeoPropertyFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootSeoPropertyArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootSignatoryArgs = {
  distinct_on?: Maybe<Array<Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Signatory_Order_By>>;
  where?: Maybe<Signatory_Bool_Exp>;
};


export type Query_RootSignatory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSmartphoneFamiliesArgs = {
  filters?: Maybe<SmartphoneFamilyFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootSmartphoneFamilyArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootSmartphoneSubscriptionArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootSmartphoneSubscriptionsArgs = {
  filters?: Maybe<SmartphoneSubscriptionFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootSmartphoneVariantArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootSmartphoneVariantsArgs = {
  filters?: Maybe<SmartphoneVariantFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootStock_Item_StatesArgs = {
  distinct_on?: Maybe<Array<Stock_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Item_States_Order_By>>;
  where?: Maybe<Stock_Item_States_Bool_Exp>;
};


export type Query_RootStock_Item_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStock_ItemsArgs = {
  distinct_on?: Maybe<Array<Stock_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Items_Order_By>>;
  where?: Maybe<Stock_Items_Bool_Exp>;
};


export type Query_RootStock_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTabletFamiliesArgs = {
  filters?: Maybe<TabletFamilyFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootTabletFamilyArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootTabletSubscriptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootTabletSubscriptionsArgs = {
  filters?: Maybe<TabletSubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootTabletVariantArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootTabletVariantsArgs = {
  filters?: Maybe<TabletVariantFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootTagArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootTagsArgs = {
  filters?: Maybe<TagFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootTaxesArgs = {
  distinct_on?: Maybe<Array<Taxes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxes_Order_By>>;
  where?: Maybe<Taxes_Bool_Exp>;
};


export type Query_RootTaxes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTranslationArgs = {
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
};


export type Query_RootTranslationsArgs = {
  filters?: Maybe<TranslationFiltersInput>;
  locale?: Maybe<Scalars['I18NLocaleCode']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootUploadFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootUploadFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootUploadFolderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootUploadFoldersArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootUserManagement_UserDetailsArgs = {
  userId: Scalars['Int'];
};


export type Query_RootUserManagement_UsersCountArgs = {
  role: UserRole;
};


export type Query_RootUsersPermissionsRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootUsersPermissionsRolesArgs = {
  filters?: Maybe<UsersPermissionsRoleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootUsersPermissionsUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type Query_RootUsersPermissionsUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootWebsiteSettingArgs = {
  publicationState?: Maybe<PublicationState>;
};

/** columns and relationships of "ratings" */
export type Ratings = {
  __typename?: 'ratings';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  rate: Scalars['Int'];
  review?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ratings" */
export type Ratings_Aggregate = {
  __typename?: 'ratings_aggregate';
  aggregate?: Maybe<Ratings_Aggregate_Fields>;
  nodes: Array<Ratings>;
};

/** aggregate fields of "ratings" */
export type Ratings_Aggregate_Fields = {
  __typename?: 'ratings_aggregate_fields';
  avg?: Maybe<Ratings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ratings_Max_Fields>;
  min?: Maybe<Ratings_Min_Fields>;
  stddev?: Maybe<Ratings_Stddev_Fields>;
  stddev_pop?: Maybe<Ratings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ratings_Stddev_Samp_Fields>;
  sum?: Maybe<Ratings_Sum_Fields>;
  var_pop?: Maybe<Ratings_Var_Pop_Fields>;
  var_samp?: Maybe<Ratings_Var_Samp_Fields>;
  variance?: Maybe<Ratings_Variance_Fields>;
};


/** aggregate fields of "ratings" */
export type Ratings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ratings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ratings_Avg_Fields = {
  __typename?: 'ratings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ratings". All fields are combined with a logical 'AND'. */
export type Ratings_Bool_Exp = {
  _and?: Maybe<Array<Ratings_Bool_Exp>>;
  _not?: Maybe<Ratings_Bool_Exp>;
  _or?: Maybe<Array<Ratings_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  rate?: Maybe<Int_Comparison_Exp>;
  review?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ratings_Max_Fields = {
  __typename?: 'ratings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ratings_Min_Fields = {
  __typename?: 'ratings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "ratings". */
export type Ratings_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  rate?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
};

/** select columns of table "ratings" */
export enum Ratings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rate = 'rate',
  /** column name */
  Review = 'review'
}

/** aggregate stddev on columns */
export type Ratings_Stddev_Fields = {
  __typename?: 'ratings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ratings_Stddev_Pop_Fields = {
  __typename?: 'ratings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ratings_Stddev_Samp_Fields = {
  __typename?: 'ratings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ratings" */
export type Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ratings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ratings_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ratings_Sum_Fields = {
  __typename?: 'ratings_sum_fields';
  id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Ratings_Var_Pop_Fields = {
  __typename?: 'ratings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ratings_Var_Samp_Fields = {
  __typename?: 'ratings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ratings_Variance_Fields = {
  __typename?: 'ratings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "signatory" */
export type Signatory = {
  __typename?: 'signatory';
  /** An object relationship */
  b2b_account: B2b_Accounts;
  b2b_account_id: Scalars['Int'];
  birthdate: Scalars['date'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  customer_contracts: Array<Customer_Contracts>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  phone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "signatory" */
export type SignatoryCustomer_ContractsArgs = {
  distinct_on?: Maybe<Array<Customer_Contracts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contracts_Order_By>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};

/** order by aggregate values of table "signatory" */
export type Signatory_Aggregate_Order_By = {
  avg?: Maybe<Signatory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Signatory_Max_Order_By>;
  min?: Maybe<Signatory_Min_Order_By>;
  stddev?: Maybe<Signatory_Stddev_Order_By>;
  stddev_pop?: Maybe<Signatory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Signatory_Stddev_Samp_Order_By>;
  sum?: Maybe<Signatory_Sum_Order_By>;
  var_pop?: Maybe<Signatory_Var_Pop_Order_By>;
  var_samp?: Maybe<Signatory_Var_Samp_Order_By>;
  variance?: Maybe<Signatory_Variance_Order_By>;
};

/** order by avg() on columns of table "signatory" */
export type Signatory_Avg_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "signatory". All fields are combined with a logical 'AND'. */
export type Signatory_Bool_Exp = {
  _and?: Maybe<Array<Signatory_Bool_Exp>>;
  _not?: Maybe<Signatory_Bool_Exp>;
  _or?: Maybe<Array<Signatory_Bool_Exp>>;
  b2b_account?: Maybe<B2b_Accounts_Bool_Exp>;
  b2b_account_id?: Maybe<Int_Comparison_Exp>;
  birthdate?: Maybe<Date_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_contracts?: Maybe<Customer_Contracts_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "signatory" */
export type Signatory_Max_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "signatory" */
export type Signatory_Min_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "signatory". */
export type Signatory_Order_By = {
  b2b_account?: Maybe<B2b_Accounts_Order_By>;
  b2b_account_id?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_contracts_aggregate?: Maybe<Customer_Contracts_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "signatory" */
export enum Signatory_Select_Column {
  /** column name */
  B2bAccountId = 'b2b_account_id',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "signatory" */
export type Signatory_Stddev_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "signatory" */
export type Signatory_Stddev_Pop_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "signatory" */
export type Signatory_Stddev_Samp_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "signatory" */
export type Signatory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Signatory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Signatory_Stream_Cursor_Value_Input = {
  b2b_account_id?: Maybe<Scalars['Int']>;
  birthdate?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "signatory" */
export type Signatory_Sum_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "signatory" */
export type Signatory_Var_Pop_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "signatory" */
export type Signatory_Var_Samp_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "signatory" */
export type Signatory_Variance_Order_By = {
  b2b_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_item_states" */
export type Stock_Item_States = {
  __typename?: 'stock_item_states';
  created_at: Scalars['timestamptz'];
  from_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  from_state?: Maybe<Stock_Item_States>;
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  /** An object relationship */
  order_item?: Maybe<Order_Items>;
  order_item_id?: Maybe<Scalars['Int']>;
  resource_id: Scalars['Int'];
  /** An object relationship */
  stock_item: Stock_Items;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "stock_item_states" */
export type Stock_Item_StatesMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "stock_item_states" */
export type Stock_Item_States_Aggregate_Order_By = {
  avg?: Maybe<Stock_Item_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Item_States_Max_Order_By>;
  min?: Maybe<Stock_Item_States_Min_Order_By>;
  stddev?: Maybe<Stock_Item_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Item_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Item_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Item_States_Sum_Order_By>;
  var_pop?: Maybe<Stock_Item_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Item_States_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Item_States_Variance_Order_By>;
};

/** order by avg() on columns of table "stock_item_states" */
export type Stock_Item_States_Avg_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_item_states". All fields are combined with a logical 'AND'. */
export type Stock_Item_States_Bool_Exp = {
  _and?: Maybe<Array<Stock_Item_States_Bool_Exp>>;
  _not?: Maybe<Stock_Item_States_Bool_Exp>;
  _or?: Maybe<Array<Stock_Item_States_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_id?: Maybe<Int_Comparison_Exp>;
  from_state?: Maybe<Stock_Item_States_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_item?: Maybe<Order_Items_Bool_Exp>;
  order_item_id?: Maybe<Int_Comparison_Exp>;
  resource_id?: Maybe<Int_Comparison_Exp>;
  stock_item?: Maybe<Stock_Items_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "stock_item_states" */
export type Stock_Item_States_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "stock_item_states" */
export type Stock_Item_States_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "stock_item_states". */
export type Stock_Item_States_Order_By = {
  created_at?: Maybe<Order_By>;
  from_id?: Maybe<Order_By>;
  from_state?: Maybe<Stock_Item_States_Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_item?: Maybe<Order_Items_Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  stock_item?: Maybe<Stock_Items_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "stock_item_states" */
export enum Stock_Item_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromId = 'from_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderItemId = 'order_item_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "stock_item_states" */
export type Stock_Item_States_Stddev_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "stock_item_states" */
export type Stock_Item_States_Stddev_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "stock_item_states" */
export type Stock_Item_States_Stddev_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_item_states" */
export type Stock_Item_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Item_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Item_States_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  order_item_id?: Maybe<Scalars['Int']>;
  resource_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_item_states" */
export type Stock_Item_States_Sum_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "stock_item_states" */
export type Stock_Item_States_Var_Pop_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "stock_item_states" */
export type Stock_Item_States_Var_Samp_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "stock_item_states" */
export type Stock_Item_States_Variance_Order_By = {
  from_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_item_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_items" */
export type Stock_Items = {
  __typename?: 'stock_items';
  amortization?: Maybe<GetDeviceAmortizationDto>;
  /** An object relationship */
  current_state?: Maybe<Stock_Item_States>;
  current_state_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  item: Items;
  item_id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
  serial?: Maybe<Scalars['String']>;
  /** An array relationship */
  states: Array<Stock_Item_States>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "stock_items" */
export type Stock_ItemsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stock_items" */
export type Stock_ItemsOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "stock_items" */
export type Stock_ItemsOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "stock_items" */
export type Stock_ItemsStatesArgs = {
  distinct_on?: Maybe<Array<Stock_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Item_States_Order_By>>;
  where?: Maybe<Stock_Item_States_Bool_Exp>;
};

/** order by aggregate values of table "stock_items" */
export type Stock_Items_Aggregate_Order_By = {
  avg?: Maybe<Stock_Items_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Items_Max_Order_By>;
  min?: Maybe<Stock_Items_Min_Order_By>;
  stddev?: Maybe<Stock_Items_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Items_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Items_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Items_Sum_Order_By>;
  var_pop?: Maybe<Stock_Items_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Items_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Items_Variance_Order_By>;
};

/** order by avg() on columns of table "stock_items" */
export type Stock_Items_Avg_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_items". All fields are combined with a logical 'AND'. */
export type Stock_Items_Bool_Exp = {
  _and?: Maybe<Array<Stock_Items_Bool_Exp>>;
  _not?: Maybe<Stock_Items_Bool_Exp>;
  _or?: Maybe<Array<Stock_Items_Bool_Exp>>;
  current_state?: Maybe<Stock_Item_States_Bool_Exp>;
  current_state_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  item?: Maybe<Items_Bool_Exp>;
  item_id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  order_items?: Maybe<Order_Items_Bool_Exp>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Bool_Exp>;
  serial?: Maybe<String_Comparison_Exp>;
  states?: Maybe<Stock_Item_States_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "stock_items" */
export type Stock_Items_Max_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  serial?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "stock_items" */
export type Stock_Items_Min_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  serial?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "stock_items". */
export type Stock_Items_Order_By = {
  current_state?: Maybe<Stock_Item_States_Order_By>;
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item?: Maybe<Items_Order_By>;
  item_id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Order_By>;
  serial?: Maybe<Order_By>;
  states_aggregate?: Maybe<Stock_Item_States_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "stock_items" */
export enum Stock_Items_Select_Column {
  /** column name */
  CurrentStateId = 'current_state_id',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Serial = 'serial',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "stock_items" */
export type Stock_Items_Stddev_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "stock_items" */
export type Stock_Items_Stddev_Pop_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "stock_items" */
export type Stock_Items_Stddev_Samp_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_items" */
export type Stock_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Items_Stream_Cursor_Value_Input = {
  current_state_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  item_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  serial?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "stock_items" */
export type Stock_Items_Sum_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "stock_items" */
export type Stock_Items_Var_Pop_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "stock_items" */
export type Stock_Items_Var_Samp_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "stock_items" */
export type Stock_Items_Variance_Order_By = {
  current_state_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  b2b_accounts: Array<B2b_Accounts>;
  /** fetch data from the table: "b2b_accounts" using primary key columns */
  b2b_accounts_by_pk?: Maybe<B2b_Accounts>;
  /** fetch data from the table in a streaming manner: "b2b_accounts" */
  b2b_accounts_stream: Array<B2b_Accounts>;
  /** An array relationship */
  b2b_users: Array<B2b_Users>;
  /** An aggregate relationship */
  b2b_users_aggregate: B2b_Users_Aggregate;
  /** fetch data from the table: "b2b_users" using primary key columns */
  b2b_users_by_pk?: Maybe<B2b_Users>;
  /** fetch data from the table: "b2b_users_orders" */
  b2b_users_orders: Array<B2b_Users_Orders>;
  /** fetch aggregated fields from the table: "b2b_users_orders" */
  b2b_users_orders_aggregate: B2b_Users_Orders_Aggregate;
  /** fetch data from the table: "b2b_users_orders" using primary key columns */
  b2b_users_orders_by_pk?: Maybe<B2b_Users_Orders>;
  /** fetch data from the table in a streaming manner: "b2b_users_orders" */
  b2b_users_orders_stream: Array<B2b_Users_Orders>;
  /** fetch data from the table in a streaming manner: "b2b_users" */
  b2b_users_stream: Array<B2b_Users>;
  /** An array relationship */
  bank_details: Array<Bank_Details>;
  /** fetch data from the table: "bank_details" using primary key columns */
  bank_details_by_pk?: Maybe<Bank_Details>;
  /** fetch data from the table: "bank_details_files" */
  bank_details_files: Array<Bank_Details_Files>;
  /** fetch data from the table in a streaming manner: "bank_details_files" */
  bank_details_files_stream: Array<Bank_Details_Files>;
  /** fetch data from the table in a streaming manner: "bank_details" */
  bank_details_stream: Array<Bank_Details>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countries_stream: Array<Countries>;
  /** An array relationship */
  coupon_orders: Array<Coupon_Orders>;
  /** fetch data from the table: "coupon_orders" using primary key columns */
  coupon_orders_by_pk?: Maybe<Coupon_Orders>;
  /** fetch data from the table in a streaming manner: "coupon_orders" */
  coupon_orders_stream: Array<Coupon_Orders>;
  /** fetch data from the table: "coupons" */
  coupons: Array<Coupons>;
  /** fetch data from the table: "coupons" using primary key columns */
  coupons_by_pk?: Maybe<Coupons>;
  /** fetch data from the table in a streaming manner: "coupons" */
  coupons_stream: Array<Coupons>;
  /** An array relationship */
  customer_contract_states: Array<Customer_Contract_States>;
  /** fetch data from the table: "customer_contract_states" using primary key columns */
  customer_contract_states_by_pk?: Maybe<Customer_Contract_States>;
  /** fetch data from the table in a streaming manner: "customer_contract_states" */
  customer_contract_states_stream: Array<Customer_Contract_States>;
  /** An array relationship */
  customer_contracts: Array<Customer_Contracts>;
  /** fetch data from the table: "customer_contracts" using primary key columns */
  customer_contracts_by_pk?: Maybe<Customer_Contracts>;
  /** fetch data from the table in a streaming manner: "customer_contracts" */
  customer_contracts_stream: Array<Customer_Contracts>;
  /** fetch data from the table: "customer_verification_files" */
  customer_verification_files: Array<Customer_Verification_Files>;
  /** fetch data from the table in a streaming manner: "customer_verification_files" */
  customer_verification_files_stream: Array<Customer_Verification_Files>;
  /** fetch data from the table: "customer_verification_states" */
  customer_verification_states: Array<Customer_Verification_States>;
  /** fetch data from the table: "customer_verification_states" using primary key columns */
  customer_verification_states_by_pk?: Maybe<Customer_Verification_States>;
  /** fetch data from the table in a streaming manner: "customer_verification_states" */
  customer_verification_states_stream: Array<Customer_Verification_States>;
  /** An array relationship */
  customer_verifications: Array<Customer_Verifications>;
  /** fetch data from the table: "customer_verifications" using primary key columns */
  customer_verifications_by_pk?: Maybe<Customer_Verifications>;
  /** fetch data from the table: "customer_verifications_scoring_model_view" */
  customer_verifications_scoring_model_view: Array<Customer_Verifications_Scoring_Model_View>;
  /** fetch data from the table in a streaming manner: "customer_verifications_scoring_model_view" */
  customer_verifications_scoring_model_view_stream: Array<Customer_Verifications_Scoring_Model_View>;
  /** fetch data from the table in a streaming manner: "customer_verifications" */
  customer_verifications_stream: Array<Customer_Verifications>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table in a streaming manner: "customers" */
  customers_stream: Array<Customers>;
  /** fetch data from the table: "devices_stock_view_cache" */
  devices_stock_view_cache: Array<Devices_Stock_View_Cache>;
  /** fetch data from the table in a streaming manner: "devices_stock_view_cache" */
  devices_stock_view_cache_stream: Array<Devices_Stock_View_Cache>;
  /** An array relationship */
  experiments_allocations: Array<Experiments_Allocations>;
  /** An aggregate relationship */
  experiments_allocations_aggregate: Experiments_Allocations_Aggregate;
  /** fetch data from the table: "experiments_allocations" using primary key columns */
  experiments_allocations_by_pk?: Maybe<Experiments_Allocations>;
  /** fetch data from the table in a streaming manner: "experiments_allocations" */
  experiments_allocations_stream: Array<Experiments_Allocations>;
  /** fetch data from the table: "financing_contract" */
  financing_contract: Array<Financing_Contract>;
  /** fetch aggregated fields from the table: "financing_contract" */
  financing_contract_aggregate: Financing_Contract_Aggregate;
  /** fetch data from the table: "financing_contract_application" */
  financing_contract_application: Array<Financing_Contract_Application>;
  /** fetch aggregated fields from the table: "financing_contract_application" */
  financing_contract_application_aggregate: Financing_Contract_Application_Aggregate;
  /** fetch data from the table: "financing_contract_application" using primary key columns */
  financing_contract_application_by_pk?: Maybe<Financing_Contract_Application>;
  /** fetch data from the table in a streaming manner: "financing_contract_application" */
  financing_contract_application_stream: Array<Financing_Contract_Application>;
  /** fetch data from the table: "financing_contract" using primary key columns */
  financing_contract_by_pk?: Maybe<Financing_Contract>;
  /** fetch data from the table in a streaming manner: "financing_contract" */
  financing_contract_stream: Array<Financing_Contract>;
  /** fetch data from the table: "insurance_ticket_states" */
  insurance_ticket_states: Array<Insurance_Ticket_States>;
  /** fetch data from the table: "insurance_ticket_states" using primary key columns */
  insurance_ticket_states_by_pk?: Maybe<Insurance_Ticket_States>;
  /** fetch data from the table in a streaming manner: "insurance_ticket_states" */
  insurance_ticket_states_stream: Array<Insurance_Ticket_States>;
  /** An array relationship */
  insurance_tickets: Array<Insurance_Tickets>;
  /** fetch data from the table: "insurance_tickets" using primary key columns */
  insurance_tickets_by_pk?: Maybe<Insurance_Tickets>;
  /** fetch data from the table: "insurance_tickets_files" */
  insurance_tickets_files: Array<Insurance_Tickets_Files>;
  /** fetch data from the table in a streaming manner: "insurance_tickets_files" */
  insurance_tickets_files_stream: Array<Insurance_Tickets_Files>;
  /** fetch data from the table in a streaming manner: "insurance_tickets" */
  insurance_tickets_stream: Array<Insurance_Tickets>;
  /** fetch data from the table: "invoice_files" */
  invoice_files: Array<Invoice_Files>;
  /** fetch data from the table in a streaming manner: "invoice_files" */
  invoice_files_stream: Array<Invoice_Files>;
  /** fetch data from the table: "invoice_groups" */
  invoice_groups: Array<Invoice_Groups>;
  /** fetch aggregated fields from the table: "invoice_groups" */
  invoice_groups_aggregate: Invoice_Groups_Aggregate;
  /** fetch data from the table: "invoice_groups" using primary key columns */
  invoice_groups_by_pk?: Maybe<Invoice_Groups>;
  /** fetch data from the table in a streaming manner: "invoice_groups" */
  invoice_groups_stream: Array<Invoice_Groups>;
  /** fetch data from the table: "invoice_payment" */
  invoice_payment: Array<Invoice_Payment>;
  /** fetch data from the table: "invoice_payment" using primary key columns */
  invoice_payment_by_pk?: Maybe<Invoice_Payment>;
  /** fetch data from the table in a streaming manner: "invoice_payment" */
  invoice_payment_stream: Array<Invoice_Payment>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** fetch data from the table in a streaming manner: "invoices" */
  invoices_stream: Array<Invoices>;
  /** An array relationship */
  items: Array<Items>;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table in a streaming manner: "items" */
  items_stream: Array<Items>;
  /** fetch data from the table: "open_banking_connections" */
  open_banking_connections: Array<Open_Banking_Connections>;
  /** fetch data from the table: "open_banking_connections" using primary key columns */
  open_banking_connections_by_pk?: Maybe<Open_Banking_Connections>;
  /** fetch data from the table in a streaming manner: "open_banking_connections" */
  open_banking_connections_stream: Array<Open_Banking_Connections>;
  /** fetch data from the table: "order_files" */
  order_files: Array<Order_Files>;
  /** fetch data from the table in a streaming manner: "order_files" */
  order_files_stream: Array<Order_Files>;
  /** fetch data from the table: "order_item_files" */
  order_item_files: Array<Order_Item_Files>;
  /** fetch data from the table in a streaming manner: "order_item_files" */
  order_item_files_stream: Array<Order_Item_Files>;
  /** fetch data from the table: "order_item_states" */
  order_item_states: Array<Order_Item_States>;
  /** fetch data from the table: "order_item_states" using primary key columns */
  order_item_states_by_pk?: Maybe<Order_Item_States>;
  /** fetch data from the table in a streaming manner: "order_item_states" */
  order_item_states_stream: Array<Order_Item_States>;
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
  /** fetch data from the table: "order_items" using primary key columns */
  order_items_by_pk?: Maybe<Order_Items>;
  /** fetch data from the table in a streaming manner: "order_items" */
  order_items_stream: Array<Order_Items>;
  /** fetch data from the table: "order_parcels" */
  order_parcels: Array<Order_Parcels>;
  /** fetch data from the table in a streaming manner: "order_parcels" */
  order_parcels_stream: Array<Order_Parcels>;
  /** fetch data from the table: "order_states" */
  order_states: Array<Order_States>;
  /** fetch data from the table: "order_states" using primary key columns */
  order_states_by_pk?: Maybe<Order_States>;
  /** fetch data from the table in a streaming manner: "order_states" */
  order_states_stream: Array<Order_States>;
  /** fetch data from the table: "order_subscriptions" */
  order_subscriptions: Array<Order_Subscriptions>;
  /** fetch data from the table: "order_subscriptions" using primary key columns */
  order_subscriptions_by_pk?: Maybe<Order_Subscriptions>;
  /** fetch data from the table in a streaming manner: "order_subscriptions" */
  order_subscriptions_stream: Array<Order_Subscriptions>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "orders_device" */
  orders_device: Array<Orders_Device>;
  /** fetch data from the table in a streaming manner: "orders_device" */
  orders_device_stream: Array<Orders_Device>;
  /** fetch data from the table in a streaming manner: "orders" */
  orders_stream: Array<Orders>;
  /** An array relationship */
  payments: Array<Payments>;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table in a streaming manner: "payments" */
  payments_stream: Array<Payments>;
  /** fetch data from the table: "phone_plans_orders" */
  phone_plans_orders: Array<Phone_Plans_Orders>;
  /** fetch aggregated fields from the table: "phone_plans_orders" */
  phone_plans_orders_aggregate: Phone_Plans_Orders_Aggregate;
  /** fetch data from the table in a streaming manner: "phone_plans_orders" */
  phone_plans_orders_stream: Array<Phone_Plans_Orders>;
  /** fetch data from the table: "ratings" */
  ratings: Array<Ratings>;
  /** fetch aggregated fields from the table: "ratings" */
  ratings_aggregate: Ratings_Aggregate;
  /** fetch data from the table: "ratings" using primary key columns */
  ratings_by_pk?: Maybe<Ratings>;
  /** fetch data from the table in a streaming manner: "ratings" */
  ratings_stream: Array<Ratings>;
  /** fetch data from the table: "signatory" */
  signatory: Array<Signatory>;
  /** fetch data from the table: "signatory" using primary key columns */
  signatory_by_pk?: Maybe<Signatory>;
  /** fetch data from the table in a streaming manner: "signatory" */
  signatory_stream: Array<Signatory>;
  /** fetch data from the table: "stock_item_states" */
  stock_item_states: Array<Stock_Item_States>;
  /** fetch data from the table: "stock_item_states" using primary key columns */
  stock_item_states_by_pk?: Maybe<Stock_Item_States>;
  /** fetch data from the table in a streaming manner: "stock_item_states" */
  stock_item_states_stream: Array<Stock_Item_States>;
  /** An array relationship */
  stock_items: Array<Stock_Items>;
  /** fetch data from the table: "stock_items" using primary key columns */
  stock_items_by_pk?: Maybe<Stock_Items>;
  /** fetch data from the table in a streaming manner: "stock_items" */
  stock_items_stream: Array<Stock_Items>;
  /** An array relationship */
  taxes: Array<Taxes>;
  /** fetch data from the table: "taxes" using primary key columns */
  taxes_by_pk?: Maybe<Taxes>;
  /** fetch data from the table in a streaming manner: "taxes" */
  taxes_stream: Array<Taxes>;
};


export type Subscription_RootB2b_AccountsArgs = {
  distinct_on?: Maybe<Array<B2b_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Accounts_Order_By>>;
  where?: Maybe<B2b_Accounts_Bool_Exp>;
};


export type Subscription_RootB2b_Accounts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootB2b_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<B2b_Accounts_Stream_Cursor_Input>>;
  where?: Maybe<B2b_Accounts_Bool_Exp>;
};


export type Subscription_RootB2b_UsersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Order_By>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


export type Subscription_RootB2b_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Order_By>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


export type Subscription_RootB2b_Users_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootB2b_Users_OrdersArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


export type Subscription_RootB2b_Users_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<B2b_Users_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<B2b_Users_Orders_Order_By>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


export type Subscription_RootB2b_Users_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootB2b_Users_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<B2b_Users_Orders_Stream_Cursor_Input>>;
  where?: Maybe<B2b_Users_Orders_Bool_Exp>;
};


export type Subscription_RootB2b_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<B2b_Users_Stream_Cursor_Input>>;
  where?: Maybe<B2b_Users_Bool_Exp>;
};


export type Subscription_RootBank_DetailsArgs = {
  distinct_on?: Maybe<Array<Bank_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Details_Order_By>>;
  where?: Maybe<Bank_Details_Bool_Exp>;
};


export type Subscription_RootBank_Details_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBank_Details_FilesArgs = {
  distinct_on?: Maybe<Array<Bank_Details_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Details_Files_Order_By>>;
  where?: Maybe<Bank_Details_Files_Bool_Exp>;
};


export type Subscription_RootBank_Details_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bank_Details_Files_Stream_Cursor_Input>>;
  where?: Maybe<Bank_Details_Files_Bool_Exp>;
};


export type Subscription_RootBank_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bank_Details_Stream_Cursor_Input>>;
  where?: Maybe<Bank_Details_Bool_Exp>;
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: Maybe<Array<Countries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Countries_Order_By>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Countries_Stream_Cursor_Input>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Subscription_RootCoupon_OrdersArgs = {
  distinct_on?: Maybe<Array<Coupon_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Orders_Order_By>>;
  where?: Maybe<Coupon_Orders_Bool_Exp>;
};


export type Subscription_RootCoupon_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCoupon_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Coupon_Orders_Stream_Cursor_Input>>;
  where?: Maybe<Coupon_Orders_Bool_Exp>;
};


export type Subscription_RootCouponsArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


export type Subscription_RootCoupons_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCoupons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Coupons_Stream_Cursor_Input>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


export type Subscription_RootCustomer_Contract_StatesArgs = {
  distinct_on?: Maybe<Array<Customer_Contract_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contract_States_Order_By>>;
  where?: Maybe<Customer_Contract_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Contract_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Contract_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Contract_States_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Contract_States_Bool_Exp>;
};


export type Subscription_RootCustomer_ContractsArgs = {
  distinct_on?: Maybe<Array<Customer_Contracts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Contracts_Order_By>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};


export type Subscription_RootCustomer_Contracts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Contracts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Contracts_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Contracts_Bool_Exp>;
};


export type Subscription_RootCustomer_Verification_FilesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_Files_Order_By>>;
  where?: Maybe<Customer_Verification_Files_Bool_Exp>;
};


export type Subscription_RootCustomer_Verification_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Verification_Files_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Verification_Files_Bool_Exp>;
};


export type Subscription_RootCustomer_Verification_StatesArgs = {
  distinct_on?: Maybe<Array<Customer_Verification_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verification_States_Order_By>>;
  where?: Maybe<Customer_Verification_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Verification_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Verification_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Verification_States_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Verification_States_Bool_Exp>;
};


export type Subscription_RootCustomer_VerificationsArgs = {
  distinct_on?: Maybe<Array<Customer_Verifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verifications_Order_By>>;
  where?: Maybe<Customer_Verifications_Bool_Exp>;
};


export type Subscription_RootCustomer_Verifications_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Verifications_Scoring_Model_ViewArgs = {
  distinct_on?: Maybe<Array<Customer_Verifications_Scoring_Model_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Verifications_Scoring_Model_View_Order_By>>;
  where?: Maybe<Customer_Verifications_Scoring_Model_View_Bool_Exp>;
};


export type Subscription_RootCustomer_Verifications_Scoring_Model_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Verifications_Scoring_Model_View_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Verifications_Scoring_Model_View_Bool_Exp>;
};


export type Subscription_RootCustomer_Verifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Verifications_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Verifications_Bool_Exp>;
};


export type Subscription_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customers_Stream_Cursor_Input>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootDevices_Stock_View_CacheArgs = {
  distinct_on?: Maybe<Array<Devices_Stock_View_Cache_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Devices_Stock_View_Cache_Order_By>>;
  where?: Maybe<Devices_Stock_View_Cache_Bool_Exp>;
};


export type Subscription_RootDevices_Stock_View_Cache_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Devices_Stock_View_Cache_Stream_Cursor_Input>>;
  where?: Maybe<Devices_Stock_View_Cache_Bool_Exp>;
};


export type Subscription_RootExperiments_AllocationsArgs = {
  distinct_on?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Experiments_Allocations_Order_By>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


export type Subscription_RootExperiments_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Experiments_Allocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Experiments_Allocations_Order_By>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


export type Subscription_RootExperiments_Allocations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootExperiments_Allocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Experiments_Allocations_Stream_Cursor_Input>>;
  where?: Maybe<Experiments_Allocations_Bool_Exp>;
};


export type Subscription_RootFinancing_ContractArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


export type Subscription_RootFinancing_Contract_AggregateArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Order_By>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


export type Subscription_RootFinancing_Contract_ApplicationArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Application_Order_By>>;
  where?: Maybe<Financing_Contract_Application_Bool_Exp>;
};


export type Subscription_RootFinancing_Contract_Application_AggregateArgs = {
  distinct_on?: Maybe<Array<Financing_Contract_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Contract_Application_Order_By>>;
  where?: Maybe<Financing_Contract_Application_Bool_Exp>;
};


export type Subscription_RootFinancing_Contract_Application_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFinancing_Contract_Application_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Financing_Contract_Application_Stream_Cursor_Input>>;
  where?: Maybe<Financing_Contract_Application_Bool_Exp>;
};


export type Subscription_RootFinancing_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFinancing_Contract_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Financing_Contract_Stream_Cursor_Input>>;
  where?: Maybe<Financing_Contract_Bool_Exp>;
};


export type Subscription_RootInsurance_Ticket_StatesArgs = {
  distinct_on?: Maybe<Array<Insurance_Ticket_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Ticket_States_Order_By>>;
  where?: Maybe<Insurance_Ticket_States_Bool_Exp>;
};


export type Subscription_RootInsurance_Ticket_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInsurance_Ticket_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insurance_Ticket_States_Stream_Cursor_Input>>;
  where?: Maybe<Insurance_Ticket_States_Bool_Exp>;
};


export type Subscription_RootInsurance_TicketsArgs = {
  distinct_on?: Maybe<Array<Insurance_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Tickets_Order_By>>;
  where?: Maybe<Insurance_Tickets_Bool_Exp>;
};


export type Subscription_RootInsurance_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInsurance_Tickets_FilesArgs = {
  distinct_on?: Maybe<Array<Insurance_Tickets_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Tickets_Files_Order_By>>;
  where?: Maybe<Insurance_Tickets_Files_Bool_Exp>;
};


export type Subscription_RootInsurance_Tickets_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insurance_Tickets_Files_Stream_Cursor_Input>>;
  where?: Maybe<Insurance_Tickets_Files_Bool_Exp>;
};


export type Subscription_RootInsurance_Tickets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insurance_Tickets_Stream_Cursor_Input>>;
  where?: Maybe<Insurance_Tickets_Bool_Exp>;
};


export type Subscription_RootInvoice_FilesArgs = {
  distinct_on?: Maybe<Array<Invoice_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Files_Order_By>>;
  where?: Maybe<Invoice_Files_Bool_Exp>;
};


export type Subscription_RootInvoice_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Files_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Files_Bool_Exp>;
};


export type Subscription_RootInvoice_GroupsArgs = {
  distinct_on?: Maybe<Array<Invoice_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Groups_Order_By>>;
  where?: Maybe<Invoice_Groups_Bool_Exp>;
};


export type Subscription_RootInvoice_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Groups_Order_By>>;
  where?: Maybe<Invoice_Groups_Bool_Exp>;
};


export type Subscription_RootInvoice_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoice_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Groups_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Groups_Bool_Exp>;
};


export type Subscription_RootInvoice_PaymentArgs = {
  distinct_on?: Maybe<Array<Invoice_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Payment_Order_By>>;
  where?: Maybe<Invoice_Payment_Bool_Exp>;
};


export type Subscription_RootInvoice_Payment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoice_Payment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Payment_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Payment_Bool_Exp>;
};


export type Subscription_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootInvoices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoices_Stream_Cursor_Input>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootItemsArgs = {
  distinct_on?: Maybe<Array<Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Items_Order_By>>;
  where?: Maybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Items_Stream_Cursor_Input>>;
  where?: Maybe<Items_Bool_Exp>;
};


export type Subscription_RootOpen_Banking_ConnectionsArgs = {
  distinct_on?: Maybe<Array<Open_Banking_Connections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Open_Banking_Connections_Order_By>>;
  where?: Maybe<Open_Banking_Connections_Bool_Exp>;
};


export type Subscription_RootOpen_Banking_Connections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOpen_Banking_Connections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Open_Banking_Connections_Stream_Cursor_Input>>;
  where?: Maybe<Open_Banking_Connections_Bool_Exp>;
};


export type Subscription_RootOrder_FilesArgs = {
  distinct_on?: Maybe<Array<Order_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Files_Order_By>>;
  where?: Maybe<Order_Files_Bool_Exp>;
};


export type Subscription_RootOrder_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Files_Stream_Cursor_Input>>;
  where?: Maybe<Order_Files_Bool_Exp>;
};


export type Subscription_RootOrder_Item_FilesArgs = {
  distinct_on?: Maybe<Array<Order_Item_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Files_Order_By>>;
  where?: Maybe<Order_Item_Files_Bool_Exp>;
};


export type Subscription_RootOrder_Item_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Item_Files_Stream_Cursor_Input>>;
  where?: Maybe<Order_Item_Files_Bool_Exp>;
};


export type Subscription_RootOrder_Item_StatesArgs = {
  distinct_on?: Maybe<Array<Order_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_States_Order_By>>;
  where?: Maybe<Order_Item_States_Bool_Exp>;
};


export type Subscription_RootOrder_Item_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Item_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Item_States_Stream_Cursor_Input>>;
  where?: Maybe<Order_Item_States_Bool_Exp>;
};


export type Subscription_RootOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


export type Subscription_RootOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


export type Subscription_RootOrder_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Items_Stream_Cursor_Input>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


export type Subscription_RootOrder_ParcelsArgs = {
  distinct_on?: Maybe<Array<Order_Parcels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Parcels_Order_By>>;
  where?: Maybe<Order_Parcels_Bool_Exp>;
};


export type Subscription_RootOrder_Parcels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Parcels_Stream_Cursor_Input>>;
  where?: Maybe<Order_Parcels_Bool_Exp>;
};


export type Subscription_RootOrder_StatesArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Subscription_RootOrder_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_States_Stream_Cursor_Input>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Subscription_RootOrder_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Order_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Subscriptions_Order_By>>;
  where?: Maybe<Order_Subscriptions_Bool_Exp>;
};


export type Subscription_RootOrder_Subscriptions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Subscriptions_Stream_Cursor_Input>>;
  where?: Maybe<Order_Subscriptions_Bool_Exp>;
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrders_DeviceArgs = {
  distinct_on?: Maybe<Array<Orders_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Device_Order_By>>;
  where?: Maybe<Orders_Device_Bool_Exp>;
};


export type Subscription_RootOrders_Device_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Orders_Device_Stream_Cursor_Input>>;
  where?: Maybe<Orders_Device_Bool_Exp>;
};


export type Subscription_RootOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Orders_Stream_Cursor_Input>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPayments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payments_Stream_Cursor_Input>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPhone_Plans_OrdersArgs = {
  distinct_on?: Maybe<Array<Phone_Plans_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Plans_Orders_Order_By>>;
  where?: Maybe<Phone_Plans_Orders_Bool_Exp>;
};


export type Subscription_RootPhone_Plans_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Plans_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Plans_Orders_Order_By>>;
  where?: Maybe<Phone_Plans_Orders_Bool_Exp>;
};


export type Subscription_RootPhone_Plans_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Phone_Plans_Orders_Stream_Cursor_Input>>;
  where?: Maybe<Phone_Plans_Orders_Bool_Exp>;
};


export type Subscription_RootRatingsArgs = {
  distinct_on?: Maybe<Array<Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ratings_Order_By>>;
  where?: Maybe<Ratings_Bool_Exp>;
};


export type Subscription_RootRatings_AggregateArgs = {
  distinct_on?: Maybe<Array<Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ratings_Order_By>>;
  where?: Maybe<Ratings_Bool_Exp>;
};


export type Subscription_RootRatings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRatings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ratings_Stream_Cursor_Input>>;
  where?: Maybe<Ratings_Bool_Exp>;
};


export type Subscription_RootSignatoryArgs = {
  distinct_on?: Maybe<Array<Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Signatory_Order_By>>;
  where?: Maybe<Signatory_Bool_Exp>;
};


export type Subscription_RootSignatory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSignatory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Signatory_Stream_Cursor_Input>>;
  where?: Maybe<Signatory_Bool_Exp>;
};


export type Subscription_RootStock_Item_StatesArgs = {
  distinct_on?: Maybe<Array<Stock_Item_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Item_States_Order_By>>;
  where?: Maybe<Stock_Item_States_Bool_Exp>;
};


export type Subscription_RootStock_Item_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStock_Item_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stock_Item_States_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Item_States_Bool_Exp>;
};


export type Subscription_RootStock_ItemsArgs = {
  distinct_on?: Maybe<Array<Stock_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Items_Order_By>>;
  where?: Maybe<Stock_Items_Bool_Exp>;
};


export type Subscription_RootStock_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStock_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stock_Items_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Items_Bool_Exp>;
};


export type Subscription_RootTaxesArgs = {
  distinct_on?: Maybe<Array<Taxes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxes_Order_By>>;
  where?: Maybe<Taxes_Bool_Exp>;
};


export type Subscription_RootTaxes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTaxes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Taxes_Stream_Cursor_Input>>;
  where?: Maybe<Taxes_Bool_Exp>;
};

/** columns and relationships of "taxes" */
export type Taxes = {
  __typename?: 'taxes';
  amount: Scalars['numeric'];
  code: Scalars['String'];
  /** An object relationship */
  country: Countries;
  id: Scalars['Int'];
  /** An array relationship */
  items: Array<Items>;
  /** An array relationship */
  order_items: Array<Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Items_Aggregate;
};


/** columns and relationships of "taxes" */
export type TaxesItemsArgs = {
  distinct_on?: Maybe<Array<Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Items_Order_By>>;
  where?: Maybe<Items_Bool_Exp>;
};


/** columns and relationships of "taxes" */
export type TaxesOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};


/** columns and relationships of "taxes" */
export type TaxesOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Items_Order_By>>;
  where?: Maybe<Order_Items_Bool_Exp>;
};

/** order by aggregate values of table "taxes" */
export type Taxes_Aggregate_Order_By = {
  avg?: Maybe<Taxes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxes_Max_Order_By>;
  min?: Maybe<Taxes_Min_Order_By>;
  stddev?: Maybe<Taxes_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxes_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxes_Sum_Order_By>;
  var_pop?: Maybe<Taxes_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxes_Var_Samp_Order_By>;
  variance?: Maybe<Taxes_Variance_Order_By>;
};

/** order by avg() on columns of table "taxes" */
export type Taxes_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxes". All fields are combined with a logical 'AND'. */
export type Taxes_Bool_Exp = {
  _and?: Maybe<Array<Taxes_Bool_Exp>>;
  _not?: Maybe<Taxes_Bool_Exp>;
  _or?: Maybe<Array<Taxes_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  country?: Maybe<Countries_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  items?: Maybe<Items_Bool_Exp>;
  order_items?: Maybe<Order_Items_Bool_Exp>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Bool_Exp>;
};

/** order by max() on columns of table "taxes" */
export type Taxes_Max_Order_By = {
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by min() on columns of table "taxes" */
export type Taxes_Min_Order_By = {
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "taxes". */
export type Taxes_Order_By = {
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  country?: Maybe<Countries_Order_By>;
  id?: Maybe<Order_By>;
  items_aggregate?: Maybe<Items_Aggregate_Order_By>;
  order_items_aggregate?: Maybe<Order_Items_Aggregate_Order_By>;
};

/** select columns of table "taxes" */
export enum Taxes_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id'
}

/** order by stddev() on columns of table "taxes" */
export type Taxes_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "taxes" */
export type Taxes_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "taxes" */
export type Taxes_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "taxes" */
export type Taxes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taxes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taxes_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "taxes" */
export type Taxes_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "taxes" */
export type Taxes_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "taxes" */
export type Taxes_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "taxes" */
export type Taxes_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};
